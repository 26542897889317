import React, { useState, useEffect, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Button from "../form/Button";
import Input from "../form/Input";
import Textarea from "../form/Textarea";
import { Link } from "react-router-dom";

import { postData } from "../../services/api";
import toastr from "toastr";
import { useTranslation } from "react-i18next";

const ManageInviteExecutivePopup = ({ isOpen, setIsOpen = () => {}, data, getActionValue = () => {}, type }) => {
  const { i18n, t } = useTranslation();
  const role = localStorage.getItem("role");
  const [email, setEmail] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);

  const closeModal = () => {
    setIsOpen(false);
  };

  const onSubmit = async () => {
    setIsSubmit(true);
    try {
      const result = await postData("executive/invitation-send", {
        email: email,
        id: data?._id,
      });
      if (result.status && result.status === 200) {
        getActionValue({
          button_type: "edit",
          row_id: data,
          current_status: "",
          email: email,
        });
        setIsOpen(false);
        setIsSubmit(false);
        toastr.success(result.message);
      } else {
        toastr.error(result.message);
        setIsSubmit(false);
      }
    } catch (error) {
      toastr.error(error.message);
      setIsSubmit(false);
    }
  };

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={() => {}}>
          <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform rounded-2xl bg-white p-4 text-left align-middle shadow-xl transition-all">
                  <Link to={"/"}>
                    <div className="h-10 w-full flex justify-center object-contain mb-5">
                      <svg
                        className="h-full"
                        preserveAspectRatio="xMidYMid meet"
                        data-bbox="0 0 382.89 71.37"
                        viewBox="0 0 382.89 71.37"
                        xmlns="http://www.w3.org/2000/svg"
                        data-type="color"
                        role="img"
                        aria-labelledby="svgcid-iz595q-x7t6i4"
                      >
                        <title id="svgcid-iz595q-x7t6i4">SuccessionNow Logo</title>
                        <g>
                          <path
                            d="m21.02 40.95-6.88-2.98c-3.78-1.61-4.59-3.29-4.59-6.01s1.05-5.15 5.08-5.15c3.41 0 4.84 2.42 4.84 6.57h8.56v-1.24c0-8.49-5.77-11.9-13.7-11.9-8.44 0-13.71 4.4-13.71 13.02 0 7.38 3.66 10.66 11.59 13.39 5.89 2.05 7.94 3.53 7.94 7.56 0 3.72-2.6 5.46-5.33 5.46-4.71 0-5.89-2.67-5.89-6.82v-1.3H0v1.8c0 8.18 3.97 12.9 14.07 12.9s15.38-4.22 15.38-13.58c0-5.39-1.74-8.8-8.43-11.72Z"
                            fill="#3a8078"
                            data-color="1"
                          ></path>
                          <path
                            d="M49.45 54.15c0 3.72-1.61 5.52-4.53 5.52-2.54 0-3.66-1.3-3.66-4.65v-23H32.7v24.74c0 6.57 3.16 9.49 8.49 9.49 3.6 0 6.7-1.49 8.49-4.77h.12v3.91h8.18V32.02h-8.56v22.13Z"
                            fill="#3a8078"
                            data-color="1"
                          ></path>
                          <path
                            d="M75.18 37.35c3.22 0 3.84 3.47 3.84 6.7h8.18c0-7.87-4.09-12.9-11.97-12.9s-13.52 3.84-13.52 17.54 4.22 17.55 12.77 17.55c9.05 0 12.71-4.9 12.71-13.64h-8.18c0 5.39-1.3 7.81-4.15 7.81-3.84 0-4.59-3.41-4.59-11.66 0-7.69.68-11.41 4.9-11.41Z"
                            fill="#3a8078"
                            data-color="1"
                          ></path>
                          <path
                            d="M103.19 37.35c3.22 0 3.84 3.47 3.84 6.7h8.18c0-7.87-4.09-12.9-11.97-12.9s-13.52 3.84-13.52 17.54 4.22 17.55 12.77 17.55c9.05 0 12.71-4.9 12.71-13.64h-8.18c0 5.39-1.3 7.81-4.15 7.81-3.84 0-4.59-3.41-4.59-11.66 0-7.69.68-11.41 4.9-11.41Z"
                            fill="#3a8078"
                            data-color="1"
                          ></path>
                          <path
                            d="M131.01 31.15c-11.53 0-13.27 8.31-13.27 17.24 0 8.31.5 17.85 12.9 17.85 8.06 0 11.78-4.34 12.09-12.09h-7.69c-.5 4.09-1.67 6.26-4.53 6.26-3.16 0-4.22-3.84-4.22-7.75v-2.67h16.92v-1.92c0-10.23-2.23-16.93-12.21-16.93Zm-4.71 13.76v-1.3c0-3.1.99-6.63 4.4-6.63s4.15 2.85 4.34 7.94h-8.74Z"
                            fill="#3a8078"
                            data-color="1"
                          ></path>
                          <path
                            d="m162.72 46.22-5.15-1.74c-2.85-.93-3.72-2.29-3.72-3.91 0-2.36 1.86-3.6 4.03-3.6 2.79 0 3.78 1.67 3.78 5.02h7.69v-1.36c0-6.01-3.66-9.49-11.41-9.49-8.8 0-12.28 4.53-12.28 10.04 0 4.71 2.42 7.63 7.13 9.3l6.01 2.17c1.67.62 3.1 1.61 3.1 3.78 0 2.67-1.74 3.97-4.34 3.97-2.91 0-4.46-1.92-4.46-4.84v-1.12h-7.69v1.18c0 6.39 2.67 10.6 12.28 10.6 7.19 0 12.4-3.47 12.4-10.48 0-5.02-1.98-7.75-7.38-9.55Z"
                            fill="#3a8078"
                            data-color="1"
                          ></path>
                          <path
                            d="m189.75 46.22-5.15-1.74c-2.85-.93-3.72-2.29-3.72-3.91 0-2.36 1.86-3.6 4.03-3.6 2.79 0 3.78 1.67 3.78 5.02h7.69v-1.36c0-6.01-3.66-9.49-11.41-9.49-8.8 0-12.28 4.53-12.28 10.04 0 4.71 2.42 7.63 7.13 9.3l6.01 2.17c1.67.62 3.1 1.61 3.1 3.78 0 2.67-1.74 3.97-4.34 3.97-2.91 0-4.46-1.92-4.46-4.84v-1.12h-7.69v1.18c0 6.39 2.67 10.6 12.28 10.6 7.19 0 12.4-3.47 12.4-10.48 0-5.02-1.98-7.75-7.38-9.55Z"
                            fill="#3a8078"
                            data-color="1"
                          ></path>
                          <path fill="#3a8078" d="M209.32 20.61v7.32h-8.56v-7.32h8.56z" data-color="1"></path>
                          <path fill="#3a8078" d="M209.32 32.02v33.35h-8.56V32.02h8.56z" data-color="1"></path>
                          <path
                            d="M226.36 31.15c-9.8 0-13.14 6.14-13.14 17.54s2.85 17.73 13.14 17.55c9.8 0 13.14-6.14 13.14-17.55s-2.85-17.73-13.14-17.54Zm0 29.26c-4.22 0-4.59-4.9-4.59-11.72s.37-11.72 4.59-11.72c3.47 0 4.59 3.41 4.59 11.72s-1.12 11.72-4.59 11.72Z"
                            fill="#3a8078"
                            data-color="1"
                          ></path>
                          <path
                            d="M260 31.15c-3.6 0-6.7 1.49-8.49 4.77h-.12v-3.91h-8.18v33.35h8.56V43.23c0-3.72 1.61-5.52 4.53-5.52 2.54 0 3.66 1.3 3.66 4.65v23h8.56V40.62c0-6.57-3.16-9.49-8.49-9.49Z"
                            fill="#3a8078"
                            data-color="1"
                          ></path>
                          <path
                            d="M325.74 31.15c-9.8 0-14.45 6.14-16.86 17.54-2.48 11.59-.93 17.73 9.42 17.55 9.79 0 14.45-6.14 16.86-17.55 2.48-11.59.93-17.73-9.42-17.54Zm.87 17.54c-1.74 8.31-3.6 11.72-7.07 11.72-4.21 0-3.53-4.9-2.11-11.72 1.49-6.82 2.85-11.72 7.07-11.72 3.47 0 3.91 3.41 2.11 11.72Z"
                            fill="#a7a9ac"
                            data-color="2"
                          ></path>
                          <path
                            fill="#a7a9ac"
                            d="m374.7 32.02-9.05 23.68h-.12l.62-23.68h-9.3l-9.18 23.68h-.12l1.11-23.68h-8.55l.18 33.35h9.92l9.18-23.68h.12l-.62 23.68h9.74l14.26-33.35h-8.19z"
                            data-color="2"
                          ></path>
                          <path
                            fill="#a7a9ac"
                            d="M321.29 12.82 313.02 0 300.2 8.27l6.29 1.36-11.16 41.79h-.13l-5.34-23.31h-10.29l-9.42 43.26h8.18l6.57-29.99h.13l5.34 23.99h10.16l14.2-53.96 6.56 1.41z"
                            data-color="2"
                          ></path>
                        </g>
                      </svg>
                    </div>
                  </Link>
                  <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900 text-center">
                    {t("executive_invite_message")}
                  </Dialog.Title>
                  {/* <p className='text-center text-sm'>{t('newslatter_subheading')}</p> */}
                  <hr className="mt-3"></hr>
                  <>
                    <div className="relative mt-4">
                      <div className="relative mb-3">
                        <Input label={t("email")} labelClasses={"!text-xs"} inputType={"text"} inputPlaceholder={t("enter_email")} inputValue={email} setInput={setEmail} />
                      </div>
                    </div>
                    <div className="relative flex justify-center gap-4">
                      <Button
                        buttonClasses=""
                        buttonLabelClasses=""
                        buttonType={"button"}
                        buttonIcon={isSubmit ? "fa-light fa-spinner fa-spin" : "fa-light fa-arrow-right-to-arc"}
                        buttonIconPosition={"left"}
                        buttonLabel={t("submit")}
                        functions={onSubmit}
                        buttonHasLink={false}
                        buttonDisabled={isSubmit}
                      />
                      <Button
                        buttonLabelClasses=""
                        buttonType={"button"}
                        buttonIcon={"fa-light fa-times"}
                        buttonIconPosition={"left"}
                        buttonLabel={t("cancel")}
                        buttonClasses={"!bg-slate-200 !text-slate-600 border border-slate-300"}
                        functions={closeModal}
                        buttonHasLink={false}
                      />
                    </div>
                  </>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default ManageInviteExecutivePopup;
