import React, { useState, useEffect, useRef } from "react";
import { setTitle } from "../../helpers/MetaTag";
import Select from "../../components/form/Select";
import Button from "../../components/form/Button";
import Table from "../../components/elements/table/Table";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { reorder } from "../../helpers";
import toastr from "toastr";
import moment from "moment";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/flatpickr.css";
import { useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import { Document, PDFDownloadLink } from "@react-pdf/renderer";
import SalesTeamReport from "../pdf/SalesTeamReport";

import { postData } from "../../services/api";
import { useTranslation } from "react-i18next";
import CompanyCreatePopup from "../../components/popup/CompanyCreatePopup";
import Pagination from "../../components/Common/Pagination";
import { Tooltip } from "react-tooltip";

const NewSale = () => {
  setTitle("Succession Planning Software for your Business with Succession Now | New Sale");
  const { i18n, t } = useTranslation();
  const role = localStorage.getItem("role");
  const user = useSelector((state) => state.auth?.user);
  const flatPicker = useRef(null);
  const [loading, setLoading] = useState(true);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [sortKey, setSortKey] = useState("ordering");
  const [sortValue, setSortValue] = useState(1);
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [startDataCount, setStartDataCount] = useState(0);
  const [endDataCount, setEndDataCount] = useState(0);
  const [results, setResults] = useState([]);
  const [actionValue, setActionValue] = useState({});
  const [checkboxValue, setCheckboxValue] = useState({});
  const [companyInfo, setCompanyInfo] = useState({});
  const [rawSalesteam, setRawSalesteam] = useState([]);
  const [reportData, setReportData] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);
  const [noOfPage, setNoOfPage] = useState(0);
  const [enabledCreateAccountPopup, setEnabledCreateAccountPopup] = useState(false);
  const [status, setStatus] = useState("");
  const extraData = [];

  const header = [
    {
      name: t("date"),
      width: "10%",
      align: "left",
    },
    {
      name: t("company"),
      width: "15%",
      align: "left",
    },
    {
      name: t("salesperson"),
      width: "15%",
      align: "left",
    },
    {
      name: t("invoice_and_w_9_sent"),
      width: "10%",
      align: "center",
    },
    {
      name: t("purchase_agreement_received"),
      width: "10%",
      align: "center",
    },
    {
      name: t("account_setup_completed"),
      width: "10%",
      align: "center",
    },
    {
      name: t("payment_received"),
      width: "10%",
      align: "center",
    },
    {
      name: t("training_scheduled"),
      width: "10%",
      align: "center",
    },
    {
      name: t("training_completed"),
      width: "10%",
      align: "center",
    },
  ];

  const tableHeadData = [
    {
      _id: 1,
      width: 40,
      name: t("date"),
      value: "created_at",
      align: "left",
      isSort: false,
      isFilter: false,
    },
    {
      _id: 2,
      width: 15,
      name: t("company"),
      value: "price",
      align: "left",
      isSort: false,
      isFilter: false,
    },
    {
      _id: 7,
      width: 10,
      name: t("invoice_and_w_9_sent"),
      value: "sales_commission_payment",
      align: "left",
      isSort: false,
      isFilter: false,
    },
    {
      _id: 5,
      width: 10,
      name: t("purchase_agreement_received"),
      value: "salesperson_commission_amount",
      align: "left",
      isSort: false,
      isFilter: false,
    },
    {
      _id: 6,
      width: 10,
      name: t("account_setup_completed"),
      value: "sales_percentage",
      align: "left",
      isSort: false,
      isFilter: false,
    },
    {
      _id: 8,
      width: 15,
      name: t("payment_received"),
      value: "consultant_id",
      align: "left",
      isSort: false,
      isFilter: false,
    },
    {
      _id: 9,
      width: 10,
      name: t("training_scheduled"),
      value: "consultant_percentage",
      align: "left",
      isSort: false,
      isFilter: false,
    },
    {
      _id: 10,
      width: 10,
      name: t("training_completed"),
      value: "consultant_commission_amount",
      align: "left",
      isSort: false,
      isFilter: false,
    },
    {
      _id: 20,
      width: 10,
      name: t("plan"),
      value: "consultant_commission_amount",
      align: "left",
      isSort: false,
      isFilter: false,
    },
    {
      _id: 3,
      width: 20,
      name: t("salesperson"),
      value: "created_at",
      align: "left",
      isSort: false,
      isFilter: false,
    },
    {
      _id: 11,
      width: 10,
      name: t("create_account"),
      value: "consultant_commission_amount",
      align: "left",
      isSort: false,
      isFilter: false,
    },
    {
      _id: 12,
      width: 10,
      name: t("no_sale"),
      value: "no_sale",
      align: "left",
      isSort: false,
      isFilter: false,
    },
    {
      _id: 4,
      width: 15,
      name: t("sales_information"),
      value: "sales_id",
      align: "left",
      isSort: false,
      isFilter: false,
    },
    {
      _id: 13,
      width: 10,
      name: t("action"),
      value: "",
      align: "left",
      isSort: false,
      isFilter: false,
    },
  ];

  useEffect(() => {
    const loadSalesteamList = async () => {
      setLoading(true);
      try {
        const result = await postData("salesteam/list", {
          sortQuery: { ["created_at"]: -1 },
          limit: limit,
          offset: offset,
          noSale: status,
        });
        if (result.status === 200) {
          setRawSalesteam(result.data);
          let totalResult = [];
          result.data.map((value, key) => {
            totalResult.push({
              _id: value?._id,
              rowData: [
                {
                  _id: 1,
                  width: 15,
                  type: "text",
                  data: value && value?.created_at ? moment(new Date(value?.created_at)).format("ll") : "N/A",
                  isColor: "",
                },
                {
                  _id: 2,
                  width: 15,
                  type: "link",
                  data: value?.company_name,
                  isColor: "",
                  value: "/edit-sales-tracking/" + value?._id,
                },
                {
                  _id: 7,
                  width: 20,
                  type: "checkbox",
                  data: value?.invoice_send,
                  value: value?.invoice_send,
                  isColor: "",
                  code: "invoice_send",
                  isCheckbox: true,
                },
                {
                  _id: 5,
                  width: 20,
                  type: "checkbox",
                  data: value?.purchase_agreement_received,
                  value: value?.purchase_agreement_received,
                  isColor: "",
                  code: "purchase_agreement_received",
                  isCheckbox: true,
                },
                {
                  _id: 6,
                  width: 20,
                  type: "checkbox",
                  data: value?.account_setup,
                  value: value?.account_setup,
                  isColor: "",
                  code: "account_setup",
                  isCheckbox: true,
                },
                {
                  _id: 8,
                  width: 20,
                  type: "checkboxText",
                  data: value?.payment_received,
                  // value: value?.payment_received,
                  ischeckboxData: value?.payment_received === 1 ? true : false,
                  checkboxData: <div className="text-xs text-slate-600 font-medium">{value && value?.payment_received_date ? moment(value?.payment_received_date).format("lll") : ""}</div>,
                  isColor: "",
                  code: "payment_received",
                  isCheckbox: true,
                  tdClasses: "",
                },
                {
                  _id: 9,
                  width: 20,
                  type: "checkbox",
                  data: value?.traning_schedule,
                  value: value?.traning_schedule,
                  isColor: "",
                  code: "traning_schedule",
                  isCheckbox: true,
                },
                {
                  _id: 10,
                  width: 20,
                  type: "checkbox",
                  data: value?.traning_complete,
                  value: value?.traning_complete,
                  isColor: "",
                  code: "traning_complete",
                  isCheckbox: true,
                },
                {
                  _id: 20,
                  width: 20,
                  type: "text",
                  data: (
                    <div className="relative flex flex-col text-teal-500 text-xs font-semibold">
                      {value?.plan_details?.name ? `${value.plan_details.name} (${value?.interval ? value.interval.charAt(0).toUpperCase() + value.interval.slice(1) : ""})` : "N/A"}
                      {value?.plan_details?.price ? <small className="text-slate-600 text-[10px] font-bold">Price: ${value?.plan_details?.price || "0.0"}</small> : ""}
                    </div>
                  ),
                },
                {
                  _id: 3,
                  width: 15,
                  type: "text",
                  data: value?.salesperson,
                  isColor: "",
                },
                {
                  _id: 11,
                  width: 20,
                  type: "button",
                  buttonLabel: t("create_new_account"),
                  buttonIcon: "fa-plus",
                  buttonHasLink: false,
                  isExist: value?.isCompanyExists && value?.isCompanyExists > 0 ? true : false,
                },
                {
                  _id: 12,
                  width: 20,
                  type: "checkbox",
                  data: value?.noSale,
                  value: value?.noSale,
                  isColor: "",
                  code: "noSale",
                  isCheckbox: true,
                },
                {
                  _id: 4,
                  width: 20,
                  type: "report",
                  data: "",
                  isColor: "",
                  info: value,
                },
                { _id: 13, width: 20, type: "action" },
              ],
              isBackgroundColor: value?.noSale && value?.noSale === 1 ? "bg-gray-100" : "",
            });
          });
          setTotalDataCount(result.count);
          setResults(totalResult);
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err.message);
      }
    };
    loadSalesteamList();
  }, [isUpdate, limit, offset, actionValue, status]);

  useEffect(() => {
    const loadReportData = async () => {
      setLoading(true);
      try {
        const result = await postData("salesteam/list", {
          sortQuery: { ["created_at"]: -1 },
          noSale: status,
        });
        if (result.status === 200) {
          setReportData(result.data);
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err.message);
      }
    };
    loadReportData();
  }, [isUpdate, actionValue, status]);

  useEffect(() => {
    let fieldValue = 2;
    if (checkboxValue && checkboxValue.isEnabled) {
      fieldValue = 1;
    } else {
      fieldValue = 2;
    }
    const handelUpdateStatus = async () => {
      setIsUpdate(false);
      try {
        const payData = await postData("salesteam/update-status", {
          id: checkboxValue?.id,
          [checkboxValue?.code]: fieldValue,
        });
        if (payData.data) {
          toastr.success(payData.message);
          setIsUpdate(true);
        } else {
          toastr.error(payData.message);
        }
      } catch (error) {
        toastr.error(error.message);
      }
    };
    if (Object.keys(checkboxValue).length > 0) {
      handelUpdateStatus();
    }
  }, [checkboxValue]);

  useEffect(() => {
    if (actionValue && actionValue.button_type === "delete") {
      const loadDeleteRecord = async () => {
        setIsUpdate(false);
        try {
          const statusData = await postData("salesteam/delete", {
            id: actionValue?.row_id,
          });
          if (statusData.data) {
            const index = results.findIndex((item) => item._id === actionValue?.row_id);
            if (index !== -1) {
              results.splice(index, 1);
              setResults(
                results.map((it) => {
                  return it;
                })
              );
            }
            setIsUpdate(true);
          } else {
            toastr.error(statusData.message);
          }
        } catch (error) {
          toastr.error(error.message);
        }
      };
      loadDeleteRecord();
    } else if (actionValue && actionValue.button_type === "others") {
      setEnabledCreateAccountPopup(true);
      const index = rawSalesteam.findIndex((item) => item._id === actionValue?.row_id);
      setCompanyInfo({
        _id: actionValue.row_id,
        company_name: rawSalesteam[index]?.company_name,
        number_of_employees: rawSalesteam[index]?.company_size,
        name: rawSalesteam[index]?.comapny_administrator,
        email: rawSalesteam[index]?.company_administrator_email,
        phone: rawSalesteam[index]?.company_administrator_phone,
        price: rawSalesteam[index]?.price,
        allData: rawSalesteam[index],
      });
    }
  }, [actionValue]);

  const handleOnDragEnd = async (result) => {
    //console.log("result",result);
    const items = reorder(results, result.source.index, result.destination.index);
    setResults(items);

    const res = await postData("position/ordering", {
      items: items,
    });
    if (res.status && res.status === 200) {
      toastr.success(res.message);
    } else {
      toastr.error(res.message);
    }
  };

  useEffect(() => {
    setNoOfPage(Math.ceil(totalDataCount / limit));
    const startItem = Number(totalDataCount > 0 ? 1 : 0);
    const endItem = Math.min(startItem + limit - 1, totalDataCount);
    setStartDataCount(startItem);
    setEndDataCount(endItem);
  }, [limit, totalDataCount]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * limit) % totalDataCount;
    console.log(`User requested page number ${event.selected}, which is offset ${newOffset}`);
    setOffset(newOffset);
    const startItem = Number(newOffset + 1);
    const endItem = Math.min(startItem + limit - 1, totalDataCount);
    setStartDataCount(startItem);
    setEndDataCount(endItem);
  };

  const getDownload = (e) => {
    e.preventDefault();
    if (e.target.tagName.toLowerCase() === "a") {
      window.open(e.target.href, "_blank");
    }
  };

  return (
    <div className="border bg-white border-gray-200 p-4 shadow-md rounded-xl">
      <div className="relative flex gap-3 items-center mb-3 flex-wrap sm:flex-nowrap gap-y-4">
        <div className="text-2xl font-bold">{t("sales_tracking")}</div>
        <div className="sm:ml-auto flex justify-end items-center" data-tooltip-id={`button${1}`} data-tooltip-place="right">
          <button
            type={"button"}
            className={"flex justify-center items-center gap-2 bg-teal-500 text-white rounded text-lg h-10 py-0 px-3 transition-all duration-200 cursor-pointer "}
            onClick={(e) => getDownload(e)}
          >
            <i className={"fa-fw fa-light fa-download"}></i>
            <div className={"text-sm font-medium whitespace-nowrap"}>
              <PDFDownloadLink
                document={
                  <Document>
                    <SalesTeamReport results={reportData} header={header} user={user} />
                  </Document>
                }
                fileName="sales_tracking_report.pdf"
              >
                {({ blob, url, loading, error }) => (loading ? t("loading_document") : t("sales_tracking_report"))}
              </PDFDownloadLink>
            </div>
          </button>

          <Tooltip
            id={`button${1}`}
            className="!text-xs !bg-dark-teal-600 z-50 rounded py-3 px-6"
            render={() => (
              <div className="gap-x-1 gap-y-1">
                <div className="text-[13px] font-normal leading-none flex items-center">
                  <div className="font-semibold">Download Sales Tracking Report</div>
                </div>
              </div>
            )}
          />
        </div>
      </div>
      <div className="relative flex gap-3 items-center mb-3 flex-wrap">
        <div className="relative">
          <Select
            xPlacement={"left"}
            dropdownButtonClass={"!bg-white"}
            selectedValue={limit}
            dropdownData={[
              { _id: 1000000, label: t("all_items"), value: 1000000 },
              ...[
                { _id: 10, label: "10 " + t("items"), value: 10 },
                { _id: 20, label: "20 " + t("items"), value: 20 },
                { _id: 30, label: "30 " + t("items"), value: 30 },
                { _id: 50, label: "50 " + t("items"), value: 50 },
                { _id: 100, label: "100 " + t("items"), value: 100 },
              ],
            ]}
            getSelectedValue={(e) => setLimit(e.value)}
          />
        </div>
        <div className="relative w-48">
          <Select
            xPlacement={"right"}
            dropdownButtonClass={"!bg-white"}
            transitionClass={"!w-auto"}
            dropdownClass={"w-48"}
            selectedValue={status}
            dropdownData={[
              { _id: "", label: "Select Status" },
              { _id: 2, label: t("active"), value: 2 },
              { _id: 1, label: t("no_sale"), value: 1 },
            ]}
            getSelectedValue={(e) => setStatus(e.value)}
          />
        </div>
      </div>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <div className="w-full bg-white rounded-md shadow mb-4">
          <Table
            tableData={results}
            tableHeadData={tableHeadData}
            totalRecord={results.length}
            loading={loading}
            getSortValue={setSortValue}
            getSortKey={setSortKey}
            isDeleteEnabled={true}
            editUrl={"/edit-sales-tracking"}
            isDraggable={false}
            getActionValue={(obj) => {
              setActionValue(obj);
            }}
            extraData={extraData}
            updateCheckboxHandler={(obj) => {
              setCheckboxValue(obj);
            }}
            tableImage={false}
          />
        </div>
      </DragDropContext>
      {noOfPage > 1 ? (
        <Pagination handlePageClick={handlePageClick} noOfPage={noOfPage} startDataCount={startDataCount} endDataCount={endDataCount} count={totalDataCount} />
      ) : (
        <div className="flex justify-center sm:justify-between my-4 text-sm text-black text-center sm:text-left">
          {totalDataCount > 0 ? (
            <div>
              <p>
                {t("showing")}: {startDataCount} to {endDataCount} of {totalDataCount}
              </p>
            </div>
          ) : (
            ""
          )}
        </div>
      )}
      {enabledCreateAccountPopup && (
        <CompanyCreatePopup
          isOpen={enabledCreateAccountPopup}
          setIsOpen={(val) => setEnabledCreateAccountPopup(val)}
          data={companyInfo}
          getActionValue={(obj) => {
            setActionValue(obj);
            setEnabledCreateAccountPopup(false);
          }}
        />
      )}
    </div>
  );
};

export default NewSale;
