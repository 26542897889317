import React, { useState, useEffect, useCallback } from "react";
import { setTitle } from "../../helpers/MetaTag";
import Table from "../../components/elements/table/Table";
import { DragDropContext } from "react-beautiful-dnd";
import { reorder } from "../../helpers";
import toastr from "toastr";
import moment from "moment";
import { useSelector } from "react-redux";
import { postData } from "../../services/api";
import Pagination from "../../components/Common/Pagination";
import Button from "../../components/form/Button";
import { Tooltip } from "react-tooltip";

import { useTranslation } from "react-i18next";
import ManagePositionGroupPopup from "../../components/popup/ManagePositionGroupPopup";
import MultipleButtonAction from "../../components/elements/multipleButtonAction/MultipleButtonAction";

const PositionGroup = () => {
  setTitle("Succession Planning Software for your Business with Succession Now | Position Group");
  const { i18n, t } = useTranslation();
  const user = useSelector((state) => state.auth?.user);
  const isTrial = localStorage.getItem("isTrial");
  const [loading, setLoading] = useState(true);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [sortKey, setSortKey] = useState("ordering");
  const [sortValue, setSortValue] = useState(1);
  const [results, setResults] = useState([]);
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [startDataCount, setStartDataCount] = useState(0);
  const [endDataCount, setEndDataCount] = useState(0);
  const [noOfPage, setNoOfPage] = useState(0);
  const [actionValue, setActionValue] = useState({});
  const [isOpen, setIsopen] = useState({
    _id: null,
    open: false,
  });
  const [isUpdate, setIsUpdate] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);

  const extraData = [
    {
      _id: 2,
      label: t("edit"),
      icon: "fa-pen-to-square",
      link: "/",
      type: "button",
      standout: false,
      buttonType: "edit-user-info",
      isVisabled: 1,
      ordering: 1,
    },
  ];

  const tableHeadData = [
    {
      _id: 1,
      width: 40,
      name: t("name"),
      value: "name",
      align: "left",
      isSort: false,
      isFilter: false,
    },
    {
      _id: 2,
      width: 15,
      name: t("Total Questions"),
      value: "total_questions",
      align: "left",
      isSort: false,
      isFilter: false,
    },
    {
      _id: 3,
      width: 15,
      name: t("created_date"),
      value: "created_at",
      align: "left",
      isSort: false,
      isFilter: false,
    },
    {
      _id: 5,
      width: 10,
      name: t("status"),
      value: "salesperson_commission_amount",
      align: "left",
      isSort: false,
      isFilter: false,
    },
    {
      _id: 6,
      width: 10,
      name: t("Default Status"),
      value: "setDefault",
      align: "left",
      isSort: false,
      isFilter: false,
    },
    {
      _id: 7,
      width: 10,
      name: t("action"),
      value: "",
      align: "right",
      isSort: false,
      isFilter: false,
    },
  ];

  const defaultStatusChange = async (data) => {
    setIsUpdate(false);
    try {
      const res = await postData("filter-item/make-default", {
        id: data,
      });
      if (res.data) {
        setResults(
          results.map((it) => {
            if (it?._id === data) {
              return {
                ...it,
                setDefault: 1,
              };
            } else {
              return it;
            }
          })
        );
        setIsUpdate(true);
      } else {
        toastr.error(res.message);
      }
    } catch (error) {
      toastr.error(error.message);
    }
  };

  const loadList = useCallback(async () => {
    setLoading(true);
    try {
      const result = await postData("filter-item/list", {
        sortQuery: { ["created_at"]: -1 },
        limit: limit,
        offset: offset,
        type: "position-group",
      });
      if (result.status === 200) {
        let totalResult = [];
        result.data.map((value, key) => {
          totalResult.push({
            _id: value?._id,
            rowData: [
              {
                _id: 1,
                width: 15,
                type: "text",
                data: value?.name,
                isColor: "",
              },
              {
                _id: 2,
                width: 15,
                type: "text",
                data: value && value?.created_at ? moment(new Date(value?.created_at)).format("MMM Do YYYY") : "N/A",
                isColor: "",
              },
              {
                _id: 5,
                width: 20,
                type: "status",
                statusLabel: value?.status === 1 ? t("active") : t("deactivated"),
                statusType: value?.status === 1 ? "success" : "warning",
              },
              {
                _id: 6,
                width: 20,
                type: "action",
                statusLabel: value?.status && value?.status === 1 ? t("active") : t("deactivated"),
                statusType: value?.status && value?.status === 1 ? "success" : "warning",
                data: [{ "edit-user-info": 1 }],
              },
            ],
          });
        });
        setTotalDataCount(result.count);
        setResults(result.data);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err.message);
    } finally {
      setLoading(false);
    }
  }, [limit, offset, isUpdate]);

  useEffect(() => {
    loadList();
  }, [loadList]);

  const tableData =
    results &&
    Array?.isArray(results) &&
    results.length > 0 &&
    results?.map((value, key) => {
      return {
        _id: value?._id,
        rowData: [
          {
            _id: 1,
            width: 15,
            type: "text",
            data: value?.name,
            isColor: "",
          },
          {
            _id: 2,
            width: 15,
            type: "text",
            data: value?.total_questions || 0,
            isColor: "",
          },
          {
            _id: 2,
            width: 15,
            type: "text",
            data: value && value?.created_at ? moment(new Date(value?.created_at)).format("MMM Do YYYY") : "N/A",
            isColor: "",
          },
          {
            _id: 5,
            width: 20,
            type: "status",
            statusLabel: value?.status === 1 ? t("active") : t("deactivated"),
            statusType: value?.status === 1 ? "success" : "warning",
          },
          {
            _id: 6,
            width: 20,
            type: "text",
            data: (
              <>
                <div className="flex items-center" data-tooltip-id={`isDefault${value?._id}`} data-tooltip-place="right">
                  <div
                    className={`flex items-center text-[10px] font-medium leading-tight rounded py-1 px-2 cursor-pointer ${
                      value?.setDefault === 1 ? "bg-blue-100 text-blue-600" : "bg-amber-100 text-amber-600"
                    }`}
                    onClick={() => defaultStatusChange(value?._id)}
                  >
                    {value?.setDefault === 1 ? t("Default") : t("Make Default")}
                  </div>
                  <Tooltip
                    id={`email${value?._id}`}
                    className="!text-xs !bg-dark-teal-600 z-50 shadow-lg rounded py-3 px-6"
                    render={() => (
                      <div className="gap-x-1">
                        <div className="text-[13px] font-normal leading-none flex items-center">
                          <div className="font-semibold">
                            {value?.setDefault === 1
                              ? "This position is set as the default. Any new question added will automatically be assigned to this position."
                              : "This position is not set as the default. Please review its assignment for new questions."}
                          </div>
                        </div>
                      </div>
                    )}
                  />
                </div>
              </>
            ),
          },
          {
            _id: 7,
            width: 20,
            type: "action",
            statusLabel: value?.status && value?.status === 1 ? t("active") : t("deactivated"),
            statusType: value?.status && value?.status === 1 ? "success" : "warning",
            data: [{ "edit-user-info": 1 }],
          },
        ],
      };
    });

  useEffect(() => {
    setNoOfPage(Math.ceil(totalDataCount / limit));
    const startItem = Number(totalDataCount > 0 ? 1 : 0);
    const endItem = Math.min(startItem + limit - 1, totalDataCount);
    setStartDataCount(startItem);
    setEndDataCount(endItem);
  }, [limit, totalDataCount]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * limit) % totalDataCount;
    setOffset(newOffset);
    const startItem = Number(newOffset + 1);
    const endItem = Math.min(startItem + limit - 1, totalDataCount);
    setStartDataCount(startItem);
    setEndDataCount(endItem);
    setSelectedRow([]);
  };

  const handleOnDragEnd = async (result) => {
    const items = reorder(results, result.source.index, result.destination.index);
    setResults(items);

    const res = await postData("position-group/ordering", {
      items: items,
    });
    if (res.status && res.status === 200) {
      toastr.success(res.message);
    } else {
      toastr.error(res.message);
    }
  };

  useEffect(() => {
    if (actionValue && actionValue.button_type === "status") {
      console.log(actionValue, "actionValue", results);

      const findData = results && Array?.isArray(results) && results?.find((item) => item._id === actionValue?.row_id);
      if (findData?.total_questions > 0) {
        toastr?.warning("You cannot deactivate because there are questions assigned under this position group.");
      } else {
        const loadStatusChange = async () => {
          setIsUpdate(false);
          try {
            const statusData = await postData("filter-item/status-change", {
              id: actionValue?.row_id,
              status: actionValue?.current_status,
            });
            if (statusData.data) {
              setResults(
                results.map((it) => {
                  if (it?._id === statusData.data?._id) {
                    return {
                      ...it,
                      status: statusData.data?.status,
                    };
                  } else {
                    return it;
                  }
                })
              );
              setIsUpdate(true);
            } else {
              toastr.error(statusData.message);
            }
          } catch (error) {
            toastr.error(error.message);
          }
        };
        loadStatusChange();
      }
    }
    //  else if (actionValue && actionValue.button_type === "delete") {
    //   const loadDeleteRecord = async () => {
    //     setIsUpdate(false);
    //     try {
    //       const statusData = await postData("position/delete", {
    //         id: actionValue?.row_id,
    //       });
    //       if (statusData.data) {
    //         const index = positions.findIndex(
    //           (item) => item._id === actionValue?.row_id
    //         );
    //         if (index !== -1) {
    //           positions.splice(index, 1);
    //           setPositions(
    //             positions.map((it) => {
    //               return it;
    //             })
    //           );
    //         }
    //         setIsUpdate(true);
    //       } else {
    //         toastr.error(statusData.message);
    //       }
    //     } catch (error) {
    //       toastr.error(error.message);
    //     }
    //   };
    //   loadDeleteRecord();
    // }
    else if (actionValue && actionValue.button_type === "edit-user-info") {
      setIsopen({ open: true, _id: actionValue?.row_id });
    }
    //console.log('actionValue',actionValue)
  }, [actionValue]);

  return (
    <>
      <div className="border bg-white border-gray-200 p-4 shadow-md rounded-xl">
        <div className="relative flex gap-3 items-center mb-3 flex-wrap sm:flex-nowrap gap-y-4">
          <div className="text-2xl font-bold">{t("position_group")}</div>
          {!isTrial && (
            <div className="sm:ml-auto flex justify-end items-center" data-tooltip-id={`button${1}`} data-tooltip-place="right">
              <Button
                buttonType={"button"}
                buttonIcon={"fa-light fa-plus"}
                buttonIconPosition={"left"}
                buttonLabel={t("add")}
                buttonHasLink={false}
                functions={() => setIsopen({ _id: null, open: true })}
              />

              <Tooltip
                id={`button${1}`}
                className="!text-xs !bg-teal-500 z-50 rounded py-2 px-2 !opacity-100"
                render={() => (
                  <div className="gap-x-1 gap-y-1">
                    <div className="text-[13px] font-normal leading-none flex items-center">
                      <div className="font-semibold">{t("create_new_group")}</div>
                    </div>
                  </div>
                )}
              />
            </div>
          )}
        </div>
        {/* <div className="relative flex gap-3 items-center mb-3 flex-wrap">
          <div className="relative">
            <MultipleButtonAction
              selectedRow={selectedRow}
              setSelectedRow={setSelectedRow}
              list={results}
              setList={setResults}
              loadList={loadList}
              API_URL_STATUS={"filter-item/status-change"}
              API_URL_DELETE={"usertype/delete"}
            />
          </div>
        </div> */}
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <div className="w-full bg-white rounded-md shadow overflow-hidden">
            <Table
              tableData={tableData}
              tableHeadData={tableHeadData}
              totalRecord={results.length}
              loading={loading}
              getSortValue={setSortValue}
              getSortKey={setSortKey}
              //editUrl={"/edit-lead-tracking"}
              isDeleteEnabled={false}
              isDraggable={false}
              getActionValue={(obj) => {
                setActionValue(obj);
              }}
              extraData={extraData}
              isStatusUpdate={true}
              tableImage={false}
              selectedRow={selectedRow}
              setSelectedRow={setSelectedRow}
              selectable={true}
            />
          </div>
        </DragDropContext>
        {noOfPage > 1 ? (
          <Pagination handlePageClick={handlePageClick} noOfPage={noOfPage} startDataCount={startDataCount} endDataCount={endDataCount} count={totalDataCount} />
        ) : (
          <div className="flex justify-center sm:justify-between my-4 text-sm text-black text-center sm:text-left">
            {totalDataCount > 0 ? (
              <div>
                <p>
                  {t("showing")}: {startDataCount} to {endDataCount} of {totalDataCount}
                </p>
              </div>
            ) : (
              ""
            )}
          </div>
        )}
      </div>
      {isOpen?.open && <ManagePositionGroupPopup isOpen={isOpen?.open} id={isOpen?._id} setIsOpen={setIsopen} loadList={loadList} />}
    </>
  );
};

export default PositionGroup;
