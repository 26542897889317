import React from "react";
import { Document, Page, Text, View, Image, Font } from "@react-pdf/renderer";
import logo from "../../logo-succession-now.png";
import Shape from "../../shape.png";
import moment from "moment/moment";

import { useTranslation } from "react-i18next";

import RobotoLight from "../../assets/font/Roboto-Light.ttf";
import RobotoRegular from "../../assets/font/Roboto-Regular.ttf";
import RobotoItalic from "../../assets/font/Roboto-Italic.ttf";
import RobotoMedium from "../../assets/font/Roboto-Medium.ttf";
import RobotoBold from "../../assets/font/Roboto-Bold.ttf";

Font.register({ family: "RobotoLight", src: RobotoLight });
Font.register({ family: "RobotoRegular", src: RobotoRegular });
Font.register({ family: "RobotoItalic", src: RobotoItalic });
Font.register({ family: "RobotoMedium", src: RobotoMedium });
Font.register({ family: "RobotoBold", src: RobotoBold });

const PerspectiveOverallReport = ({
  title,
  headingTitle,
  user,
  companyLogo,
  type,
  perspectiveReport,
}) => {
  const { i18n, t } = useTranslation();
  return (
    <>
      <Page
        size="A4"
        orientation="landscape"
        style={[
          {
            padding: 20,
            fontFamily: "RobotoRegular",
            lineHeight: 1.2,
            fontSize: 9,
            color: "#334155",
            backgroundColor: "#f1f5f9",
          },
        ]}
      >
        <View
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            right: 0,
            height: 160,
            zIndex: -1,
            opacity: 0.1,
          }}
        >
          <Image style={{ objectFit: "cover" }} src={Shape} />
        </View>
        <View
          style={{
            position: "absolute",
            left: 0,
            bottom: -10,
            right: 0,
            height: 160,
            zIndex: -1,
            opacity: 0.075,
          }}
        >
          <Image style={{ objectFit: "contain" }} src={logo} />
        </View>
        <View
          style={{
            position: "absolute",
            right: 30,
            top: 30,
            width: 120,
            height: 24,
          }}
        >
          <Image style={{ objectFit: "contain" }} src={logo} />
        </View>
        <View
          style={{
            paddingBottom: 20,
            width: "100%",
            height: "90%",
            margin: "0 auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
          }}
        >
          <View
            style={{
              paddingBottom: 20,
              margin: "0 auto",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <View
              style={{
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                fontSize: 16,
              }}
            >
              <Image
                style={[
                  {
                    width: "400",
                    height: "40",
                    objectFit: "contain",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  },
                ]}
                src={companyLogo}
              />
            </View>
            <View
              style={[{ display: "flex", flexDirection: "row", marginTop: 25 }]}
            >
              <Text
                style={[
                  {
                    fontFamily: "RobotoBold",
                    fontSize: 28,
                    textTransform: "capitalize",
                    color: "#334155",
                  },
                ]}
              >
                {user?.company_name}
              </Text>
            </View>
            <View
              style={[{ display: "flex", flexDirection: "row", marginTop: 5 }]}
            >
              <Text
                style={[
                  {
                    fontFamily: "RobotoBold",
                    fontSize: 12,
                    textTransform: "uppercase",
                    color: "#14b8a6",
                    letterSpacing: 3,
                  },
                ]}
              >
                {title}
              </Text>
            </View>
          </View>
          <View
            style={[
              {
                position: "absolute",
                bottom: 80,
                display: "flex",
                flexDirection: "row",
                textAlign: "center",
                justifyContent: "center",
                marginTop: "auto",
              },
            ]}
          >
            <View
              style={[
                {
                  display: "flex",
                  flexDirection: "row",
                  marginTop: 5,
                  textAlign: "center",
                  justifyContent: "center",
                  marginRight: 12,
                },
              ]}
            >
              <Text
                style={[
                  {
                    fontFamily: "RobotoMedium",
                    fontSize: 12,
                    textAlign: "center",
                    color: "#334155",
                  },
                ]}
              >
                Created by :
              </Text>
              <Text
                style={{
                  fontFamily: "RobotoBold",
                  fontSize: 12,
                  marginLeft: 2,
                  color: "#334155",
                }}
              >
                {user?.name}({user?.title})
              </Text>
            </View>
            <View
              style={[{ display: "flex", flexDirection: "row", marginTop: 5 }]}
            >
              <Text
                style={[
                  {
                    display: "flex",
                    flexDirection: "row",
                    fontFamily: "RobotoMedium",
                    fontSize: 12,
                    color: "#334155",
                  },
                ]}
              >
                Date:
              </Text>
              <Text
                style={{
                  fontFamily: "RobotoBold",
                  fontSize: 12,
                  marginLeft: 2,
                  color: "#334155",
                }}
              >
                {moment(new Date()).format("MM/DD/YYYY")}
              </Text>
            </View>
          </View>
        </View>
      </Page>
      {perspectiveReport &&
        Array.isArray(perspectiveReport) &&
        perspectiveReport?.length > 0 &&
        perspectiveReport?.map((item) => (
          <Page
            size="A4"
            orientation="landscape"
            style={{
              padding: 16,
              fontFamily: "RobotoRegular",
              lineHeight: 1.2,
              fontSize: 9,
              color: "#334155",
              backgroundColor: "#f1f5f9",
            }}
          >
            <View
              style={{
                position: "absolute",
                left: 0,
                top: 0,
                right: 0,
                bottom: 0,
                zIndex: 0,
              }}
              fixed
            >
              <View
                style={{
                  position: "absolute",
                  left: 0,
                  top: 0,
                  right: 0,
                  width: "100%",
                  height: 160,
                  zIndex: -1,
                  opacity: 0.1,
                }}
              >
                <Image style={{ objectFit: "cover" }} src={Shape} />
              </View>
              <View
                style={{
                  position: "absolute",
                  width: "100%",
                  left: 0,
                  bottom: -10,
                  right: 0,
                  height: 160,
                  zIndex: -1,
                  opacity: 0.075,
                }}
              >
                <Image style={{ objectFit: "contain" }} src={logo} />
              </View>
            </View>
            <View
              style={{
                border: "1px solid #e2e8f0",
                borderRadius: 16,
                overflow: "hidden",
                marginBottom: 20,
              }}
            >
              <View
                style={{
                  width: "100%",
                  borderBottomLeftRadius: 16,
                  borderBottomRightRadius: 16,
                  backgroundColor: "white",
                }}
              >
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    borderTop: "1px solid #e2e8f0",
                  }}
                >
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      width: "100%",
                      maxWidth: "100%",
                      paddingHorizontal: 12,
                      paddingVertical: 12,
                      rowGap: 6,
                    }}
                  >
                    <View
                      style={[
                        {
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                          width: "100%",
                          maxWidth: "100%",
                        },
                      ]}
                    >
                      <Text
                        style={[
                          {
                            whiteSpace: "nowrap",
                            fontFamily: "RobotoBold",
                            fontSize: 9,
                            textTransform: "uppercase",
                            lineHeight: 1,
                          },
                        ]}
                      >
                        Name
                      </Text>
                    </View>
                    <View
                      style={[
                        {
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                          width: "100%",
                          maxWidth: "100%",
                        },
                      ]}
                    >
                      <Text
                        style={{
                          fontSize: 11,
                          lineHeight: 1,
                          fontFamily: "RobotoRegular",
                        }}
                      >
                        {item?.first_name + " " + item?.last_name}
                      </Text>
                    </View>
                  </View>
                </View>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    borderTop: "1px solid #e2e8f0",
                  }}
                >
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      width: "100%",
                      maxWidth: "100%",
                      paddingHorizontal: 12,
                      paddingVertical: 12,
                      rowGap: 6,
                    }}
                  >
                    <View
                      style={[
                        {
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                          width: "100%",
                          maxWidth: "100%",
                        },
                      ]}
                    >
                      <Text
                        style={[
                          {
                            whiteSpace: "nowrap",
                            fontFamily: "RobotoBold",
                            fontSize: 9,
                            textTransform: "uppercase",
                            lineHeight: 1,
                          },
                        ]}
                      >
                        Level of Education
                      </Text>
                    </View>
                    <View
                      style={[
                        {
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                          width: "100%",
                          maxWidth: "100%",
                        },
                      ]}
                    >
                      <Text
                        style={{
                          fontSize: 11,
                          lineHeight: 1,
                          fontFamily: "RobotoRegular",
                        }}
                      >
                        {item?.education_details?.name || "N/A"}
                      </Text>
                    </View>
                  </View>
                </View>

                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    borderTop: "1px solid #e2e8f0",
                  }}
                >
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      width: "100%",
                      maxWidth: "100%",
                      paddingHorizontal: 12,
                      paddingVertical: 12,
                      rowGap: 6,
                    }}
                  >
                    <View
                      style={[
                        {
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                          width: "100%",
                          maxWidth: "100%",
                        },
                      ]}
                    >
                      <Text
                        style={[
                          {
                            whiteSpace: "nowrap",
                            fontFamily: "RobotoBold",
                            fontSize: 9,
                            textTransform: "uppercase",
                            lineHeight: 1,
                          },
                        ]}
                      >
                        Field of Study
                      </Text>
                    </View>
                    <View
                      style={[
                        {
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                          width: "100%",
                          maxWidth: "100%",
                        },
                      ]}
                    >
                      <Text
                        style={{
                          fontSize: 11,
                          lineHeight: 1,
                          fontFamily: "RobotoRegular",
                        }}
                      >
                        {item?.study_details?.name || "N/A"}
                      </Text>
                    </View>
                  </View>
                </View>

                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    borderTop: "1px solid #e2e8f0",
                  }}
                >
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      width: "100%",
                      maxWidth: "100%",
                      paddingHorizontal: 12,
                      paddingVertical: 12,
                      rowGap: 6,
                    }}
                  >
                    <View
                      style={[
                        {
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                          width: "100%",
                          maxWidth: "100%",
                        },
                      ]}
                    >
                      <Text
                        style={[
                          {
                            whiteSpace: "nowrap",
                            fontFamily: "RobotoBold",
                            fontSize: 9,
                            textTransform: "uppercase",
                            lineHeight: 1,
                          },
                        ]}
                      >
                        Industry Experience
                      </Text>
                    </View>
                    <View
                      style={[
                        {
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                          maxWidth: "100%",
                          rowGap: 4,
                        },
                      ]}
                    >
                      <View
                        style={[
                          {
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            flexWrap: "wrap",
                            width: "100%",
                            maxWidth: "100%",
                            columnGap: 6,
                          },
                        ]}
                      >
                        <View
                          style={[
                            {
                              width: 4,
                              height: 4,
                              backgroundColor: "#334155",
                              borderRadius: 10,
                              marginTop: 2,
                            },
                          ]}
                        />
                        {/* <View
                          style={[
                            {
                              display: "flex",
                              flexDirection: "row",
                              flexWrap: "wrap",
                              width: "15%",
                              maxWidth: "100%",
                            },
                          ]}
                        >
                          <Text
                            style={{
                              fontSize: 10,
                              lineHeight: 1,
                              fontFamily: "RobotoMedium",
                            }}
                          >
                            Industry 1
                          </Text>
                        </View> */}
                        <View
                          style={[
                            {
                              display: "flex",
                              flexDirection: "row",
                              flexWrap: "wrap",
                              width: "80%",
                              maxWidth: "100%",
                            },
                          ]}
                        >
                          <Text
                            style={{
                              fontSize: 11,
                              lineHeight: 1,
                              fontFamily: "RobotoRegular",
                            }}
                          >
                            {item?.industry_details?.name || "N/A"}
                          </Text>
                        </View>
                      </View>
                      <View
                        style={[
                          {
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            flexWrap: "wrap",
                            width: "100%",
                            maxWidth: "100%",
                            columnGap: 6,
                          },
                        ]}
                      >
                        <View
                          style={[
                            {
                              width: 4,
                              height: 4,
                              backgroundColor: "#334155",
                              borderRadius: 10,
                              marginTop: 2,
                            },
                          ]}
                        />
                        {/* <View
                          style={[
                            {
                              display: "flex",
                              flexDirection: "row",
                              flexWrap: "wrap",
                              width: "15%",
                              maxWidth: "100%",
                            },
                          ]}
                        >
                          <Text
                            style={{
                              fontSize: 10,
                              lineHeight: 1,
                              fontFamily: "RobotoMedium",
                            }}
                          >
                            Industry 2
                          </Text>
                        </View> */}
                        <View
                          style={[
                            {
                              display: "flex",
                              flexDirection: "row",
                              flexWrap: "wrap",
                              width: "80%",
                              maxWidth: "100%",
                            },
                          ]}
                        >
                          <Text
                            style={{
                              fontSize: 11,
                              lineHeight: 1,
                              fontFamily: "RobotoRegular",
                            }}
                          >
                            {item?.industry_one_details?.name || "N/A"}
                          </Text>
                        </View>
                      </View>
                      <View
                        style={[
                          {
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            flexWrap: "wrap",
                            width: "100%",
                            maxWidth: "100%",
                            columnGap: 6,
                          },
                        ]}
                      >
                        <View
                          style={[
                            {
                              width: 4,
                              height: 4,
                              backgroundColor: "#334155",
                              borderRadius: 10,
                              marginTop: 2,
                            },
                          ]}
                        />
                        {/* <View
                          style={[
                            {
                              display: "flex",
                              flexDirection: "row",
                              flexWrap: "wrap",
                              width: "15%",
                              maxWidth: "100%",
                            },
                          ]}
                        >
                          <Text
                            style={{
                              fontSize: 10,
                              lineHeight: 1,
                              fontFamily: "RobotoMedium",
                            }}
                          >
                            Industry 3
                          </Text>
                        </View> */}
                        <View
                          style={[
                            {
                              display: "flex",
                              flexDirection: "row",
                              flexWrap: "wrap",
                              width: "80%",
                              maxWidth: "100%",
                            },
                          ]}
                        >
                          <Text
                            style={{
                              fontSize: 11,
                              lineHeight: 1,
                              fontFamily: "RobotoRegular",
                            }}
                          >
                            {item?.industry_two_details?.name || "N/A"}
                          </Text>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
                {/* <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    borderTop: "1px solid #e2e8f0",
                  }}
                >
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      width: "100%",
                      maxWidth: "100%",
                      paddingHorizontal: 12,
                      paddingVertical: 12,
                      rowGap: 6,
                    }}
                  >
                    <View
                      style={[
                        {
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                          width: "100%",
                          maxWidth: "100%",
                        },
                      ]}
                    >
                      <Text
                        style={[
                          {
                            whiteSpace: "nowrap",
                            fontFamily: "RobotoBold",
                            fontSize: 9,
                            textTransform: "uppercase",
                            lineHeight: 1,
                          },
                        ]}
                      >
                        Level of Education
                      </Text>
                    </View>
                    <View
                      style={[
                        {
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                          width: "100%",
                          maxWidth: "100%",
                        },
                      ]}
                    >
                      <Text
                        style={{
                          fontSize: 11,
                          lineHeight: 1,
                          fontFamily: "RobotoRegular",
                        }}
                      >
                        {item?.education_details?.name || "N/A"}
                      </Text>
                    </View>
                  </View>
                </View> */}

                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    borderTop: "1px solid #e2e8f0",
                  }}
                >
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      width: "100%",
                      maxWidth: "100%",
                      paddingHorizontal: 12,
                      paddingVertical: 12,
                      rowGap: 6,
                    }}
                  >
                    <View
                      style={[
                        {
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                          width: "100%",
                          maxWidth: "100%",
                        },
                      ]}
                    >
                      <Text
                        style={[
                          {
                            whiteSpace: "nowrap",
                            fontFamily: "RobotoBold",
                            fontSize: 9,
                            textTransform: "uppercase",
                            lineHeight: 1,
                          },
                        ]}
                      >
                        Comment
                      </Text>
                    </View>
                    <View
                      style={[
                        {
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                          width: "100%",
                          maxWidth: "100%",
                        },
                      ]}
                    >
                      <Text
                        style={{
                          fontSize: 11,
                          lineHeight: 1,
                          fontFamily: "RobotoRegular",
                        }}
                      >
                        {item?.comments || "N/A"}
                      </Text>
                    </View>
                  </View>
                </View>



                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    borderTop: "1px solid #e2e8f0",
                  }}
                >
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end",
                      width: "100%",
                      maxWidth: "100%",
                      paddingHorizontal: 12,
                      paddingVertical: 12,
                      rowGap: 3,
                    }}
                  >
                    <View
                      style={[
                        {
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-end",
                          flexWrap: "wrap",
                          width: "100%",
                          maxWidth: "100%",
                        },
                      ]}
                    >
                      <Text
                        style={[
                          {
                            whiteSpace: "nowrap",
                            fontFamily: "RobotoMedium",
                            fontSize: 8,
                            textTransform: "uppercase",
                            lineHeight: 1,
                          },
                        ]}
                      >
                        Submitted by
                      </Text>
                    </View>
                    <View
                      style={[
                        {
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-end",
                          flexWrap: "wrap",
                          width: "100%",
                          maxWidth: "100%",
                        },
                      ]}
                    >
                      <Text
                        style={{
                          fontSize: 11,
                          lineHeight: 1,
                          fontFamily: "RobotoBold",
                        }}
                      >
                        {item?.submitted_by}
                      </Text>
                    </View>
                  </View>
                </View>

              </View>
            </View>
            {/* <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "100%",
                  maxWidth: "100%",
                  paddingHorizontal: 12,
                  paddingVertical: 12,
                  rowGap: 6,
                }}
              >
                <View
                  style={[
                    {
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      width: "100%",
                      maxWidth: "100%",
                    },
                  ]}
                >
                  <Text
                    style={[
                      {
                        whiteSpace: "nowrap",
                        fontFamily: "RobotoBold",
                        fontSize: 9,
                        textTransform: "uppercase",
                        lineHeight: 1,
                      },
                    ]}
                  >
                    Comment
                  </Text>
                </View>
                <View
                  style={[
                    {
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      width: "100%",
                      maxWidth: "100%",
                    },
                  ]}
                >
                  <Text
                    style={{
                      fontSize: 10,
                      lineHeight: 1.4,
                      fontFamily: "RobotoLight",
                    }}
                  >
                    {item?.comments || "N/A"}
                  </Text>
                </View>
              </View>
            </View> */}
          </Page>
        ))}
    </>
  );
};

export default PerspectiveOverallReport;
