import React from "react";
import Tbody from "./Tbody";
import Thead from "./Thead";
import { Droppable } from "react-beautiful-dnd";

const Table = ({
  tableClasses,
  tableData,
  tableHeadData,
  tdClasses,
  totalRecord,
  loading,
  getSortValue = () => {},
  getSortKey = () => {},
  editUrl,
  isStatusUpdate,
  isDeleteEnabled,
  isDraggable,
  getActionValue = () => {},
  isUser,
  detailsUrl,
  othersAction = () => {},
  loginAs,
  extraData,
  updateCheckboxHandler = () => {},
  tableImage,
  totalDataCount,
  selectable = false,
  selectedRow = [],
  setSelectedRow = () => {},
}) => {
  function selectAllHandler() {
    if (
      tableData
        ?.filter((row) => !row?.disabled)
        .every((row) => selectedRow.includes(row?._id))
    ) {
      setSelectedRow([]);
    } else {
      setSelectedRow(
        tableData?.filter((row) => !row?.disabled)?.map((row) => row?._id)
      );
    }
  }

  function singleSelectHandler(row) {
    if (selectedRow?.some((id) => id === row)) {
      setSelectedRow(selectedRow?.filter((id) => id !== row));
    } else {
      setSelectedRow([...selectedRow, row]);
    }
  }

  return (
    <>
      <div
        className={
          "w-full scroll-smooth scrollbar overflow-auto  " + tableClasses
        }
      >
        {isDraggable ? (
          <table className="min-w-max w-full table-auto">
            <Thead
              tableHeadData={tableHeadData}
              getSortValue={getSortValue}
              getSortKey={getSortKey}
              totalDataCount={totalRecord}
              selectable={selectable}
              selectAllHandler={selectAllHandler}
              checked={
                tableData?.length > 0 &&
                selectedRow &&
                Array.isArray(selectedRow) &&
                selectedRow?.length > 0 &&
                tableData
                  ?.filter((row) => !row?.disabled)
                  .every((row) => selectedRow.includes(row?._id))
              }
            />
            <Droppable droppableId={"table-body"}>
              {(provided, snapshot) => (
                <Tbody
                  tableData={tableData}
                  totalRecord={totalRecord}
                  loading={loading}
                  editUrl={editUrl}
                  isStatusUpdate={isStatusUpdate}
                  isDeleteEnabled={isDeleteEnabled}
                  isDraggable={isDraggable}
                  dropableRef={provided.innerRef}
                  droppableProps={provided.droppableProps}
                  getActionValue={(obj) => {
                    getActionValue(obj);
                  }}
                  isUser={isUser}
                  detailsUrl={detailsUrl}
                  othersAction={othersAction}
                  loginAs={loginAs}
                  extraData={extraData}
                  tableImage={tableImage}
                  selectable={selectable}
                  singleSelectHandler={singleSelectHandler}
                  selectedRow={selectedRow}
                  updateCheckboxHandler={updateCheckboxHandler}
                />
              )}
            </Droppable>
          </table>
        ) : (
          <table className="min-w-max w-full table-auto">
            <Thead
              tableHeadData={tableHeadData}
              getSortValue={getSortValue}
              getSortKey={getSortKey}
              totalDataCount={totalRecord}
              selectable={selectable}
              selectAllHandler={selectAllHandler}
              checked={
                tableData?.length > 0 &&
                selectedRow &&
                Array.isArray(selectedRow) &&
                selectedRow?.length > 0 &&
                tableData
                  ?.filter((row) => !row?.disabled)
                  .every((row) => selectedRow.includes(row?._id))
              }
            />
            <Tbody
              tableData={tableData}
              totalRecord={totalRecord}
              tdClasses={tdClasses}
              loading={loading}
              editUrl={editUrl}
              isStatusUpdate={isStatusUpdate}
              isDeleteEnabled={isDeleteEnabled}
              isDraggable={isDraggable}
              getActionValue={(obj) => getActionValue(obj)}
              isUser={isUser}
              detailsUrl={detailsUrl}
              othersAction={othersAction}
              loginAs={loginAs}
              extraData={extraData}
              tableImage={tableImage}
              selectable={selectable}
              singleSelectHandler={singleSelectHandler}
              selectedRow={selectedRow}
              updateCheckboxHandler={updateCheckboxHandler}
            />
          </table>
        )}
      </div>
    </>
  );
};

export default Table;
