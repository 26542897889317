import React from 'react';

const Accordian = ({ title, children, id, isOpen, toggleAccordion, divClass, childClass }) => {
  return (
    <div className={"relative " + (divClass)} >
      <div className="flex justify-between items-center px-5 py-4 bg-transparent cursor-pointer" onClick={() => toggleAccordion(id)}>
        <div className="text-base font-bold text-slate-700">{title}</div>
        <div className={`text-xl text-teal-500 transition-transform ${isOpen ? 'transform rotate-45' : ''}`}>
          <i className="far fa-fw fa-plus"></i>
        </div>
      </div>
      <div className={`overflow-hidden transition-max-height duration-300 ${isOpen ? 'max-h-[20000px]' : 'max-h-0'}`}>
        <div className={"py-4 px-5 " + (childClass)}>{children}</div>
      </div>
    </div>
  );
};

export default Accordian;