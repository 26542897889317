import React, { useState, useEffect, useCallback } from "react";
import Checkbox from "../../components/form/Checkbox";
import { postData } from "../../services/api";
import { Tooltip } from "react-tooltip";
import { setTitle } from "../../helpers/MetaTag";
import { useTranslation } from "react-i18next";
import ConcernPredictorsItem from "../../components/section/ManageTiersConcernPredictors/ConcernPredictorsItem";
import { useDebounce } from "../../helpers/useDebounce";
import Button from "../../components/form/Button";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import NoDataFound from "../../components/NoDataFound";

const QuestionGroup = () => {
  setTitle("Succession Planning Software for your Business with Succession Now | Question group");
  const { t } = useTranslation();
  const [concerns, setConcerns] = useState([]);
  const [predictors, setPredictors] = useState([]);
  const [totalSelectedConcerns, setTotalSelectedConcerns] = useState(0);
  const [totalSelectedPredictors, setTotalSelectedPredictors] = useState(0);
  const [questionData, setQuestionData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [tabType, setTabType] = useState("concern");

  //Question group List Api Call
  const loadList = useCallback(async () => {
    setLoading(true);
    try {
      const result = await postData("heading/question-group", {
        status: 1,
        type: tabType,
      });

      if (result.data) {
        setQuestionData(result?.data);
      }
      setTotalDataCount(result.count);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err.message);
    } finally {
      setLoading(false);
    }
  }, [tabType]);

  useEffect(() => {
    loadList();
  }, [loadList]);

  useEffect(() => {
    // Heading list
    const loadHeadingList = async () => {
      try {
        const result = await postData("heading/list", {
          sortQuery: { ordering: 1 },
          isGlobal: 1,
        });
        if (result.data) {
          let _concerns = [];
          let _predictors = [];
          result.data.map((item) => {
            if (item?.type === "concern") {
              _concerns.push({
                _id: item?._id,
                name: item?.name,
                description: item?.description,
                alias: item?.alias,
                weight: item?.weight,
                created_at: new Date(),
                isEnabled: false,
                disabled: false,
              });
            } else if (item?.type === "predictors") {
              _predictors.push({
                _id: item?._id,
                name: item?.name,
                description: item?.description,
                alias: item?.alias,
                weight: item?.weight,
                created_at: new Date(),
                isEnabled: false,
                disabled: false,
              });
            }
          });
          setConcerns(_concerns);
          setPredictors(_predictors);
        }
      } catch (err) {
        console.log(err.message);
      }
    };
    loadHeadingList();
  }, []);

  const updateHandler = (heading_id, event, type) => {
    if (event) {
      if (type === "concern") {
        setConcerns((concern) =>
          concern.map((item, i) => {
            if (item?._id === heading_id) {
              return {
                ...item,
                isEnabled: true,
              };
            } else {
              return item;
            }
          })
        );
      } else if (type === "predictors") {
        setPredictors((predictors) =>
          predictors.map((item, i) => {
            if (item?._id === heading_id) {
              return {
                ...item,
                isEnabled: true,
              };
            } else {
              return item;
            }
          })
        );
      }
    } else {
      if (type === "concern") {
        setConcerns((concern) =>
          concern.map((item, i) => {
            if (item?._id === heading_id) {
              return {
                ...item,
                isEnabled: false,
              };
            } else {
              return item;
            }
          })
        );
      } else if (type === "predictors") {
        setPredictors((predictors) =>
          predictors.map((item, i) => {
            if (item?._id === heading_id) {
              return {
                ...item,
                isEnabled: false,
              };
            } else {
              return item;
            }
          })
        );
      }
    }
  };

  // Limited Question Select
  useEffect(() => {
    let enabledConcerns = concerns.length > 0 && concerns.filter((it) => it?.isEnabled)?.length;
    let enabledPredictors = predictors.length > 0 && predictors.filter((it) => it?.isEnabled)?.length;
    if (enabledConcerns > 0) {
      setTotalSelectedConcerns(enabledConcerns);
      if (enabledConcerns === 8) {
        setConcerns((concern) =>
          concern.map((item, i) => {
            if (item?.isEnabled === false) {
              return {
                ...item,
                disabled: true,
              };
            } else {
              return item;
            }
          })
        );
      } else {
        setConcerns((concern) =>
          concern.map((item, i) => {
            if (item?.isEnabled === false) {
              return {
                ...item,
                disabled: false,
              };
            } else {
              return item;
            }
          })
        );
      }
    }
    if (enabledPredictors > 0) {
      setTotalSelectedPredictors(enabledPredictors);
      if (enabledPredictors === 8) {
        setPredictors((predictors) =>
          predictors.map((item, i) => {
            if (item?.isEnabled === false) {
              return {
                ...item,
                disabled: true,
              };
            } else {
              return item;
            }
          })
        );
      } else {
        setPredictors((predictors) =>
          predictors.map((item, i) => {
            if (item?.isEnabled === false) {
              return {
                ...item,
                disabled: false,
              };
            } else {
              return item;
            }
          })
        );
      }
    }
  }, [concerns, predictors]);

  const handleDragEndDrop = async (data) => {
    setLoading(true);
    try {
      const result = await postData("heading/manage-global-tire", {
        type: tabType,
        data: data,
      });

      if (result.data) {
        setQuestionData(data);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err.message);
    } finally {
      setLoading(false);
    }
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;

    // If dropped outside any droppable area
    if (!destination) return;

    // If dropped in the same group and same position
    if (source.droppableId === destination.droppableId && source.index === destination.index) return;

    // Get source and destination groups
    const sourceGroup = questionData.find((group) => group._id.toString() === source.droppableId);
    const destinationGroup = questionData.find((group) => group._id.toString() === destination.droppableId);

    // Copy the source question
    const [movedQuestion] = sourceGroup.question.splice(source.index, 1);

    // Add the question to the destination group
    destinationGroup.question.splice(destination.index, 0, movedQuestion);

    // Update state with the new data

    handleDragEndDrop([...questionData]);
  };

  const tabHeading = [
    {
      title: t("conditions_of_concern_library"),
      description: "",
      value: "concern",
    },
    {
      title: t("potential_predictor_library"),
      description: "",
      value: "predictors",
    },
  ];

  return (
    <>
      <div className="border bg-white border-gray-200 p-4 shadow-md rounded-xl">
        <div className="relative flex justify-between items-center mb-3">
          <div className="text-2xl font-bold">{t("Question Groups")}</div>
        </div>
        <div className="relative mb-3">
          <div className="flex justify-center items-center -mt-3 space-x-5 border-b border-gray-200 mb-5">
            {tabHeading?.map((type) => (
              <button
                key={type}
                type="button"
                className={`inline-block py-2 px-6 border-b-4 rounded-t-lg transition-all duration-300 ease-in-out transform font-semibold ${
                  tabType === type?.value ? "text-teal-500 border-teal-500 " : "text-slate-500 border-transparent hover:text-teal-500 hover:border-teal-500"
                }`}
                onClick={() => setTabType(type?.value)}
              >
                {type?.title}
              </button>
            ))}
          </div>
          <DragDropContext onDragEnd={onDragEnd}>
            <div className="flex flex-col">
              <div className="space-y-8">
                {questionData &&
                  Array?.isArray(questionData) &&
                  questionData?.length > 0 &&
                  questionData?.map((category) => (
                    <Droppable key={category?._id} droppableId={category?._id?.toString()}>
                      {(provided) => (
                        <div ref={provided.innerRef} {...provided.droppableProps} className={`rounded-lg bg-slate-50 border border-slate-200`}>
                          <div className="cursor-pointer py-2 px-4 bg-teal-50 flex justify-between items-center border-t rounded-t-lg border-b border-slate-200">
                            <h2 className="flex gap-2 text-lg font-bold text-slate-800">
                              {category.name}
                              <div className="text-lg font-bold text-slate-800">{`(${category?.question?.length})`}</div>
                            </h2>

                            <div
                              className={`flex items-center text-[10px] font-medium leading-tight rounded py-1 px-2 cursor-pointer ${
                                category?.setDefault === 1 ? "bg-blue-100 text-blue-600" : "bg-amber-100 text-amber-600"
                              }`}
                              // onClick={() => defaultStatusChange(value?._id)}
                            >
                              {category?.setDefault === 1 ? "Default" : "Non-default"}
                            </div>
                          </div>
                          {category?.question?.length > 0 ? (
                            <div className="list-disc py-3 px-4 space-y-2">
                              {category?.question?.map((q, index) => (
                                <Draggable key={q._id} draggableId={q._id.toString()} index={index}>
                                  {(provided) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      className="flex text-gray-800 bg-white py-3 px-4 rounded-md border border-slate-300 shadow-sm font-semibold gap-4"
                                    >
                                      <div className="aspect-[1/1] flex-shrink-0 text-slate-400">
                                        <i className="fa-regular fa-arrow-up-arrow-down"></i>
                                      </div>
                                      <div className="text-sm font-bold !leading-none flex items-center flex-shrink flex-grow">{q.name}</div>
                                      <div className="flex-shrink-0 ml-auto" data-tooltip-id={"info-tooltip-" + q?._id} data-tooltip-place="left">
                                        {q?.description && (
                                          <>
                                            <Tooltip
                                              id={"info-tooltip-" + q?._id}
                                              className="!text-xs !bg-teal-500 z-50 rounded py-2 px-2 !opacity-100"
                                              render={() => (
                                                <div className="w-60 p-1 rounded-md">
                                                  <div className="text-[13px] font-normal">
                                                    <p className="font-normal text-white">{q?.description}</p>
                                                  </div>
                                                </div>
                                              )}
                                            />
                                            <i className="fa-light fa-circle-info" style={{ color: "#14b8a6" }}></i>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  )}
                                </Draggable>
                              ))}
                              {provided.placeholder}
                            </div>
                          ) : (
                            <div className="list-disc pl-6 p-4 space-y-1">
                              <div className="flex justify-center items-center">
                                <i className="fas fa-exclamation-circle mr-2 text-slate-500 text-[25px]"></i>
                              </div>
                              <div className="flex justify-center items-center text-xl font-normal text-slate-700">No questions are available in this group at the moment.</div>
                            </div>
                          )}
                        </div>
                      )}
                    </Droppable>
                  ))}
              </div>
            </div>
          </DragDropContext>
        </div>
      </div>
    </>
  );
};

export default QuestionGroup;
