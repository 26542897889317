import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";

const BoardMemeberAlert = () => {
  const user = useSelector((state) => state.auth?.user);
  const { i18n,t } = useTranslation();
  return (
    <div className="bg-teal-100 border-l-4 border-teal-500 text-teal-700 p-2 mb-4" role="alert">
      <strong className="font-bold">{t('current_chair')+':'}</strong>&nbsp;
      <span className="block sm:inline">({t('this_should_be_the_name_of_the_person_with_chaiman_as_their_board_position')})</span>&nbsp;
      <br></br>    
      <strong className="font-bold">{t('change_window')+':'}</strong>&nbsp;
      <span className="block sm:inline">({t('this_should_be_whatever_content_is_in_the_retirement_window')})</span>&nbsp; 
    </div>
  );
};

export default BoardMemeberAlert;