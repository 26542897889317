import React from "react";
import { Page, Text, View, StyleSheet, Image, Font } from "@react-pdf/renderer";
import logo from "../../logo-succession-now.png";
import Shape from "../../shape.png";
import moment from "moment/moment";

import { useTranslation } from "react-i18next";

import RobotoLight from "../../assets/font/Roboto-Light.ttf";
import RobotoRegular from "../../assets/font/Roboto-Regular.ttf";
import RobotoMedium from "../../assets/font/Roboto-Medium.ttf";
import RobotoBold from "../../assets/font/Roboto-Bold.ttf";

Font.register({ family: "RobotoLight", src: RobotoLight });
Font.register({ family: "RobotoRegular", src: RobotoRegular });
Font.register({ family: "RobotoMedium", src: RobotoMedium });
Font.register({ family: "RobotoBold", src: RobotoBold });

const CommissionReport = ({ results,code, user }) => {
  const { i18n,t } = useTranslation();
  const styles = StyleSheet.create({
    page: {
      padding: 20,
      fontFamily: 'RobotoRegular',
      lineHeight: 1.2,
      fontSize: 9,
      color:'#334155'
    },
    tableHeading: {
      fontSize: 12,
      width: "20%",
      maxWidth: '100%',
      padding: 8,

    },
    tableData: {
      fontSize: 10,
      width: "20%",
      maxWidth: '100%',
      padding: 8,
    },

    image: {
      width: 200,
      height: 40,
      objectFit: "contain",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    tableOtherData: {
      fontSize: 10
    },
    imageSignature: {
      width: 100,
      height: 60,
      objectFit: "contain",
    },
    section: { textAlign: "center" },
  });    

  return (
    <Page size="A4" orientation="landscape" style={{ padding: 16, fontFamily: "RobotoRegular", lineHeight: 1.2, fontSize: 9, color: "#334155", backgroundColor: "#f1f5f9", }}>
      <View style={{ position: "absolute", left: 0, top: 0, right: 0, bottom: 0, zIndex: 0, }} fixed>
        <View style={{ position: "absolute", left: 0, top: 0, right: 0, width: "100%", height: 160, zIndex: -1, opacity: 0.1, }}>
          <Image style={{ objectFit: "cover" }} src={Shape} />
        </View>
        <View style={{ position: "absolute", width: "100%", left: 0, bottom: -10, right: 0, height: 160, zIndex: -1, opacity: 0.075, }}>
          <Image style={{ objectFit: "contain" }} src={logo} />
        </View>
      </View>
      <View style={{ paddingBottom: 20, width: "100%", margin: "0 auto", display: "flex", flexDirection: "column", alignItems: "center" }}>
        <View style={{ textAlign: "center", display: "flex", flexDirection: "column", justifyContent: "center", fontSize: 16, }} >
          <Image style={{ width: 200, height: 40, objectFit: "contain", display: "flex", alignItems: "center", justifyContent: "center" }} src={logo} />
        </View>
        <View style={[{ display: "flex", width: "100%", flexDirection: "row", justifyContent: "center", marginTop: 15 }]}>
          <Text style={{ fontFamily: "RobotoBold", fontSize: 12, textTransform: "uppercase", color: "#14b8a6", letterSpacing: 3, textAlign: "center", }}>{t('commission_report')}</Text>
        </View>
        <View style={[{ display: "flex", width: "100%", flexDirection: "row", marginTop: 5, textAlign: "center", justifyContent: "center" }]}>
          <View style={[{ display: "flex", flexDirection: "row", marginTop: 5, textAlign: "center", justifyContent: "center", marginRight: 12 }]}>
            <Text style={[{ fontFamily: "RobotoBold", fontSize: 10, textAlign: "center" }]}>Created by:</Text>
            <Text style={{ fontFamily: "RobotoRegular", fontSize: 10, marginLeft: 2 }}>{user?.name}</Text>
          </View>
          <View style={[{ display: "flex", flexDirection: "row", marginTop: 5 }]}>
            <Text style={[{ fontFamily: "RobotoBold", fontSize: 10 }]}>Date:</Text>
            <Text style={{ fontFamily: "RobotoRegular", fontSize: 10, marginLeft: 2 }}>{moment(new Date()).format("MM/DD/YYYY")}</Text>
          </View>
        </View>
      </View>

      
      <View style={[{ marginBottom: 20 }]}> 
      {results && results.length > 0 &&   
        results.map((item,index) => {
          let salesId = '';
          let salesPersion = '';
          let salesCommission = 0;
          let consultantId = 0;
          let salesPrice = 0;
          let consultantPersion = '';
          let consultantCommission = 0;
          let consultantPrice = 0; 
          let appointmentSetterId = '';          
          let appointmentSetterPersion = '';
          let appointmentSetterCommission = 0;
          let appointmentSetterPrice = 0;
          let salesAdminId = '';
          let salesAdminPersion = '';
          let salesAdminPrice = 0;
          let salesAdminCommission = 0;
          let sales = item?.staffs.length>0 && item?.staffs.filter((item) => item.code === 'sales-staff');
          if(sales && sales.length>0){
            salesId = sales[0].staff_id;
            let salesPersionArray = item?.staff_details.filter((itm) => itm._id === salesId);              
            if(salesPersionArray && salesPersionArray.length>0){
              let finalSalesCommission = salesPersionArray[0]?.commission?.filter(sale => sale.code === 'sales-staff')[0]?.commission;
              salesPersion = salesPersionArray[0].first_name+' '+salesPersionArray[0].last_name;
              salesCommission = (sales[0].commission)?sales[0].commission:finalSalesCommission;
              salesPrice = (Number(item?.original_price)*Number(salesCommission))/100;
            }                      
          }
          let consultant = item?.staffs.length>0 && item?.staffs.filter((item) => item.code === 'consultant');
          if(consultant && consultant.length>0){
            consultantId = consultant[0].staff_id;
            let consultantPersionArray = item?.staff_details.filter((itm) => itm._id === consultantId);
            if(consultantPersionArray && consultantPersionArray.length>0){
              let finalConsultantCommission = consultantPersionArray[0]?.commission?.filter(consult => consult.code === 'consultant')[0]?.commission;
              consultantPersion = consultantPersionArray[0].first_name+' '+consultantPersionArray[0].last_name;
              consultantCommission = (consultant[0].commission)?consultant[0].commission:finalConsultantCommission;
              consultantPrice = (Number(item?.original_price)*Number(consultantCommission))/100;
            }
          }
          let appointmentSetter = item?.staffs.length>0 && item?.staffs.filter((item) => item.code === 'appointment-setter');
          if(appointmentSetter && appointmentSetter.length>0){
            appointmentSetterId = appointmentSetter[0].staff_id;
            let appointmentSetterPersionArray = item?.staff_details.filter((itm) => itm._id === appointmentSetterId);
            if(appointmentSetterPersionArray && appointmentSetterPersionArray.length>0) {
              let finalAppointmentSetterCommission = appointmentSetterPersionArray[0]?.commission?.filter(appt => appt.code === 'appointment-setter')[0]?.commission;
              appointmentSetterPersion = appointmentSetterPersionArray[0].first_name+' '+appointmentSetterPersionArray[0].last_name;
              appointmentSetterCommission = (appointmentSetter[0].commission)?appointmentSetter[0].commission:finalAppointmentSetterCommission;
              appointmentSetterPrice = (Number(item?.original_price)*Number(appointmentSetterCommission))/100; 
            }
          }
          let salesAdmin = item?.staffs.length>0 && item?.staffs.filter((item) => item.code === 'sales-admin');
          if(salesAdmin && salesAdmin.length>0){
            salesAdminId = salesAdmin[0].staff_id;
            let salesAdminPersionArray = item?.staff_details.filter((itm) => itm._id === salesAdminId);
            if(salesAdminPersionArray && salesAdminPersionArray.length>0) {
              let finalSalesAdminCommission = salesAdminPersionArray[0]?.commission?.filter(salesAdmin => salesAdmin.code === 'sales-admin')[0]?.commission;
              salesAdminPersion = salesAdminPersionArray[0].first_name+' '+salesAdminPersionArray[0].last_name;
              salesAdminCommission = (salesAdmin[0].commission)?salesAdmin[0].commission:finalSalesAdminCommission;
              salesAdminPrice = (Number(item?.original_price)*Number(salesAdminCommission))/100; 
            }
          }
          let salesCommissionPay = item?.staff_commission.length>0 && item?.staff_commission.filter((item) => item.staff_code === 'sales-staff');
          let consultantCommissionPay = item?.staff_commission.length>0 && item?.staff_commission.filter((item) => item.staff_code === 'consultant');
          let appointmentSetterCommissionPay = item?.staff_commission.length>0 && item?.staff_commission.filter((item) => item.staff_code === 'appointment-setter');
          let salesAdminCommissionPay = item?.staff_commission.length>0 && item?.staff_commission.filter((item) => item.staff_code === 'sales-admin');  
          let primaryId='';
          let primaryName='';
          let primaryPrice = 0;
          if(code && code === 'sales-staff'){
            primaryId = salesId;
            primaryName = (salesPersion)?salesPersion:'';
            primaryPrice = (salesPersion)?salesPrice:0
          }else if(code && code === 'consultant'){
            primaryId = consultantId;
            primaryName = (consultantPersion)?consultantPersion:'';
            primaryPrice = (consultantPersion)?consultantPrice:0
          }else if(code && code === 'appointment-setter'){
            primaryId = appointmentSetterId;
            primaryName = (appointmentSetterPersion)?appointmentSetterPersion:'';
            primaryPrice = (appointmentSetterPersion)?appointmentSetterPrice:0
          }else if(code && code === 'sales-admin'){
            primaryId = salesAdminId;
            primaryName = (salesAdminPersion)?salesAdminPersion:'';
            primaryPrice = (salesAdminPersion)?salesAdminPrice:0
          }
          return (
            <View style={{ border: "1px solid #e2e8f0", borderRadius: 16, overflow: "hidden", marginBottom: 20 }} key={index} wrap={false}>
              <View style={{ backgroundColor: "#14B8A6", paddingHorizontal: 10, paddingVertical: 12, display: "flex", flexDirection: "column", gap: 4, overflow: "hidden", borderTopLeftRadius: 16, borderTopRightRadius: 16 }}>
                <View style={[{ display: "flex", flexDirection: "column", gap:4}]}>
                  <View style={[{ display: "flex", flexDirection: "row", alignItems: 'center'}]}>
                    <Text style={[{ fontFamily: "RobotoBold", fontSize: 14, color: "#ffffff" }]}>{item?.company_name}</Text>
                  </View>
                  <View style={[{ display: "flex", flexDirection: "row", alignItems: 'center', justifyContent: 'space-between', gap: 20 }]}>
                    <View style={[{ display: "flex", flexDirection: "row", alignItems: 'center', gap: 20 }]}>
                      <View style={{ display: "flex", flexDirection: "row", alignItems: 'center', gap: 8 }}>
                        <Text style={[{ fontFamily: "RobotoMedium", textTransform:'uppercase', fontSize: 9, color: "#ffffff" }]}>{t('price')}:</Text>
                        <Text style={[{ fontFamily: "RobotoMedium", textTransform:'uppercase', fontSize: 9, color: "#ffffff" }]}>{'$' + Number(item?.original_price).toFixed(2)}</Text>
                      </View>
                      <View style={{ display: "flex", flexDirection: "row", alignItems: 'center', gap: 8 }}>
                        <Text style={[{ fontFamily: "RobotoMedium", textTransform:'uppercase', fontSize: 9, color: "#ffffff" }]}>{t('total_commission')}:</Text>
                        <Text style={[{ fontFamily: "RobotoMedium", textTransform:'uppercase', fontSize: 9, color: "#ffffff" }]}>{'$' + Number(((salesPrice) ? salesPrice : 0) + ((consultantPrice) ? consultantPrice : 0) + ((appointmentSetterPrice) ? appointmentSetterPrice : 0) + ((salesAdminPrice) ? salesAdminPrice : 0)).toFixed(2)}</Text>
                      </View>
                    </View>
                    <View style={{ display: "flex", flexDirection: "row", alignItems: 'center', gap: 8 }}>
                      <Text style={[{ fontFamily: "RobotoMedium", textTransform:'uppercase', fontSize: 9, color: "#ffffff" }]}>{t('start_date')}:</Text>
                      <Text style={[{ fontFamily: "RobotoMedium", textTransform:'uppercase', fontSize: 9, color: "#ffffff" }]}>{(item?.created_at) ? moment(new Date(item?.created_at)).format("MMM Do YYYY") : 'N/A'}</Text>
                    </View>
                  </View>
                </View>
              </View> 
              <View style={{ overflow: "hidden" }}>
                <View style={{ display: "flex", flexDirection: "row", backgroundColor: "#0f172a", color: "#ffffff", overflow: "hidden",}}>
                  <View style={{ display: "flex", flexDirection: "row", alignItems: 'center', flexWrap: "wrap", width: "100%", maxWidth: "20%", paddingHorizontal: 12, paddingVertical: 8, justifyContent: 'flex-start' }}>
                    <Text style={[{ fontFamily: "RobotoBold", fontSize: 10, color: "#ffffff", textAlign: 'left' }]}>{t('role')}</Text>
                  </View>
                  <View style={{ display: "flex", flexDirection: "row", alignItems: 'center', flexWrap: "wrap", width: "100%", maxWidth: "35%", paddingHorizontal: 12, paddingVertical: 8, justifyContent: 'flex-start' }}>
                    <Text style={[{ fontFamily: "RobotoBold", fontSize: 10, color: "#ffffff", textAlign: 'left' }]}>{t('name')}</Text>
                  </View>
                  <View style={{ display: "flex", flexDirection: "row", alignItems: 'center', flexWrap: "wrap", width: "100%", maxWidth: "15%", paddingHorizontal: 12, paddingVertical: 8, justifyContent: 'center' }}>
                    <Text style={[{ fontFamily: "RobotoBold", fontSize: 10, color: "#ffffff", textAlign: 'center' }]}>{t('percentage')}</Text>
                  </View>
                  <View style={{ display: "flex", flexDirection: "row", alignItems: 'center', flexWrap: "wrap", width: "100%", maxWidth: "15%", paddingHorizontal: 12, paddingVertical: 8, justifyContent: 'center' }}>
                    <Text style={[{ fontFamily: "RobotoBold", fontSize: 10, color: "#ffffff", textAlign: 'center' }]}>{t('amount')}</Text>
                  </View>
                  <View style={{ display: "flex", flexDirection: "row", alignItems: 'center', flexWrap: "wrap", width: "100%", maxWidth: "15%", paddingHorizontal: 12, paddingVertical: 8, justifyContent: 'flex-end' }}>
                    <Text style={[{ fontFamily: "RobotoBold", fontSize: 10, color: "#ffffff", textAlign: 'right' }]}>{t('status')}</Text>
                  </View>
                </View>
                <View style={{ width: "100%", borderBottomLeftRadius: 16, borderBottomRightRadius: 16, backgroundColor: "white", }}>
                  <View style={{ display: "flex", flexDirection: "row", width:"100%", borderTop: "1px solid #e2e8f0" }}>
                    <View style={{ display: "flex", flexDirection: "row", alignItems: 'center', justifyContent: 'flex-start', flexWrap: "wrap", fontSize: 10, width: "100%", maxWidth: "20%", paddingHorizontal: 12, paddingVertical: 8 }} >
                      <Text style={{ fontSize: 9 }}>{t('salesperson')}</Text>                            
                    </View>
                    <View style={{ display: "flex", flexDirection: "row", alignItems: 'center', justifyContent: 'flex-start', flexWrap: "wrap", fontSize: 10, width: "100%", maxWidth: "35%", paddingHorizontal: 12, paddingVertical: 8 }} >
                      <Text style={{ fontSize: 9 }}>{(salesPersion)?salesPersion:t('none')}</Text>                            
                    </View>
                    <View style={{ display: "flex", flexDirection: "row", alignItems: 'center', justifyContent: 'center', flexWrap: "wrap", fontSize: 10, width: "100%", maxWidth: "15%", paddingHorizontal: 12, paddingVertical: 8 }} >
                      <Text style={{ fontSize: 9 }}>{((salesCommission)?salesCommission:0)+'%'}</Text>                            
                    </View>
                    <View style={{ display: "flex", flexDirection: "row", alignItems: 'center', justifyContent: 'center', flexWrap: "wrap", fontSize: 10, width: "100%", maxWidth: "15%", paddingHorizontal: 12, paddingVertical: 8 }} >
                      <Text style={{ fontSize: 9 }}>{'$'+((salesPrice)?salesPrice:0).toFixed(2)}</Text>                            
                    </View>
                    <View style={{ display: "flex", flexDirection: "row", alignItems: 'center', justifyContent: 'flex-end', flexWrap: "wrap", fontSize: 10, width: "100%", maxWidth: "15%", paddingHorizontal: 12, paddingVertical: 8 }} >
                      {salesCommissionPay && salesCommissionPay.length>0 ? (
                        <Text style={{ fontSize: 9, color:"#22c55e" }}>{(salesCommissionPay && salesCommissionPay.length>0)?t('paid'):t('unpaid')}</Text> 
                      ):(
                        <Text style={{ fontSize: 9, color:'#ef4444' }}>{(salesCommissionPay && salesCommissionPay.length>0)?t('paid'):t('unpaid')}</Text>
                      )}                       
                    </View>
                  </View>
                  <View style={{ display: "flex", flexDirection: "row", width:"100%", borderTop: "1px solid #e2e8f0" }}>
                    <View style={{ display: "flex", flexDirection: "row", alignItems: 'center', justifyContent: 'flex-start', flexWrap: "wrap", fontSize: 10, width: "100%", maxWidth: "20%", paddingHorizontal: 12, paddingVertical: 8 }} >
                      <Text style={{ fontSize: 9 }}>{t('consultant')}</Text>                            
                    </View>
                    <View style={{ display: "flex", flexDirection: "row", alignItems: 'center', justifyContent: 'flex-start', flexWrap: "wrap", fontSize: 10, width: "100%", maxWidth: "35%", paddingHorizontal: 12, paddingVertical: 8 }} >
                      <Text style={{ fontSize: 9 }}>{(consultantPersion)?consultantPersion:t('none')}</Text>                            
                    </View>
                    <View style={{ display: "flex", flexDirection: "row", alignItems: 'center', justifyContent: 'center', flexWrap: "wrap", fontSize: 10, width: "100%", maxWidth: "15%", paddingHorizontal: 12, paddingVertical: 8 }} >
                      <Text style={{ fontSize: 9 }}>{((consultantCommission)?consultantCommission:0)+'%'}</Text>                            
                    </View>
                    <View style={{ display: "flex", flexDirection: "row", alignItems: 'center', justifyContent: 'center', flexWrap: "wrap", fontSize: 10, width: "100%", maxWidth: "15%", paddingHorizontal: 12, paddingVertical: 8 }} >
                      <Text style={{ fontSize: 9 }}>{'$'+((consultantPrice)?consultantPrice:0).toFixed(2)}</Text>                            
                    </View>
                    <View style={{ display: "flex", flexDirection: "row", alignItems: 'center', justifyContent: 'flex-end', flexWrap: "wrap", fontSize: 10, width: "100%", maxWidth: "15%", paddingHorizontal: 12, paddingVertical: 8 }} >
                      {consultantCommissionPay && consultantCommissionPay.length>0 ?(
                        <Text style={{ fontSize: 9, color:"#22c55e" }}>{(consultantCommissionPay && consultantCommissionPay.length>0)?t('paid'):t('unpaid')}</Text> 
                      ):(
                        <Text style={{ fontSize: 9, color:'#ef4444' }}>{(consultantCommissionPay && consultantCommissionPay.length>0)?t('paid'):t('unpaid')}</Text>
                      )}                       
                    </View>
                  </View>
                  <View style={{ display: "flex", flexDirection: "row", width:"100%", borderTop: "1px solid #e2e8f0" }}>
                    <View style={{ display: "flex", flexDirection: "row", alignItems: 'center', justifyContent: 'flex-start', flexWrap: "wrap", fontSize: 10, width: "100%", maxWidth: "20%", paddingHorizontal: 12, paddingVertical: 8 }} >
                      <Text style={{ fontSize: 9 }}>{t('appt_setter')}</Text>                            
                    </View>
                    <View style={{ display: "flex", flexDirection: "row", alignItems: 'center', justifyContent: 'flex-start', flexWrap: "wrap", fontSize: 10, width: "100%", maxWidth: "35%", paddingHorizontal: 12, paddingVertical: 8 }} >
                      <Text style={{ fontSize: 9 }}>{(appointmentSetterPersion)?appointmentSetterPersion:t('none')}</Text>                            
                    </View>
                    <View style={{ display: "flex", flexDirection: "row", alignItems: 'center', justifyContent: 'center', flexWrap: "wrap", fontSize: 10, width: "100%", maxWidth: "15%", paddingHorizontal: 12, paddingVertical: 8 }} >
                      <Text style={{ fontSize: 9 }}>{((appointmentSetterCommission)?appointmentSetterCommission:0)+'%'}</Text>                            
                    </View>
                    <View style={{ display: "flex", flexDirection: "row", alignItems: 'center', justifyContent: 'center', flexWrap: "wrap", fontSize: 10, width: "100%", maxWidth: "15%", paddingHorizontal: 12, paddingVertical: 8 }} >
                      <Text style={{ fontSize: 9 }}>{'$'+((appointmentSetterPrice)?appointmentSetterPrice:0).toFixed(2)}</Text>                            
                    </View>
                    <View style={{ display: "flex", flexDirection: "row", alignItems: 'center', justifyContent: 'flex-end', flexWrap: "wrap", fontSize: 10, width: "100%", maxWidth: "15%", paddingHorizontal: 12, paddingVertical: 8 }} >
                      {appointmentSetterCommissionPay && appointmentSetterCommissionPay.length>0 ?(
                        <Text style={{ fontSize: 9, color:"#22c55e" }}>{(appointmentSetterCommissionPay && appointmentSetterCommissionPay.length>0)?t('paid'):t('unpaid')}</Text> 
                      ):(
                        <Text style={{ fontSize: 9, color:'#ef4444' }}>{(appointmentSetterCommissionPay && appointmentSetterCommissionPay.length>0)?t('paid'):t('unpaid')}</Text>
                      )}                        
                    </View>
                  </View>
                  <View style={{ display: "flex", flexDirection: "row", width:"100%", borderTop: "1px solid #e2e8f0" }}>
                    <View style={{ display: "flex", flexDirection: "row", alignItems: 'center', justifyContent: 'flex-start', flexWrap: "wrap", fontSize: 10, width: "100%", maxWidth: "20%", paddingHorizontal: 12, paddingVertical: 8 }}>
                      <Text style={{ fontSize: 9 }}>{t('sales_admin')}</Text>                            
                    </View>
                    <View style={{ display: "flex", flexDirection: "row", alignItems: 'center', justifyContent: 'flex-start', flexWrap: "wrap", fontSize: 10, width: "100%", maxWidth: "35%", paddingHorizontal: 12, paddingVertical: 8 }}>
                      <Text style={{ fontSize: 9 }}>{(salesAdminPersion)?salesAdminPersion:t('none')}</Text>                            
                    </View>
                    <View style={{ display: "flex", flexDirection: "row", alignItems: 'center', justifyContent: 'center', flexWrap: "wrap", fontSize: 10, width: "100%", maxWidth: "15%", paddingHorizontal: 12, paddingVertical: 8 }}>
                      <Text style={{ fontSize: 9 }}>{((salesAdminCommission)?salesAdminCommission:0)+'%'}</Text>                            
                    </View>
                    <View style={{ display: "flex", flexDirection: "row", alignItems: 'center', justifyContent: 'center', flexWrap: "wrap", fontSize: 10, width: "100%", maxWidth: "15%", paddingHorizontal: 12, paddingVertical: 8 }}>
                      <Text style={{ fontSize: 9 }}>{'$'+((salesAdminPrice)?salesAdminPrice:0).toFixed(2)}</Text>                            
                    </View>
                    <View style={{ display: "flex", flexDirection: "row", alignItems: 'center', justifyContent: 'flex-end', flexWrap: "wrap", fontSize: 10, width: "100%", maxWidth: "15%", paddingHorizontal: 12, paddingVertical: 8 }}>
                      {salesAdminCommissionPay && salesAdminCommissionPay.length>0?(
                        <Text style={{ fontSize: 9, color:"#22c55e" }}>{(salesAdminCommissionPay && salesAdminCommissionPay.length>0)?t('paid'):t('unpaid')}</Text> 
                      ):(
                        <Text style={{ fontSize: 9, color:'#ef4444' }}>{(salesAdminCommissionPay && salesAdminCommissionPay.length>0)?t('paid'):t('unpaid')}</Text>
                      )}
                    </View>
                  </View>
                </View>              
              </View>
            </View>
          )
      })}
      </View>
    </Page>
  )
};
export default CommissionReport;