// SkeletonLoader.js
import React from "react";

const DashboardCardSkeleton = ({ bgColor = "bg-rose-200" }) => {
  return (
    <div className="relative col-span-12 sm:col-span-6 lg:col-span-4 2xl:col-span-3 animate-pulse">
      <div
        className={`absolute -top-5 left-5 w-12 h-12 rounded-full  z-[1] flex items-center justify-center ${bgColor}`}
      ></div>

      <div className="relative bg-white shadow-md rounded-xl pt-10 pb-6 px-5 h-full border-t border-slate-300">
        <div className="flex items-center justify-between gap-2 mb-4">
          <div className="w-1/2 h-4 bg-slate-300 rounded"></div>
          <div className="flex items-center justify-between gap-2 ml-auto">
            <div className={`w-6 h-6 rounded ${bgColor}`}></div>
            <div className={`w-6 h-6 rounded ${bgColor}`}></div>
          </div>
        </div>
        <div className="space-y-6">
          {[...Array(6)].map((_, index) => (
            <div key={index} className="flex justify-between items-center">
              <div className="w-3/5 h-3 bg-slate-200 rounded"></div>
              <div className="w-1/4 h-3 bg-slate-200 rounded"></div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DashboardCardSkeleton;
