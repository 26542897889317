import React, { useState, useEffect, useCallback } from "react";
import { setTitle } from "../../helpers/MetaTag";
import Button from "../../components/form/Button";
import Select from "../../components/form/Select";
import Search from "../../components/form/Search";
import Table from "../../components/elements/table/Table";
import moment from "moment";
import ReactPaginate from "react-paginate";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { reorder } from "../../helpers";
import toastr from "toastr";

import { postData } from "../../services/api";
import { useTranslation } from "react-i18next";
import Pagination from "../../components/Common/Pagination";
import { getStatusFilter } from "../../constant/Constant";
import { useDebounce } from "../../helpers/useDebounce";

const SuccessionPlan = ({}) => {
  setTitle(
    "Succession Planning Software for your Business with Succession Now | Succession Plan"
  );
  const { i18n, t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const status_filter = getStatusFilter(t);
  const [keyword, setKeyword] = useState("");
  const search = useDebounce(keyword, 500);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [sortKey, setSortKey] = useState("created_at");
  const [sortValue, setSortValue] = useState(-1);
  const [sortQuery, setSortQuery] = useState("");
  const [status, setStatus] = useState("");
  const [noOfPage, setNoOfPage] = useState(0);
  const [planData, setPlanData] = useState([]);
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [startDataCount, setStartDataCount] = useState(0);
  const [endDataCount, setEndDataCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    if (sortKey && sortValue) {
      setSortQuery({ [sortKey]: sortValue });
    } else {
      setSortQuery("");
    }
  }, [sortKey, sortValue]);

  const loadList = useCallback(async () => {
    setLoading(true);
    try {
      const result = await postData("successionplan/list", {
        keyword: search,
        limit: limit,
        offset: offset,
        sortQuery: sortQuery,
        status: status,
      });
      if (result.data) {
        setPlanData(
          result.data.map((value, key) => {
            return {
              _id: value?._id,
              rowData: [
                {
                  _id: 1,
                  width: 40,
                  type: "text",
                  data: value?.name?.[i18n?.language],
                },
                {
                  _id: 2,
                  width: 15,
                  type: "text",
                  data: value?.description?.[i18n?.language],
                },
                {
                  _id: 3,
                  width: 20,
                  type: "text",
                  data:
                    value && value?.created_at
                      ? moment(new Date(value?.created_at)).format(
                          "Do MMM YYYY"
                        )
                      : "N/A",
                },
                {
                  _id: 4,
                  width: 15,
                  type: "status",
                  statusLabel:
                    value?.status && value?.status === 1
                      ? t("active")
                      : t("deactivated"),
                  statusType:
                    value?.status && value?.status === 1
                      ? "success"
                      : "warning",
                },
                { _id: 5, width: 10, type: "action" },
              ],
            };
          })
        );
      }
      setTotalDataCount(result.count);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err.message);
    } finally {
      setLoading(false);
    }
  }, [search, limit, offset, sortQuery, status, i18n?.language]);

  useEffect(() => {
    loadList();
  }, [loadList]);

  useEffect(() => {
    setNoOfPage(Math.ceil(totalDataCount / limit));
    const startItem = Number(totalDataCount > 0 ? 1 : 0);
    const endItem = Math.min(startItem + limit - 1, totalDataCount);
    setStartDataCount(startItem);
    setEndDataCount(endItem);
  }, [limit, totalDataCount]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * limit) % totalDataCount;
    setCurrentPage(event.selected);
    setOffset(newOffset);
    const startItem = Number(newOffset + 1);
    const endItem = Math.min(startItem + limit - 1, totalDataCount);
    setStartDataCount(startItem);
    setEndDataCount(endItem);
  };

  const tableHeadData = [
    {
      _id: 1,
      width: 40,
      name: t("name"),
      value: "name",
      align: "left",
      isSort: false,
      isFilter: false,
    },
    {
      _id: 2,
      width: 15,
      name: t("description"),
      value: "description",
      align: "left",
      isSort: true,
      isFilter: false,
    },
    {
      _id: 3,
      width: 20,
      name: t("created_date"),
      value: "created_at",
      align: "left",
      isSort: false,
      isFilter: false,
    },
    {
      _id: 4,
      width: 15,
      name: t("status"),
      value: "status",
      align: "left",
      isSort: false,
      isFilter: true,
      filterData: status_filter || [],
      onFilter: (data) => {
        if (data && Array.isArray(data) && data.length === 1) {
          setStatus(data[0]?.value);
        } else {
          setStatus("");
        }
        setOffset(0);
      },
    },
    {
      _id: 5,
      width: 10,
      name: t("action"),
      value: "",
      align: "right",
      isSort: false,
      isFilter: false,
    },
  ];

  const handleOnDragEnd = async (result) => {
    const items = reorder(
      planData,
      result.source.index,
      result.destination.index
    );
    setPlanData(items);
    const res = await postData("position/ordering", {
      items: items,
    });
    if (res.status && res.status === 200) {
      toastr.success(res.message);
    } else {
      toastr.error(res.message);
    }
  };

  return (
    <div className="border bg-white border-gray-200 p-4 shadow-md rounded-xl">
      <div className="relative flex justify-between items-center mb-4 flex-wrap sm:flex-nowrap gap-y-4">
        <div className="text-2xl font-bold">{t("successionplan")}</div>
        <div className="sm:ml-auto flex justify-end items-center">
          <Button
            buttonType={"button"}
            buttonIcon={"fa-light fa-plus"}
            buttonIconPosition={"left"}
            buttonLabel={t("add")}
            buttonHasLink={true}
            buttonLink={"/add-successionplan"}
          />
        </div>
      </div>
      <div className="relative flex gap-3 items-center mb-3 flex-wrap">
        <div className="relative">
          <Select
            xPlacement={"left"}
            dropdownButtonClass={"!bg-white"}
            selectedValue={limit}
            dropdownData={[
              { _id: 1000000, label: t("all_items"), value: 1000000 },
              ...[
                { _id: 10, label: "10 " + t("items"), value: 10 },
                { _id: 20, label: "20 " + t("items"), value: 20 },
                { _id: 30, label: "30 " + t("items"), value: 30 },
                { _id: 50, label: "50 " + t("items"), value: 50 },
                { _id: 100, label: "100 " + t("items"), value: 100 },
              ],
            ]}
            getSelectedValue={(e) => {
              setLimit(e.value);
              setOffset(0);
              setCurrentPage(0);
            }}
          />
        </div>
        <div className="relative w-48">
          <Search
            placeholder={t("search")}
            setInput={setKeyword}
            valueReset={() => {
              setOffset(0);
              setCurrentPage(0);
            }}
          />
        </div>
      </div>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <div className="w-full bg-white rounded-md shadow overflow-hidden">
          <Table
            tableData={planData}
            tableHeadData={tableHeadData}
            totalRecord={totalDataCount}
            loading={loading}
            getSortValue={setSortValue}
            getSortKey={setSortKey}
            editUrl={"/edit-successionplan"}
            isDraggable={false}
            tableImage={false}
          />
        </div>
      </DragDropContext>
      {noOfPage > 1 ? (
        <Pagination
          handlePageClick={handlePageClick}
          noOfPage={noOfPage}
          startDataCount={startDataCount}
          endDataCount={endDataCount}
          count={totalDataCount}
          forcePage={currentPage}
        />
      ) : (
        <div className="flex justify-center sm:justify-between my-4 text-sm text-black text-center sm:text-left">
          {totalDataCount > 0 ? (
            <div>
              <p>
                {t("showing")}: {startDataCount} to {endDataCount} of{" "}
                {totalDataCount}
              </p>
            </div>
          ) : (
            ""
          )}
        </div>
      )}
    </div>
  );
};
export default SuccessionPlan;
