import React, { useState, useEffect, useCallback } from "react";
import { setTitle } from "../../helpers/MetaTag";
import Select from "../../components/form/Select";
import Search from "../../components/form/Search";
import Table from "../../components/elements/table/Table";
import Button from "../../components/form/Button";
import moment from "moment";
import ReactPaginate from "react-paginate";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { reorder } from "../../helpers";
import toastr from "toastr";

import { postData } from "../../services/api";
import { useTranslation } from "react-i18next";
import Pagination from "../../components/Common/Pagination";
import { useDebounce } from "../../helpers/useDebounce";
import { Tooltip } from "react-tooltip";
import AdminAddEditPopup from "../../components/popup/AdminAddEditPopup";
import { useSelector } from "react-redux";

const Admins = ({}) => {
  setTitle("Succession Planning Software for your Business with Succession Now | Administrators");
  const user = useSelector((state) => state.auth?.user);
  const { i18n, t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [keyword, setKeyword] = useState("");
  const search = useDebounce(keyword, 500);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [sortKey, setSortKey] = useState("created_at");
  const [sortValue, setSortValue] = useState(-1);
  const [sortQuery, setSortQuery] = useState("");
  const [emailVerified, setEmailVerified] = useState("");
  const [noOfPage, setNoOfPage] = useState(0);
  const [admins, setAdmins] = useState([]);
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [startDataCount, setStartDataCount] = useState(0);
  const [endDataCount, setEndDataCount] = useState(0);
  const [actionValue, setActionValue] = useState({});

  const extraData = [
    {
      _id: 1,
      label: t("edit"),
      icon: "fa-pen-to-square",
      link: "/",
      type: "button",
      standout: false,
      buttonType: "edit-user-info",
      ordering: 1,
    },
    {
      _id: 2,
      label: t("password_generate_mail"),
      icon: "fa-envelope",
      link: "/",
      type: "button",
      standout: false,
      buttonType: "generate-password",
      isVisabled: 1,
      ordering: 5,
    },
  ];

  const [isOpen, setIsopen] = useState({
    _id: null,
    open: false,
  });

  useEffect(() => {
    if (sortKey && sortValue) {
      setSortQuery({ [sortKey]: sortValue });
    } else {
      setSortQuery("");
    }
  }, [sortKey, sortValue]);

  const imageExists = (url, callback) => {
    const img = new Image();
    img.onload = () => callback(true);
    img.onerror = () => callback(false);
    img.src = url;
  };

  const getInitials = function (string) {
    var names = string.split(" "),
      initials = names[0].substring(0, 1).toUpperCase();
    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  };

  const loadList = useCallback(async () => {
    setLoading(true);
    try {
      const result = await postData("usertype/list", {
        keyword: search,
        limit,
        offset: search ? 0 : offset,
        sortQuery,
        email_verified: emailVerified,
        code: ["master-admin", "basic-administrator"],
      });
      if (result.data) {
        setAdmins(
          result.data.map((value, key) => {
            let salesCommission = 0;
            let consultantCommission = 0;
            let appointmentSetterCommission = 0;
            let salesAdminCommission = 0;
            if (value?.commission && Object.keys(value?.commission).length > 0) {
              let sales = value?.commission.filter((itm) => itm.code === "sales-staff");
              if (sales && sales.length > 0) {
                salesCommission = sales[0].commission ? sales[0].commission : 0;
              }
              let consultant = value?.commission.filter((item) => item.code === "consultant");
              if (consultant && consultant.length > 0) {
                consultantCommission = consultant[0].commission ? consultant[0].commission : 0;
              }
              let appointmentSetter = value?.commission.filter((item) => item.code === "appointment-setter");
              if (appointmentSetter && appointmentSetter.length > 0) {
                appointmentSetterCommission = appointmentSetter[0].commission ? appointmentSetter[0].commission : 0;
              }
              let salesAdmin = value?.commission.filter((item) => item.code === "sales-admin");
              if (salesAdmin && salesAdmin.length > 0) {
                salesAdminCommission = salesAdmin[0].commission ? salesAdmin[0].commission : 0;
              }
            }
            //let td = "Sales staff :" + salesCommission + "% , Consultant :" + consultantCommission + "% , Appointment Setter :" + appointmentSetterCommission + "% , Sales Admin :" + salesAdminCommission + "%";
            let fullCommission = (
              <div className="flex items-center flex-wrap gap-x-1 gap-y-1" data-tooltip-id={`commission${value?._id}`} data-tooltip-place="right">
                <div className="text-[10px] font-normal leading-none mb-1 flex items-center space-x-1 bg-amber-100/80 text-amber-600 py-1 px-2 rounded">
                  <div className="font-semibold">Sales staff:</div>
                  <div>{salesCommission + "%"}</div>
                </div>
                <div className="text-[10px] font-normal leading-none mb-1 flex items-center space-x-1 bg-violet-100/80 text-violet-500 py-1 px-2 rounded">
                  <div className="font-semibold">Consultant:</div>
                  <div>{consultantCommission + "%"}</div>
                </div>
                <div className="text-[10px] font-normal leading-none mb-1 flex items-center space-x-1 bg-pink-100/80 text-pink-500 py-1 px-2 rounded">
                  <div className="font-semibold">Appointment Setter:</div>
                  <div>{appointmentSetterCommission + "%"}</div>
                </div>
                <div className="text-[10px] font-normal leading-none mb-1 flex items-center space-x-1 bg-cyan-100/80 text-cyan-600 py-1 px-2 rounded">
                  <div className="font-semibold">Sales Admin:</div>
                  <div>{salesAdminCommission + "%"}</div>
                </div>
                <Tooltip
                  id={`commission${value?._id}`}
                  className="!text-xs !bg-dark-teal-600 z-50 rounded py-3 px-6"
                  render={() => (
                    <div className="gap-x-1 gap-y-1">
                      <div className="text-[13px] font-normal leading-none mb-1 flex items-center space-x-1  py-1 px-2 rounded">
                        <div className="font-semibold">Sales staff:</div>
                        <div>{salesCommission + "%"}</div>
                      </div>
                      <div className="text-[13px] font-normal leading-none mb-1 flex items-center space-x-1 py-1 px-2 rounded">
                        <div className="font-semibold">Consultant:</div>
                        <div>{consultantCommission + "%"}</div>
                      </div>
                      <div className="text-[13px] font-normal leading-none mb-1 flex items-center space-x-1  py-1 px-2 rounded">
                        <div className="font-semibold">Appointment Setter:</div>
                        <div>{appointmentSetterCommission + "%"}</div>
                      </div>
                      <div className="text-[13px] font-normal leading-none mb-1 flex items-center space-x-1  py-1 px-2 rounded">
                        <div className="font-semibold">Sales Admin:</div>
                        <div>{salesAdminCommission + "%"}</div>
                      </div>
                    </div>
                  )}
                />
              </div>
            );
            return {
              _id: value?._id,
              rowData: [
                // { _id: 1, width: 40, type: "textImage", image: value?.image,data:getInitials(value.first_name + " " + value.last_name) },
                {
                  _id: 1,
                  width: 40,
                  type: "user",
                  image: value?.image,
                  shortName: value?.name || "N / A",
                  isTooltip: true,
                  toolTipData: (
                    <div className="flex flex-col items-start justify-center text-white gap-1">
                      {value?.name && (
                        <h4 className="font-semibold">
                          <span className="text-slate-200">Name: </span>
                          {value?.name}
                        </h4>
                      )}
                      {value?.email && (
                        <h4 className="font-semibold">
                          <span className="text-slate-200">Email: </span>
                          {value?.email}
                        </h4>
                      )}
                      {value?.phone && (
                        <h4 className="font-semibold">
                          <span className="text-slate-200">Phone: </span>
                          {value?.phone}
                        </h4>
                      )}
                      {value?.email_verified && (
                        <h4 className="flex font-medium space-x-2">
                          <span className="text-slate-200">Email Verified : </span>
                          <div className="flex items-center">
                            <div className={`text-[10px] font-medium leading-tight rounded py-1 px-2  ${value?.email_verified === 1 ? "bg-green-100 text-green-600" : "bg-red-100 text-red-600"}`}>
                              {value?.email_verified === 1 ? t("verified") : t("not_verified")}
                            </div>
                          </div>
                        </h4>
                      )}
                    </div>
                  ),
                  data: {
                    _id: value?._id,
                    name: value?.name || "N/A",
                  },
                },
                {
                  _id: 2,
                  width: 15,
                  type: "text",
                  data: value?.email || "N/A",
                },
                {
                  _id: 3,
                  width: 15,
                  type: "text",
                  data: value?.phone ? (value?.phone === "null" ? "N/A" : value?.phone) : "N/A",
                },
                {
                  _id: 4,
                  width: 15,
                  type: "text",
                  data: (
                    <>
                      <div className="flex relative text-slate-700">
                        {value?.role_details &&
                          Array.isArray(value?.role_details) &&
                          value?.role_details?.length > 0 &&
                          value?.role_details?.map((item, index) => (
                            <span key={index}>
                              {item?.name}
                              {index < value?.role_details.length - 1 && ", "}
                            </span>
                          ))}
                      </div>
                    </>
                  ),
                },
                { _id: 5, width: 15, type: "text", data: fullCommission },
                {
                  _id: 6,
                  width: 20,
                  type: "text",
                  data: value && value?.created_at ? moment(new Date(value?.created_at)).format("ll") : "N/A",
                },
                // {
                //   _id: 6,
                //   width: 15,
                //   type: "status",
                //   statusLabel:
                //     value?.email_verified && value?.email_verified === 1
                //       ? t("verified")
                //       : t("not_verified"),
                //   statusType:
                //     value?.email_verified && value?.email_verified === 1
                //       ? "success"
                //       : "warning",
                // },

                {
                  _id: 7,
                  width: 10,
                  type: "action",
                  data: [{ "generate-password": 1 }],
                },
              ],
            };
          })
        );
      }
      setTotalDataCount(result.count);
      setLoading(false);
    } catch (err) {
      console.error(err.message);
    } finally {
      setLoading(false);
    }
  }, [search, limit, offset, sortQuery, emailVerified]);

  useEffect(() => {
    loadList();
  }, [loadList]);

  useEffect(() => {
    setNoOfPage(Math.ceil(totalDataCount / limit));
    const startItem = Number(totalDataCount > 0 ? 1 : 0);
    const endItem = Math.min(startItem + limit - 1, totalDataCount);
    setStartDataCount(startItem);
    setEndDataCount(endItem);
    if (search) {
      setOffset(0);
    }
  }, [limit, totalDataCount, search]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * limit) % totalDataCount;
    console.log(`User requested page number ${event.selected}, which is offset ${newOffset}`);
    setOffset(newOffset);
    const startItem = Number(newOffset + 1);
    const endItem = Math.min(startItem + limit - 1, totalDataCount);
    setStartDataCount(startItem);
    setEndDataCount(endItem);
  };

  const tableHeadData = [
    {
      _id: 1,
      width: 40,
      name: t("user"),
      value: "name",
      align: "left",
      isSort: false,
      isFilter: false,
    },
    {
      _id: 2,
      width: 15,
      name: t("email"),
      value: "email",
      align: "left",
      isSort: true,
      isFilter: false,
    },
    {
      _id: 3,
      width: 15,
      name: t("phone no"),
      value: "phone",
      align: "left",
      isSort: true,
      isFilter: false,
    },
    {
      _id: 4,
      width: 15,
      name: t("Role"),
      value: "role",
      align: "left",
      isSort: false,
      isFilter: false,
    },
    {
      _id: 5,
      width: 15,
      name: t("commission"),
      value: "commission",
      align: "left",
      isSort: true,
      isFilter: false,
    },
    {
      _id: 6,
      width: 20,
      name: t("created"),
      value: "created_at",
      align: "left",
      isSort: false,
      isFilter: false,
    },
    {
      _id: 7,
      width: 10,
      name: t("action"),
      value: "",
      align: "right",
      isSort: false,
      isFilter: false,
    },
  ];

  const handleOnDragEnd = async (result) => {
    const items = reorder(admins, result.source.index, result.destination.index);
    setAdmins(items);
    const res = await postData("position/ordering", {
      items: items,
    });
    if (res.status && res.status === 200) {
      toastr.success(res.message);
    } else {
      toastr.error(res.message);
    }
  };

  useEffect(() => {
    if (actionValue && actionValue.button_type === "generate-password") {
      const loadResendInvitation = async () => {
        try {
          const othersData = await postData("usertype/generate-password", {
            id: actionValue?.row_id,
          });
          if (othersData.data) {
            setActionValue({});
            toastr.success("Email resent.Please make sure to check your spam email.");
          } else {
            toastr.error(othersData.message);
          }
        } catch (error) {
          toastr.error(error.message);
        }
      };
      loadResendInvitation();
    } else if (actionValue?.row_id && actionValue?.button_type === "delete") {
      if (user?._id === actionValue?.row_id) {
        toastr.warning("Your account cannot be deleted by yourself.");
      } else {
        const deleteAdmin = async () => {
          const statusData = await postData("admin/delete", {
            id: actionValue?.row_id,
          });
          if (statusData.status === 200) {
            setAdmins((admins) => admins.filter((item) => item._id !== actionValue.row_id));
            toastr.success(statusData.message);
          } else {
            toastr.error(statusData.message);
          }
        };
        deleteAdmin();
      }
    } else if (actionValue && actionValue.button_type === "edit-user-info") {
      setIsopen({ open: true, _id: actionValue?.row_id });
    }
  }, [actionValue]);

  return (
    <>
      <div className="border bg-white border-gray-200 p-4 shadow-md rounded-xl">
        <div className="relative flex justify-between items-center mb-3">
          <div className="text-2xl font-bold">{t("administrators")}</div>
          <div className="sm:ml-auto flex justify-end items-center" data-tooltip-id={`button${1}`} data-tooltip-place="right">
            <Button
              buttonType={"button"}
              buttonIcon={"fa-light fa-plus"}
              buttonIconPosition={"left"}
              buttonLabel={t("add")}
              buttonHasLink={false}
              functions={() => setIsopen({ _id: null, open: true })}
            />
            <Tooltip
              id={`button${1}`}
              className="!text-xs !bg-teal-500 z-50 rounded py-3 px-6"
              render={() => (
                <div className="gap-x-1 gap-y-1">
                  <div className="text-[13px] font-normal leading-none flex items-center">
                    <div className="font-semibold">Add New Administrator</div>
                  </div>
                </div>
              )}
            />
          </div>
        </div>
        <div className="relative flex gap-3 items-center mb-4 flex-wrap sm:flex-nowrap gap-y-2">
          <div className="relative">
            <Select
              xPlacement={"left"}
              dropdownButtonClass={"!bg-white"}
              selectedValue={limit}
              dropdownData={[
                { _id: 1000000, label: t("all_items"), value: 1000000 },
                ...[
                  { _id: 10, label: "10 " + t("items"), value: 10 },
                  { _id: 20, label: "20 " + t("items"), value: 20 },
                  { _id: 30, label: "30 " + t("items"), value: 30 },
                  { _id: 50, label: "50 " + t("items"), value: 50 },
                  { _id: 100, label: "100 " + t("items"), value: 100 },
                ],
              ]}
              getSelectedValue={(e) => setLimit(e.value)}
            />
          </div>
          <div className="relative w-48">
            <Search placeholder={t("search")} setInput={setKeyword} />
          </div>
          <div className="relative w-48">
            <Select
              xPlacement={"right"}
              dropdownButtonClass={"!bg-white"}
              dropdownClass={"w-48"}
              transitionClass={"!w-auto"}
              selectedValue={emailVerified}
              dropdownData={[
                { _id: "", label: t("select") },
                { _id: 1, label: t("verified"), value: 1 },
                { _id: 2, label: t("not_verified"), value: 2 },
              ]}
              getSelectedValue={(e) => setEmailVerified(e.value)}
            />
          </div>
        </div>
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <div className="w-full bg-white rounded-md shadow">
            <Table
              tableData={admins}
              tableHeadData={tableHeadData}
              totalRecord={totalDataCount}
              loading={loading}
              getSortValue={setSortValue}
              getSortKey={setSortKey}
              // editUrl={"/edit-admin"}
              getActionValue={(obj) => setActionValue(obj)}
              isDraggable={false}
              isDeleteEnabled={true}
              extraData={extraData}
              // loginAs={"/login-as"}
              isColor={"flex items-center gap-2"}
            />
          </div>
        </DragDropContext>
        {noOfPage > 1 ? (
          <Pagination handlePageClick={handlePageClick} noOfPage={noOfPage} startDataCount={startDataCount} endDataCount={endDataCount} count={totalDataCount} />
        ) : (
          <div className="flex justify-center sm:justify-between my-4 text-sm text-black text-center sm:text-left">
            {totalDataCount > 0 ? (
              <div>
                <p>
                  {t("showing")}: {startDataCount} to {endDataCount} of {totalDataCount}
                </p>
              </div>
            ) : (
              ""
            )}
          </div>
        )}
      </div>
      {isOpen?.open && <AdminAddEditPopup isOpen={isOpen?.open} id={isOpen?._id} setIsOpen={setIsopen} loadList={loadList} />}
    </>
  );
};
export default Admins;
