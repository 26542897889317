import React from "react";
import { Page, Text, View, Image, Font } from "@react-pdf/renderer";
import logo from "../../logo-succession-now.png";
import Shape from "../../shape.png";
import moment from "moment/moment";

import RobotoLight from "../../assets/font/Roboto-Light.ttf";
import RobotoRegular from "../../assets/font/Roboto-Regular.ttf";
import RobotoItalic from "../../assets/font/Roboto-Italic.ttf";
import RobotoMedium from "../../assets/font/Roboto-Medium.ttf";
import RobotoBold from "../../assets/font/Roboto-Bold.ttf";

Font.register({ family: "RobotoLight", src: RobotoLight });
Font.register({ family: "RobotoRegular", src: RobotoRegular });
Font.register({ family: "RobotoItalic", src: RobotoItalic });
Font.register({ family: "RobotoMedium", src: RobotoMedium });
Font.register({ family: "RobotoBold", src: RobotoBold });

const ConclusionIndividualReport = ({ successors, heading, user, users, companyLogo }) => {

  return (
    <Page
      size="A4"
      orientation="landscape"
      style={{
        padding: 20,
        fontFamily: "RobotoRegular",
        lineHeight: 1.2,
        fontSize: 9,
        color: "#334155",
        backgroundColor: "#f1f5f9",
        position: "relative",
        zIndex: 0,
      }}
    >
      <View
        style={{
          position: "absolute",
          left: 0,
          top: 0,
          right: 0,
          bottom: 0,
          zIndex: 0,
        }}
        fixed
      >
        <View
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            right: 0,
            width: "100%",
            height: 160,
            zIndex: -1,
            opacity: 0.1,
          }}
        >
          <Image style={{ objectFit: "cover" }} src={Shape} />
        </View>
        <View
          style={{
            position: "absolute",
            width: "100%",
            left: 0,
            bottom: -10,
            right: 0,
            height: 160,
            zIndex: -1,
            opacity: 0.075,
          }}
        >
          <Image style={{ objectFit: "contain" }} src={logo} />
        </View>
      </View>

      <View style={{ position: "absolute", right: 30, top: 30 }}>
        <Image style={{ width: 120, height: 24, objectFit: "contain" }} src={logo} />
      </View>

      <View style={{ paddingBottom: 20, width: "100%", margin: "0 auto", display: "flex", flexDirection: "column", alignItems: "center" }}>
        <View
          style={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            fontSize: 16,
            alignItems: "center",
            position: "relative",
            width: "100%",
          }}
        >
          <Image
            style={{
              width: 200,
              height: 40,
              objectFit: "contain",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            src={companyLogo}
          />
        </View>
        <View style={[{ display: "flex", width: "100%", flexDirection: "row", justifyContent: "center", marginTop: 15 }]}>
          <Text style={[{ fontFamily: "RobotoBold", fontSize: 20, textTransform: "capitalize", textAlign: "center" }]}>Company Name: {user?.company_name}</Text>
        </View>
        <View style={[{ display: "flex", width: "100%", flexDirection: "row", justifyContent: "center", marginTop: 15 }]}>
          <Text
            style={{
              fontFamily: "RobotoBold",
              fontSize: 12,
              textTransform: "uppercase",
              color: "#14b8a6",
              letterSpacing: 3,
              textAlign: "center",
            }}
          >
            Succession Plan for {user?.title}
          </Text>
        </View>
        <View style={[{ display: "flex", width: "100%", flexDirection: "row", marginTop: 5, textAlign: "center", justifyContent: "center" }]}>
          <View style={[{ display: "flex", flexDirection: "row", marginTop: 5, textAlign: "center", justifyContent: "center", marginRight: 12 }]}>
            <Text style={[{ fontFamily: "RobotoBold", fontSize: 10, textAlign: "center" }]}>Created by:</Text>
            <Text style={{ fontFamily: "RobotoRegular", fontSize: 10, marginLeft: 2 }}>
              {user?.name}({user?.title})
            </Text>
          </View>
          <View style={[{ display: "flex", flexDirection: "row", marginTop: 5 }]}>
            <Text style={[{ fontFamily: "RobotoBold", fontSize: 10 }]}>Date:</Text>
            <Text style={{ fontFamily: "RobotoRegular", fontSize: 10, marginLeft: 2 }}>{moment(new Date()).format("MM/DD/YYYY")}</Text>
          </View>
        </View>
      </View>
      {successors &&
        successors.length > 0 &&
        successors.map((row, key) => {
          return (
            <View style={{ width: "100%" }} wrap={false} key={key}>
              {row.conclusion && row.conclusion.length > 0 && (
                <>
                  <View style={[{ display: "flex", flexDirection: "row", columnGap: 20, marginTop: 5, marginBottom: 5 }]}>
                    <Text style={[{ fontFamily: "RobotoBold", fontSize: 10 }]}>
                      {row.name} <Text style={[{ fontFamily: "RobotoRegular", fontSize: 10 }]}>{" (" + row.position + ")"}</Text>
                    </Text>
                    <Text style={[{ fontFamily: "RobotoBold", fontSize: 10 }]}>
                      Retirement Window : {row?.retirement ? row?.retirement?.replace(/(Eliminates this candidate)/gi, "").replace(/[{()}]/g, "") : ""}
                    </Text>
                  </View>
                  <View style={{ border: "1px solid #e2e8f0", borderRadius: 16, overflow: "hidden", marginBottom: 20 }}>
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        backgroundColor: "#0f172a",
                        color: "#ffffff",
                        overflow: "hidden",
                        borderTopLeftRadius: 16,
                        borderTopRightRadius: 16,
                      }}
                    >
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                          fontFamily: "RobotoBold",
                          fontSize: 12,
                          color: "#ffffff",
                          width: "20%",
                          maxWidth: "100%",
                          paddingHorizontal: 6,
                          paddingVertical: 6,
                        }}
                      >
                        <Text style={[{ width: "100%", fontFamily: "RobotoBold", fontSize: 10, marginTop: 6 }]}>Choices</Text>
                      </View>

                      <View
                        style={[
                          {
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                            fontFamily: "RobotoBold",
                            fontSize: 12,
                            color: "#ffffff",
                            width: "20%",
                            maxWidth: "100%",
                            paddingHorizontal: 6,
                            paddingVertical: 6,
                          },
                        ]}
                      >
                        <Text style={{ width: "100%", fontFamily: "RobotoBold", fontSize: 10 }}>Time Horizon </Text>
                        <Text style={{ fontSize: 8, marginTop: 2 }}>(If 0-1 Year)</Text>
                      </View>
                      <View
                        style={[
                          {
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                            fontFamily: "RobotoBold",
                            fontSize: 12,
                            color: "#ffffff",
                            width: "20%",
                            maxWidth: "100%",
                            paddingHorizontal: 6,
                            paddingVertical: 6,
                          },
                        ]}
                      >
                        <Text style={{ width: "100%", fontFamily: "RobotoBold", fontSize: 10 }}>Time Horizon </Text>
                        <Text style={{ fontSize: 8, marginTop: 2 }}>(If 1-3 Year)</Text>
                      </View>
                      <View
                        style={[
                          {
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                            fontFamily: "RobotoBold",
                            fontSize: 12,
                            color: "#ffffff",
                            width: "20%",
                            maxWidth: "100%",
                            paddingHorizontal: 6,
                            paddingVertical: 6,
                          },
                        ]}
                      >
                        <Text style={{ width: "100%", fontFamily: "RobotoBold", fontSize: 10 }}>Time Horizon </Text>
                        <Text style={{ fontSize: 8, marginTop: 2 }}>(If 3-5 Year)</Text>
                      </View>
                      <View
                        style={[
                          {
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                            fontFamily: "RobotoBold",
                            fontSize: 12,
                            color: "#ffffff",
                            width: "20%",
                            maxWidth: "100%",
                            paddingHorizontal: 6,
                            paddingVertical: 6,
                            backgroundColor: "#ef4444",
                          },
                        ]}
                      >
                        <Text style={{ width: "100%", fontFamily: "RobotoBold", fontSize: 10, marginTop: 6 }}>Emergency or Interim</Text>
                      </View>
                    </View>
                    <View
                      style={{
                        width: "100%",
                        borderBottomLeftRadius: 16,
                        borderBottomRightRadius: 16,
                        backgroundColor: "white",
                      }}
                    >
                      {row.conclusion &&
                        row.conclusion.length > 0 &&
                        row.conclusion.map((item, index) => {
                          let zero_to_one = users.filter((itm) => itm?._id === item?.time_horizon_zero_to_one);
                          let one_to_three = users.filter((itm) => itm?._id === item?.time_horizon_one_to_three);
                          let three_to_five = users.filter((itm) => itm?._id === item?.time_horizon_three_to_five);

                          let time_horizon_zero_to_one_record = row?.successors_details.length > 0 && row?.successors_details?.filter((rv) => rv._id === item?.time_horizon_zero_to_one);
                          let time_horizon_zero_to_one_name = "";
                          let time_horizon_zero_to_one_score = 0;
                          if (time_horizon_zero_to_one_record.length > 0) {
                            time_horizon_zero_to_one_name = row?.successors_details.length > 0 && row?.successors_details?.find((rv) => rv._id === item?.time_horizon_zero_to_one)?.name;
                            time_horizon_zero_to_one_score = row?.successors_details.length > 0 && row?.successors_details?.find((rv) => rv._id === item?.time_horizon_zero_to_one)?.overallWeightScore;
                          }

                          let time_horizon_one_to_three_record = row?.successors_details.length > 0 && row?.successors_details?.filter((rv) => rv._id === item?.time_horizon_one_to_three);
                          let time_horizon_one_to_three_name = "";
                          let time_horizon_one_to_three_score = "";
                          if (time_horizon_one_to_three_record.length > 0) {
                            time_horizon_one_to_three_name = row?.successors_details.length > 0 && row?.successors_details?.find((rv) => rv._id === item?.time_horizon_one_to_three)?.name;
                            time_horizon_one_to_three_score =
                              row?.successors_details.length > 0 && row?.successors_details?.find((rv) => rv._id === item?.time_horizon_one_to_three)?.overallWeightScore;
                          }

                          let time_horizon_three_to_five_record = row?.successors_details.length > 0 && row?.successors_details?.filter((rv) => rv._id === item?.time_horizon_three_to_five);
                          let time_horizon_three_to_five_name = "";
                          let time_horizon_three_to_five_score = "";
                          if (time_horizon_three_to_five_record.length > 0) {
                            time_horizon_three_to_five_name = row?.successors_details.length > 0 && row?.successors_details?.find((rv) => rv._id === item?.time_horizon_three_to_five)?.name;
                            time_horizon_three_to_five_score =
                              row?.successors_details.length > 0 && row?.successors_details?.find((rv) => rv._id === item?.time_horizon_three_to_five)?.overallWeightScore;
                          }

                          let replacement_record = row?.successors_details.length > 0 && row?.successors_details?.filter((rv) => rv._id === item?.replacement);
                          let replacement_name = "";
                          let replacement_score = "";
                          if (replacement_record.length > 0) {
                            replacement_name = row?.successors_details.length > 0 && row?.successors_details?.find((rv) => rv._id === item?.replacement)?.name;
                            replacement_score = row?.successors_details.length > 0 && row?.successors_details?.find((rv) => rv._id === item?.replacement)?.overallWeightScore;
                          }

                          return (
                            <View key={index} style={{ display: "flex", flexDirection: "row", borderTop: "1px solid #e2e8f0" }}>
                              <View
                                style={[
                                  {
                                    display: "flex",
                                    flexDirection: "row",
                                    flexWrap: "wrap",
                                    fontSize: 10,
                                    width: "20%",
                                    maxWidth: "100%",
                                    paddingHorizontal: 6,
                                    paddingVertical: 12,
                                  },
                                ]}
                              >
                                <Text style={[{ fontFamily: "RobotoMedium", width: "100%", fontSize: 9, lineHeight: 1 }]}>{item.name}</Text>
                              </View>
                              <View style={[{ display: "flex", flexDirection: "row", flexWrap: "wrap", width: "20%", maxWidth: "100%", paddingHorizontal: 6, paddingVertical: 12 }]}>
                                <Text style={{ fontSize: 9 }}>
                                  {item.isComment
                                    ? item?.comment_zero_to_one
                                    : time_horizon_zero_to_one_record.length > 0
                                    ? time_horizon_zero_to_one_name + " (" + (time_horizon_zero_to_one_score > 0 ? time_horizon_zero_to_one_score : 0) + ")"
                                    : (zero_to_one && zero_to_one.length > 0) || item?.time_horizon_zero_to_one === "external-hire"
                                    ? item?.time_horizon_zero_to_one_text
                                    : ""}
                                </Text>
                              </View>
                              <View style={[{ display: "flex", flexDirection: "row", flexWrap: "wrap", width: "20%", maxWidth: "100%", paddingHorizontal: 6, paddingVertical: 12 }]}>
                                <Text style={{ fontSize: 9 }}>
                                  {item.isComment
                                    ? item?.comment_one_to_three
                                    : time_horizon_one_to_three_record.length > 0
                                    ? time_horizon_one_to_three_name + " (" + (time_horizon_one_to_three_score > 0 ? time_horizon_one_to_three_score : 0) + ")"
                                    : (one_to_three && one_to_three.length > 0) || item?.time_horizon_one_to_three === "external-hire"
                                    ? item?.time_horizon_one_to_three_text
                                    : ""}
                                </Text>
                              </View>
                              <View style={[{ display: "flex", flexDirection: "row", flexWrap: "wrap", width: "20%", maxWidth: "100%", paddingHorizontal: 6, paddingVertical: 12 }]}>
                                <Text style={{ fontSize: 9 }}>
                                  {item.isComment
                                    ? item?.comment_three_to_five
                                    : time_horizon_three_to_five_record.length > 0
                                    ? time_horizon_three_to_five_name + " (" + (time_horizon_three_to_five_score > 0 ? time_horizon_three_to_five_score : 0) + ")"
                                    : (three_to_five && three_to_five.length > 0) || item?.time_horizon_three_to_five === "external-hire"
                                    ? item?.time_horizon_three_to_five_text
                                    : ""}
                                </Text>
                              </View>
                              <View style={[{ display: "flex", flexDirection: "row", flexWrap: "wrap", width: "20%", maxWidth: "100%", paddingHorizontal: 6, paddingVertical: 12 }]}>
                                <Text style={{ fontSize: 9 }}>
                                  {item.isComment
                                    ? item?.comment_replacement
                                    : replacement_record.length > 0
                                    ? replacement_name + " (" + (replacement_score > 0 ? replacement_score : 0) + ")"
                                    : item?.replacement_text}
                                </Text>
                              </View>
                            </View>
                          );
                        })}
                    </View>
                  </View>
                </>
              )}
            </View>
          );
        })}
    </Page>
  );
};

export default ConclusionIndividualReport;
