import React, { useCallback, useEffect, useRef, useState } from "react";
import ApexCharts from "react-apexcharts";
import { postData } from "../../../services/api";
import html2canvas from "html2canvas";
import {
  Document,
  Page,
  Text,
  Image,
  PDFDownloadLink,
  View,
  Font,
  pdf,
} from "@react-pdf/renderer";
import Select from "../../form/Select";
import { useSelector } from "react-redux";

import logo from "../../../logo-succession-now.png";
import Shape from "../../../shape.png";

import RobotoLight from "../../../assets/font/Roboto-Light.ttf";
import RobotoRegular from "../../../assets/font/Roboto-Regular.ttf";
import RobotoMedium from "../../../assets/font/Roboto-Medium.ttf";
import RobotoBold from "../../../assets/font/Roboto-Bold.ttf";

Font.register({ family: "RobotoLight", src: RobotoLight });
Font.register({ family: "RobotoRegular", src: RobotoRegular });
Font.register({ family: "RobotoMedium", src: RobotoMedium });
Font.register({ family: "RobotoBold", src: RobotoBold });

const AvgOfQuestionTier = () => {
  const [image, setImage] = useState(null);
  const user = useSelector((state) => state.auth?.user);
  const chartRef = useRef(null);
  const chartPredictorRef = useRef(null);
  const [avgQuestionData, setAvgQuestionData] = useState([]);
  const [avgQuestionPredictors, setAvgQuestionPredictors] = useState([]);
  const [tierList, setTierList] = useState([]);
  const [selectedForConcern, setSelectedForConcern] = useState("");
  const [selectedForPredictor, setSelectedForPredictor] = useState("");

  const [loading, setLoading] = useState(false);

  const loadList = useCallback(async () => {
    setLoading(true);
    try {
      const result = await postData("avarage-score", {
        type: "concern",
        tierId: selectedForConcern?._id,
      });

      if (result.data) {
        setAvgQuestionData(result?.data);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err.message);
    } finally {
      setLoading(false);
    }
  }, [selectedForConcern]);

  useEffect(() => {
    loadList();
  }, [loadList]);

  const loadPredictorsList = useCallback(async () => {
    setLoading(true);
    try {
      const result = await postData("avarage-score", {
        type: "predictors",
        tierId: selectedForPredictor?._id,
      });

      if (result.data) {
        setAvgQuestionPredictors(result?.data);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err.message);
    } finally {
      setLoading(false);
    }
  }, [selectedForPredictor]);

  useEffect(() => {
    loadPredictorsList();
  }, [loadPredictorsList]);

  //Tier List api call
  const loadTierList = useCallback(async () => {
    try {
      const result = await postData("filter-item/list", {
        type: "position-group",
        status: 1,
      });

      if (result.status === 200) {
        setTierList(result?.data);
      }
    } catch (err) {
      console.log(err.message);
    }
  }, []);

  useEffect(() => {
    loadTierList();
  }, [loadTierList]);

  const getBarColor = (value) => {
    if (value <= 3) {
      return "#a90f05";
    } else if (value > 3 && value <= 3.74) {
      return "#c19f13";
    } else {
      return "#07a213";
    }
  };

  const getCustomLegendLabels = [
    { color: "#a90f05", label: "Red: Avg Rating < 3" },
    { color: "#FFD700", label: "Yellow: Avg Rating 3.00 to 3.74" },
    { color: "#07a213", label: "Green: Avg Rating > 3.74" },
  ];

  const chartData = {
    series: [
      {
        name: "Rating",
        data: avgQuestionData?.map((item) => item.number),
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 400,
        toolbar: {
          show: false,
          tools: {
            downloadSVG: false,
            downloadPNG: true,
            downloadCSV: false,
          },
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: "60%",
          distributed: true,
          borderRadius: 8,
          borderRadiusApplication: "end",
        },
      },
      xaxis: {
        categories: avgQuestionData?.map((item) => item.question),
        labels: {
          show: true,
          style: {
            fontSize: "12px",
            colors: "#000",
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            fontSize: "14px",
            colors: "#3A8078",
          },
          maxWidth: 400,
          tickAmount: 6, // To show the specific number of ticks
          min: 0, // Minimum value for yaxis
          max: 5, // Maximum value for yaxis
          tickPositions: [0, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5],
        },
      },
      tooltip: {
        enabled: true,
        theme: "light", // Options: 'light', 'dark'
        style: {
          fontSize: "14px",
          fontFamily: "Arial, sans-serif",
        },
        x: {
          show: true,
          formatter: (value) => `<b>${value}</b>`,
        },
        y: {
          formatter: (val) =>
            `<span style="color:#3A8078; font-weight:bold;">${val}</span>`,
        },
      },
      colors: avgQuestionData?.map((item) => getBarColor(item.number)),
      // title: {
      //   text:
      //     user?.isEnabledTires === 1
      //       ? `Conditions of Concern - Average Score for ${
      //           selectedForConcern?._id ? selectedForConcern?.label : "All Tier"
      //         }`
      //       : "Average Score for Conditions of Concern",
      //   align: "left",
      //   style: {
      //     fontSize: "16px",
      //     fontWeight: "bold",
      //   },
      // },
      grid: {
        padding: {
          left: 20,
          right: 20,
        },
        borderColor: "#e0e0e0",
        strokeDashArray: 4,
      },
      legend: {
        show: false,
        // position: "bottom",
        // horizontalAlign: "center",
        // verticalAlign: "top",
        // floating: true,
        fontSize: "12px",
        labels: {
          colors: "#000",
        },
        // markers: {
        //   width: 12,
        //   height: 12,
        // },
      },
      noData: {
        text: "No Data Available",
        align: "center",
        verticalAlign: "middle",
        style: {
          color: "#999",
          fontSize: "20px",
          fontWeight: "bold",
        },
      },
    },
  };

  const chartPredictorData = {
    series: [
      {
        name: "Rating",
        data: avgQuestionPredictors?.map((item) => item.number),
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 400,
        toolbar: {
          show: false,
          tools: {
            downloadSVG: false,
            downloadPNG: true,
            downloadCSV: false,
          },
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: "60%",
          distributed: true,
          borderRadius: 8,
          borderRadiusApplication: "end",
        },
      },
      xaxis: {
        categories: avgQuestionPredictors?.map((item) => item.question),
        labels: {
          show: true,
          style: {
            fontSize: "12px",
            colors: "#000",
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            fontSize: "14px",
            colors: "#3A8078",
          },
          maxWidth: 500,
          tickAmount: 6, // To show the specific number of ticks
          min: 0, // Minimum value for yaxis
          max: 5, // Maximum value for yaxis
          tickPositions: [0, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5],
        },
      },
      tooltip: {
        enabled: true,
        theme: "light", // Options: 'light', 'dark'
        style: {
          fontSize: "14px",
          fontFamily: "Arial, sans-serif",
        },
        x: {
          show: true,
          formatter: (value) => `<b>${value}</b>`,
        },
        y: {
          formatter: (val) =>
            `<span style="color:#3A8078; font-weight:bold;">${val}</span>`,
        },
      },
      colors: avgQuestionPredictors?.map((item) => getBarColor(item.number)),
      // title: {
      //   text:
      //     user?.isEnabledTires === 1
      //       ? `Potential Predictors - Average Score for ${
      //           selectedForPredictor?._id
      //             ? selectedForPredictor?.label
      //             : "All Tier"
      //         }`
      //       : "Average Score for Potential Predictors",
      //   align: "left",
      //   style: {
      //     fontSize: "16px",
      //     fontWeight: "bold",
      //   },
      // },
      grid: {
        padding: {
          left: 20,
          right: 20,
        },
        borderColor: "#e0e0e0",
        strokeDashArray: 4,
      },
      legend: {
        show: false,
        // position: "bottom",
        // horizontalAlign: "center",
        // verticalAlign: "top",
        // floating: true,
        fontSize: "12px",
        labels: {
          colors: "#000",
        },
        // markers: {
        //   width: 12,
        //   height: 12,
        // },
      },
      noData: {
        text: "No Data Available",
        align: "center",
        verticalAlign: "middle",
        style: {
          color: "#999",
          fontSize: "20px",
          fontWeight: "bold",
        },
      },
    },
  };

  const captureCharts = async (e, imgData, title) => {
    e.preventDefault();

  
    const newTab = window.open("", "_blank");


    newTab.document.write(`
      <html lang="en">
        <head>
          <script src="https://cdn.tailwindcss.com"></script>
          <style>
            body {
              @apply bg-gray-100;
              margin: 0;
              height: 100vh;
            }
            .loader-container {
              @apply flex flex-col justify-center items-center h-screen;
            }
            .loader {
              width: 50px;
              aspect-ratio: 1;
              display: grid;
              border-radius: 50%;
              background:
                linear-gradient(0deg, rgb(0 0 0 / 50%) 30%, #0000 0 70%, rgb(0 0 0 / 100%) 0) 50% / 8% 100%,
                linear-gradient(90deg, rgb(0 0 0 / 25%) 30%, #0000 0 70%, rgb(0 0 0 / 75%) 0) 50% / 100% 8%;
              background-repeat: no-repeat;
              animation: l23 1s infinite steps(12);
            }
            .loader::before,
            .loader::after {
              content: "";
              grid-area: 1 / 1;
              border-radius: 50%;
              background: inherit;
              opacity: 0.915;
              transform: rotate(30deg);
            }
            .loader::after {
              opacity: 0.83;
              transform: rotate(60deg);
            }
            @keyframes l23 {
              100% {
                transform: rotate(1turn);
              }
            }
          </style>
        </head>
        <body>
          <div class="flex justify-center items-center mt-20 loader-container">
          <div class="flex flex-col items-center justify-center space-y-4">
            <div class="loader"></div>
            <p class="text-teal-700 text-lg font-semibold mt-4">Generating your PDF... Please wait.</p>
          </div>
         </div>
        </body>
      </html>
      `);
    try {
      const blob = await pdf(
        <PDFDocument
          title={title}
          image={imgData}
          legendLabels={getCustomLegendLabels}
        />
      ).toBlob();

      const blobURL = URL.createObjectURL(blob);
      newTab.location.href = blobURL;
    } catch (error) {
      newTab.document.body.innerHTML = `
        <div class="flex flex-col items-center justify-center h-screen bg-red-100">
          <p class="text-red-700 text-lg font-semibold">Failed to load PDF. Please try again later.</p>
          <p class="text-sm text-gray-600">${error.message}</p>
        </div>
      `;
      console.error("PDF generation error:", error);
    }
  };

  const captureChart = (e) => {
    setImage(null);
    if (chartRef.current) {
      html2canvas(chartRef.current).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        setImage(imgData);
        const title =
          user?.isEnabledTires === 1
            ? `Conditions of Concern - Average Score for ${
                selectedForConcern?._id ? selectedForConcern?.label : "All Tier"
              }`
            : "Average Score for Conditions of Concern";
        captureCharts(e, imgData, title);
      });
    }
  };

  const capturePredictorChart = (e) => {
    setImage(null);
    if (chartPredictorRef.current) {
      html2canvas(chartPredictorRef.current).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        setImage(imgData);

        const title =
          user?.isEnabledTires === 1
            ? `Potential Predictors - Average Score for ${
                selectedForPredictor?._id
                  ? selectedForPredictor?.label
                  : "All Tier"
              }`
            : "Average Score for Potential Predictors";
        captureCharts(e, imgData, title);
      });
    }
  };

  const PDFDocument = ({ title, legendLabels, image }) => (
    <Document>
      <Page
        size="A4"
        orientation="portrait"
        style={{
          padding: 16,
          fontFamily: "RobotoRegular",
          lineHeight: 1.2,
          fontSize: 9,
          color: "#334155",
          backgroundColor: "#f1f5f9",
        }}
      >
        <View
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            zIndex: 0,
          }}
          fixed
        >
          <View
            style={{
              position: "absolute",
              left: 0,
              top: 0,
              right: 0,
              width: "100%",
              height: 160,
              zIndex: -1,
              opacity: 0.1,
            }}
          >
            <Image style={{ objectFit: "cover" }} src={Shape} />
          </View>
          <View
            style={{
              position: "absolute",
              width: "100%",
              left: 0,
              bottom: -10,
              right: 0,
              height: 160,
              zIndex: -1,
              opacity: 0.075,
            }}
          >
            <Image style={{ objectFit: "contain" }} src={logo} />
          </View>
        </View>
        <View
          style={{
            paddingBottom: 20,
            width: "100%",
            margin: "0 auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <View
            style={{
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Image
              style={{
                width: 200,
                height: 40,
                objectFit: "contain",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              src={logo}
            />
          </View>
          <View
            style={[
              {
                display: "flex",
                width: "100%",
                flexDirection: "row",
                justifyContent: "center",
                marginTop: 15,
              },
            ]}
          >
            <Text
              style={{
                fontFamily: "RobotoBold",
                fontSize: 12,
                textTransform: "uppercase",
                color: "#14b8a6",
                letterSpacing: 3,
                textAlign: "center",
              }}
            >
              {title}
            </Text>
          </View>
        </View>
        <View style={[{ marginBottom: 20 }]}>
          <View
            style={{
              border: "1px solid #e2e8f0",
              borderRadius: 16,
              overflow: "hidden",
              marginBottom: 20,
              backgroundColor: "#fff",
              paddingHorizontal: 20,
              paddingVertical: 20,
            }}
            wrap={false}
          >
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                flexWrap: "wrap",
                justifyContent: "center",
                columnGap: 24,
                rowGap: 8,
                marginTop: 16,
              }}
            >
              {legendLabels?.map((item, index) => (
                <View
                  key={index}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    columnGap: 8,
                  }}
                >
                  <View
                    style={{
                      width: 12,
                      height: 12,
                      borderRadius: 4,
                      backgroundColor: item?.color,
                    }}
                  ></View>
                  <View style={{ flexShrink: 1, flexGrow: 1 }}>
                    <Text style={{ fontSize: 10, fontFamily: "RobotoMedium" }}>
                      {item?.label}
                    </Text>
                  </View>
                </View>
              ))}
            </View>
            <View
              style={{
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              {image && (
                <Image
                  src={image}
                  style={{
                    width: "100%",
                    objectFit: "contain",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                />
              )}
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );

  return (
    <>
      <div className="grid grid-cols-2 gap-5" id="chart">
        <div className="w-full px-4 py-4 shadow-md bg-white relative rounded-lg space-y-8">
          <div className="flex items-center gap-4">
            {user?.isEnabledTires === 1 && (
              <div className="relative w-48">
                <Select
                  xPlacement={"left"}
                  dropdownButtonClass={"!bg-white"}
                  dropdownClass={"w-auto"}
                  selectedValue={selectedForConcern}
                  dropdownData={[
                    { _id: "", label: "Select Tier", value: "" },
                    ...tierList?.map((item) => ({
                      _id: item?._id,
                      label: item?.name,
                      value: item?._id,
                    })),
                  ]}
                  getSelectedValue={(e) => {
                    setSelectedForConcern(e);
                  }}
                />
              </div>
            )}

            <div className="ml-auto">
              <button
                type={"button"}
                className={
                  "flex justify-center items-center gap-2 bg-teal-500 text-white rounded text-lg h-10 py-0 px-3 transition-all duration-200 cursor-pointer "
                }
                onClick={(e) => captureChart(e)}
              >
                <i className={"fa-fw fa-light fa-download"}></i>
                <div className={"text-sm font-medium whitespace-nowrap"}>
                  Download Report
                </div>
              </button>
            </div>
          </div>
          <div className="relative">
            <h2 className="text-lg text-center text-slate-800 font-bold">
              {user?.isEnabledTires === 1
                ? `Conditions of Concern - Average Score for ${
                    selectedForConcern?._id
                      ? selectedForConcern?.label
                      : "All Tier"
                  }`
                : "Average Score for Conditions of Concern"}
            </h2>
            <div className="flex items-center flex-wrap justify-center gap-x-6 gap-y-2 mt-4">
              {getCustomLegendLabels?.map((item, index) => (
                <div key={index} className="flex items-center gap-2">
                  <div
                    className="w-4 h-4 rounded-sm"
                    style={{ backgroundColor: item?.color }}
                  ></div>
                  <div className="text-sm font-semibold text-slate-700">
                    {item?.label}
                  </div>
                </div>
              ))}
            </div>
            <div ref={chartRef}>
              <ApexCharts
                options={chartData.options}
                series={chartData.series}
                type="bar"
                height={400}
              />
            </div>
          </div>
        </div>
        <div className="w-full px-4 py-4 shadow-md bg-white relative rounded-lg space-y-8">
          <div className="flex items-center gap-4">
            {user?.isEnabledTires === 1 && (
              <div className="relative w-48">
                <Select
                  xPlacement={"left"}
                  dropdownButtonClass={"!bg-white"}
                  dropdownClass={"w-auto"}
                  selectedValue={selectedForPredictor}
                  dropdownData={[
                    { _id: "", label: "Select Tier", value: "" },
                    ...tierList?.map((item) => ({
                      _id: item?._id,
                      label: item?.name,
                      value: item?._id,
                    })),
                  ]}
                  getSelectedValue={(e) => {
                    setSelectedForPredictor(e);
                  }}
                />
              </div>
            )}
            <div className="ml-auto">
              <button
                type={"button"}
                className={
                  "flex justify-center items-center gap-2 bg-teal-500 text-white rounded text-lg h-10 py-0 px-3 transition-all duration-200 cursor-pointer "
                }
                onClick={(e) => capturePredictorChart(e)}
              >
                <i className={"fa-fw fa-light fa-download"}></i>
                <div className={"text-sm font-medium whitespace-nowrap"}>
                  Download Report
                </div>
              </button>
            </div>
          </div>
          <div className="relative">
            <h2 className="text-lg text-center text-slate-800 font-bold">
              {user?.isEnabledTires === 1
                ? `Potential Predictors - Average Score for ${
                    selectedForPredictor?._id
                      ? selectedForPredictor?.label
                      : "All Tier"
                  }`
                : "Average Score for Potential Predictors"}
            </h2>
            <div className="flex items-center flex-wrap justify-center gap-x-6 gap-y-2 mt-4">
              {getCustomLegendLabels?.map((item, index) => (
                <div key={index} className="flex items-center gap-2">
                  <div
                    className="w-4 h-4 rounded-sm"
                    style={{ backgroundColor: item?.color }}
                  ></div>
                  <div className="text-sm font-semibold text-slate-700">
                    {item?.label}
                  </div>
                </div>
              ))}
            </div>
            <div ref={chartPredictorRef}>
              <ApexCharts
                options={chartPredictorData.options}
                series={chartPredictorData.series}
                type="bar"
                height={400}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AvgOfQuestionTier;
