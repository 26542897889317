import React, { useState, useEffect } from "react";
import { setTitle } from "../helpers/MetaTag";
import Input from "../components/form/Input";
import Select from "../components/form/Select";
import Button from "../components/form/Button";
import toastr from "toastr";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { postData } from "../services/api";
import { useSelector } from "react-redux";
import OtherStudyPopup from "../components/popup/OtherStudyPopup";
import OtherIndustryPopup from "../components/popup/OtherIndustryPopup";
//import { PDFDownloadLink } from "@react-pdf/renderer";
//import BoardMemberReport from "./pdf/BoardMemberReport";

import { useTranslation } from "react-i18next";
import NoDataFound from "../components/NoDataFound";
import { Document, PDFDownloadLink } from "@react-pdf/renderer";
import PerspectiveReport from "./pdf/PerspectiveReport";
import { API_URL } from "../config/host";
import Textarea from "../components/form/Textarea";
import { comment } from "postcss";
//import { set } from "immutable";

const PerspectiveBoardMember = () => {
  const { id } = useParams();
  if (id) {
    setTitle("Succession Now | Edit Boardroom");
  } else {
    setTitle("Succession Now | Add Boardroom");
  }
  const { t } = useTranslation();

  const navigate = useNavigate();
  const role = localStorage.getItem("role");
  const user = useSelector((state) => state.auth?.user);
  const [loading, setLoading] = useState(true);
  const [education, setEducation] = useState([]);
  const [studies, setStudies] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [enabledOtherStudyPopup, setEnabledOtherStudyPopup] = useState(false);
  const [enabledOtherIndustryPopup, setEnabledOtherIndustryPopup] =
    useState(false);
  const [otherIndustryType, setOtherIndustryType] = useState("");
  const [actionValue, setActionValue] = useState({});
  const [isSave, setIsSave] = useState(false);
  const [lastname, setLastname] = useState("");
  const [firstname, setFirstname] = useState("");
  const [edu, setEdu] = useState("");
  const [study, setStudy] = useState("");
  const [industry, setIndustry] = useState("");
  const [industry1, setIndustry1] = useState("");
  const [industry2, setIndustry2] = useState("");
  // const [boardMambers, setBoardMambers] = useState([]);
  const [boardMambersDetails, setBoardMambersDetails] = useState({});
  const [detailsId, setDetailsId] = useState("");
  const [isCalling, setIsCalling] = useState(false);
  const [autoSave, setAutoSave] = useState(false);
  const [perspectiveReport, setPerspectiveReport] = useState({});
  const [message, setMessage] = useState("");

  const [isComplateSurvey, setIsComplateSurvey] = useState({
    totalQuestion: null,
    totalAnswer: null,
  });

  useEffect(() => {
    if (role && role !== "customer-boardmember") {
      return navigate("/dashboard");
    }
    if (role && role === "customer-boardmember") {
      // Board Member List
      const loadBoardMemberDetails = async () => {
        setLoading(true);
        try {
          const result = await postData("board-member/details", {
            id: user?._id,
            usedFor: "perspective-boardmember",
          });
          if (result.data) {
            setBoardMambersDetails(result.data);
            let totalQuestion =
              result?.data &&
              Array?.isArray(result?.data?.ratingOptions) &&
              result?.data?.ratingOptions?.length;
            let filterAnswer =
              result?.data?.ratingOptions?.length > 0 &&
              result?.data?.ratingOptions.filter(
                (it) => it?.answerId !== null || it?.ids?.length > 0
              );
            let totalAnswer = filterAnswer?.length;
            setIsComplateSurvey({
              totalQuestion: totalQuestion,
              totalAnswer: totalAnswer,
            });
          }
          setLoading(false);
        } catch (err) {
          setLoading(false);
          console.log(err.message);
        }
      };
      loadBoardMemberDetails();
    }
  }, [role]);

  useEffect(() => {
    if (actionValue && actionValue.button_type === "study_add") {
      setStudies([...studies, actionValue?.result]);
      setStudy(actionValue.row_id);
    } else if (actionValue && actionValue.button_type === "industry_add") {
      setIndustries([...industries, actionValue?.result]);
      setIndustry(actionValue.row_id);
    } else if (actionValue && actionValue.button_type === "industry_add1") {
      setIndustries([...industries, actionValue?.result]);
      setIndustry1(actionValue.row_id);
    } else if (actionValue && actionValue.button_type === "industry_add2") {
      setIndustries([...industries, actionValue?.result]);
      setIndustry2(actionValue.row_id);
    }
  }, [actionValue]);

  useEffect(() => {
    if (autoSave) {
      onSubmit();
    }
  }, [autoSave]);

  useEffect(() => {
    if (Object.keys(boardMambersDetails).length > 0) {
      setDetailsId(boardMambersDetails?._id);
      setLastname(boardMambersDetails?.last_name);
      setFirstname(boardMambersDetails?.first_name);
      setEdu(boardMambersDetails?.education);
      setStudy(boardMambersDetails?.study);
      setIndustry(boardMambersDetails?.industry);
      setIndustry1(boardMambersDetails?.industry_one);
      setIndustry2(boardMambersDetails?.industry_two);
      setPerspectiveReport({
        _id: boardMambersDetails?._id,
        lastName: boardMambersDetails?.last_name,
        firstName: boardMambersDetails?.first_name,
        education: boardMambersDetails?.education_details,
        study: boardMambersDetails?.study_details,
        industry: boardMambersDetails?.industry_details,
        industry_one: boardMambersDetails?.industry_one_details,
        industry_two: boardMambersDetails?.industry_two_details,
      });
      setMessage(boardMambersDetails?.comments);
    }
  }, [boardMambersDetails, isCalling]);

  useEffect(() => {
    async function educationList() {
      try {
        const result = await postData("education/list", {
          sortQuery: { created_at: 1 },
          status: 1,
          isBoardMember: 1,
        });
        if (result.data) {
          setEducation(result.data);
        } else {
          console.log("Education message", result.message);
        }
      } catch (error) {
        console.log("Error education catch", error.message);
      }
    }
    educationList();

    async function studyList() {
      try {
        const result = await postData("filter-item/list", {
          sortQuery: { created_at: 1 },
          status: 1,
          type: "study",
        });
        if (result.data) {
          setStudies(result.data);
        } else {
          console.log("Education message", result.message);
        }
      } catch (error) {
        console.log("Error education catch", error.message);
      }
    }
    studyList();

    async function industryList() {
      try {
        const result = await postData("industry/list", {
          sortQuery: { created_at: 1 },
          status: 1,
        });
        if (result.data) {
          setIndustries(result.data);
        } else {
          console.log("Industry message", result.message);
        }
      } catch (error) {
        console.log("Error education catch", error.message);
      }
    }
    industryList();

    // Board Member List
    // const loadBoardMembers = async () => {
    //   try {
    //     const result = await postData("board-member/list", {
    //       sortQuery: { created_at: 1 },
    //       is_interim: 2,
    //     });
    //     if (result.data) {
    //       setBoardMambers(result.data);
    //     }
    //   } catch (err) {
    //     console.log(err.message);
    //   }
    // };
    // if (role && role !== "customer-boardmember") {
    //   loadBoardMembers();
    // }
  }, []);

  const onSubmit = async (e) => {
    setIsSave(true);
    try {
      let path;
      let payload;
      if (detailsId) {
        path = "perspective-board-member/update";
        payload = {
          id: detailsId,
          first_name: firstname,
          last_name: lastname,
          education: edu,
          study: study !== "others" ? study : null,
          industry: industry !== "others" ? industry : null,
          industry1: industry1 !== "others" ? industry1 : null,
          industry2: industry2 !== "others" ? industry2 : null,
          comments: message,
        };
      } else {
        path = "perspective-board-member/create";
        payload = {
          first_name: firstname,
          last_name: lastname,
          education: edu,
          study: study !== "others" ? study : null,
          industry: industry !== "others" ? industry : null,
          industry1: industry1 !== "others" ? industry1 : null,
          industry2: industry2 !== "others" ? industry2 : null,
          comments: message,
        };
      }
      const result = await postData(path, payload);
      if (result.status && result.status === 200) {
        toastr.success(result.message);
        setIsSave(false);
        setAutoSave(false);
        if (!detailsId) {
          return navigate("/board-members");
        }
      } else {
        toastr.error(result.message);
        setIsSave(false);
      }
    } catch (error) {
      toastr.error(error.message);
      setIsSave(false);
    }
  };

  const getDownload = (e) => {
    e.preventDefault();
    if (e.target.tagName.toLowerCase() === "a") {
      window.open(e.target.href, "_blank");
    }
  };

  return (
    <>
      <div className="relative d-flex justify-between items-center mb-4">
        <div className="text-3xl text-slate-800 font-bold">
          {t("perspective_board_member_candidates")}
        </div>
        {/* <button
          type={"button"}
          className={
            "flex justify-center items-center gap-2 bg-transparent text-teal-500 rounded text-sm h-8 py-0 px-2 transition-all duration-200 cursor-pointer "
          }
          onClick={(e) => getDownload(e)}
        >
          <i className={"fa-fw fa-regular fa-download"}></i>
          <div className={"text-xs font-semibold whitespace-nowrap"}>
            <PDFDownloadLink
              document={
                <Document>
                  <PerspectiveReport
                    title={"Executive Audit 360 Reports"}
                    headingTitle={"Executive Audit 360"}
                    user={user}
                    companyLogo={API_URL + user?.image}
                    type={"executive"}
                    perspectiveReport={perspectiveReport}
                  />
                </Document>
              }
              fileName="scorecard.pdf"
            >
              {({ blob, url, loading, error }) =>
                loading ? t("loading_document") : t("download_report")
              }
            </PDFDownloadLink>
          </div>
        </button> */}
      </div>
      {isComplateSurvey &&
        isComplateSurvey &&
        isComplateSurvey.totalQuestion !== null &&
        isComplateSurvey.totalAnswer !== null &&
        (isComplateSurvey.totalQuestion !== isComplateSurvey.totalAnswer ? (
          <NoDataFound
            label={
              "You cannot use the proposed board members at this time. If you wish to proceed, please ensure that your survey is completed first."
            }
          />
        ) : (
          <div className="relative grid grid-cols-2 gap-4">
            <div className="col-span-2">
              <div className="relative bg-white rounded shadow divide-y divide-slate-200">
                <div className="py-8 px-6 flex flex-col">
                  <div className="space-y-4 mt-4">
                    <div className="flex items-start gap-4">
                      <div className="relative w-1/4 max-w-[calc(25%-1rem)]">
                        <Input
                          label={t("last_name")}
                          labelClasses={"!text-xs !font-semibold"}
                          inputType={"text"}
                          inputPlaceholder={t("enter_last_name")}
                          inputValue={lastname}
                          setInput={setLastname}
                          requiredDisplay={true}
                        />
                      </div>
                      <div className="relative w-1/4 max-w-[calc(25%-1rem)]">
                        <Input
                          label={t("first_name")}
                          labelClasses={"!text-xs !font-semibold"}
                          inputType={"text"}
                          inputPlaceholder={t("enter_first_name")}
                          inputValue={firstname}
                          setInput={setFirstname}
                          requiredDisplay={true}
                        />
                      </div>
                    </div>
                    <div className="flex items-start gap-4">
                      <div className="relative w-1/4 max-w-[calc(25%-1rem)]">
                        <Select
                          label={t("education")}
                          labelClasses={"!text-xs !font-semibold"}
                          xPlacement={"left"}
                          selectedValue={edu}
                          dropdownClass={"!w-60"}
                          dropdownData={[
                            {
                              _id: " ",
                              label: t("select_education"),
                              value: "",
                            },
                            ...education.map((item) => ({
                              _id: item?._id,
                              label: item?.name,
                              value: item?._id,
                            })),
                          ].filter((itm) => itm)}
                          getSelectedValue={(e) => {
                            setEdu(e._id);
                          }}
                          requiredDisplay={true}
                        />
                      </div>
                      <div className="relative w-1/4 max-w-[calc(25%-1rem)]">
                        <Select
                          label={t("field_of_study")}
                          labelClasses={"!text-xs !font-semibold"}
                          xPlacement={"left"}
                          selectedValue={study}
                          dropdownClass={"!w-60"}
                          dropdownData={[
                            { _id: " ", label: t("select_study"), value: "" },
                            ...studies.map((item) => ({
                              _id: item?._id,
                              label: item?.name,
                              value: item?._id,
                            })),
                            {
                              _id: "others",
                              label: t("other"),
                              value: "others",
                            },
                          ].filter((itm) => itm)}
                          getSelectedValue={(e) => {
                            if (e._id === "others") {
                              setEnabledOtherStudyPopup(true);
                            }
                            setStudy(e._id);
                          }}
                          requiredDisplay={true}
                        />
                      </div>
                    </div>
                    <div className="flex items-start gap-4">
                      <div className="relative w-1/2 max-w-[calc(50%-1rem)]">
                        <Select
                          label={t("industry_experience")}
                          labelClasses={"!text-xs !font-semibold"}
                          xPlacement={"left"}
                          selectedValue={industry}
                          dropdownClass={"!w-60"}
                          dropdownData={[
                            {
                              _id: " ",
                              label: t("select_industry"),
                              value: "",
                            },
                            ...industries.map((item) => ({
                              _id: item?._id,
                              label: item?.name,
                              value: item?._id,
                            })),
                            {
                              _id: "others",
                              label: t("other"),
                              value: "others",
                            },
                          ].filter((itm) => itm)}
                          getSelectedValue={(e) => {
                            if (e._id === "others") {
                              setEnabledOtherIndustryPopup(true);
                              setOtherIndustryType("industry_add");
                            }
                            setIndustry(e._id);
                          }}
                          requiredDisplay={true}
                        />
                      </div>
                    </div>
                    <div className="flex items-start gap-4">
                      <div className="relative w-1/4 max-w-[calc(25%-1rem)]">
                        <Select
                          label={t("industry_experience_2")}
                          labelClasses={"!text-xs !font-semibold"}
                          xPlacement={"left"}
                          selectedValue={industry1}
                          dropdownClass={"!w-60"}
                          dropdownData={[
                            {
                              _id: " ",
                              label: t("select_industry"),
                              value: "",
                            },
                            ...industries.map((item) => ({
                              _id: item?._id,
                              label: item?.name,
                              value: item?._id,
                            })),
                            {
                              _id: "others",
                              label: t("other"),
                              value: "others",
                            },
                          ].filter((itm) => itm)}
                          getSelectedValue={(e) => {
                            if (e._id === "others") {
                              setEnabledOtherIndustryPopup(true);
                              setOtherIndustryType("industry_add1");
                            }
                            setIndustry1(e._id);
                          }}
                          requiredDisplay={false}
                        />
                      </div>
                      <div className="relative w-1/4 max-w-[calc(25%-1rem)]">
                        <Select
                          label={t("industry_experience_3")}
                          labelClasses={"!text-xs !font-semibold"}
                          xPlacement={"left"}
                          selectedValue={industry2}
                          dropdownClass={"!w-60"}
                          dropdownData={[
                            {
                              _id: " ",
                              label: t("select_industry"),
                              value: "",
                            },
                            ...industries.map((item) => ({
                              _id: item?._id,
                              label: item?.name,
                              value: item?._id,
                            })),
                            {
                              _id: "others",
                              label: t("other"),
                              value: "others",
                            },
                          ].filter((itm) => itm)}
                          getSelectedValue={(e) => {
                            if (e._id === "others") {
                              setEnabledOtherIndustryPopup(true);
                              setOtherIndustryType("industry_add2");
                            }
                            setIndustry2(e._id);
                          }}
                          requiredDisplay={false}
                        />
                      </div>
                    </div>
                    <div className="relative w-1/2 max-w-[calc(25%-1rem)">
                      <Textarea
                        label={t("message")}
                        labelClasses={""}
                        inputClasses={
                          "focus:!border-dark-teal-300 focus:!bg-dark-teal-50/40"
                        }
                        inputPlaceholder={t("write_your_comments")}
                        inputValue={message}
                        inputName={""}
                        errorType={""}
                        setTextarea={setMessage}
                      />
                    </div>
                  </div>
                </div>
                <div className="py-3 px-3 border-t border-slate-200">
                  <div className="flex items-center justify-end gap-4">
                    <Button
                      buttonClasses=""
                      buttonLabelClasses=""
                      buttonType={"button"}
                      buttonIcon={
                        isSave
                          ? "fa-light fa-spinner fa-spin"
                          : "fa-light fa-save"
                      }
                      buttonIconPosition={"left"}
                      buttonLabel={t("submit")}
                      functions={onSubmit}
                      buttonHasLink={false}
                      buttonDisabled={isSave}
                    />
                    {/* {role && role !== "customer-boardmember" && (
                  <button
                    type={"button"}
                    className={
                      "flex justify-center items-center gap-2 bg-teal-500 text-white rounded text-lg h-10 py-0 px-3 transition-all duration-200 cursor-pointer "
                    }
                    onClick={(e) => getDownload(e)}
                  >
                    <i className={"fa-fw fa-light fa-download"}></i>
                    <div className={"text-sm font-medium whitespace-nowrap"}>
                      <PDFDownloadLink
                        document={
                          <BoardMemberReport
                            boardMembers={boardMambers}
                            user={user}
                          />
                        }
                        fileName="profile_report.pdf"
                      >
                        {({ blob, url, loading, error }) =>
                          loading ? t("loading_document") : t("report")
                        }
                      </PDFDownloadLink>
                    </div>
                  </button>
                )} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}

      {enabledOtherStudyPopup && (
        <OtherStudyPopup
          isOpen={enabledOtherStudyPopup}
          setIsOpen={(val) => setEnabledOtherStudyPopup(val)}
          getActionValue={(obj) => {
            setActionValue(obj);
          }}
        ></OtherStudyPopup>
      )}
      {enabledOtherIndustryPopup && (
        <OtherIndustryPopup
          isOpen={enabledOtherIndustryPopup}
          setIsOpen={(val) => setEnabledOtherIndustryPopup(val)}
          getActionValue={(obj) => {
            setActionValue(obj);
          }}
          otherIndustryType={otherIndustryType}
        ></OtherIndustryPopup>
      )}
    </>
  );
};

export default PerspectiveBoardMember;
