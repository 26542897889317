import React, { useState, useEffect, useCallback } from "react";
import { setTitle } from "../helpers/MetaTag";
import Button from "../components/form/Button";
import Select from "../components/form/Select";
import toastr from "toastr";
import { useSelector } from "react-redux";
import Searching from "../components/Searching";
import NoDataFound from "../components/NoDataFound";
import { Document, pdf, PDFDownloadLink } from "@react-pdf/renderer";
import BoardMemberReport from "./pdf/BoardMemberReport";
import BoardMemeberAlert from "../components/elements/alert/BoardMemeberAlert";
import CardAction from "../components/Common/CardAction";
import { useNavigate } from "react-router-dom";
import BoardMemberAllReport from "./pdf/BoardMemberAllReport";
import ManageInviteBoardMemberPopup from "../components/popup/ManageInviteBoardMemberPopup";

import { postData } from "../services/api";
import { useTranslation } from "react-i18next";
import BoardmemberResendSurveyPopup from "../components/popup/BoardmemberResendSurveyPopup";
import ProfileCardSkeleton from "../components/loader/ProfileCardSkeleton";
import moment from "moment";
import Indentification from "../components/elements/Indentification";
import { initialsValue } from "../helpers";
import Search from "../components/form/Search";
import Pagination from "../components/Common/Pagination";
import Checkbox from "../components/form/Checkbox";
import { Tooltip } from "react-tooltip";
import ManagePasswordPopup from "../components/popup/ManagePasswordPopup";
import PerspectiveReport from "./pdf/PerspectiveReport";
import { API_URL } from "../config/host";
import PerspectiveOverallReport from "./pdf/PerspectiveOverallReport";

const BoardMamberNew = () => {
  setTitle("Succession Now | Board Member");
  const { i18n, t } = useTranslation();
  const role = localStorage.getItem("role");
  const user = useSelector((state) => state.auth?.user);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [keyword, setKeyword] = useState("");
  const [limit, setLimit] = useState(15);
  const [offset, setOffset] = useState(0);
  const [sortKey, setSortKey] = useState("created_at");
  const [sortValue, setSortValue] = useState(-1);
  const [sortQuery, setSortQuery] = useState("");
  const [noOfPage, setNoOfPage] = useState(0);
  const [boardMambers, setBoardMambers] = useState([]);
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [isUpdate, setIsUpdate] = useState(false);
  const [actionValue, setActionValue] = useState({});
  const [reatingOptions, setReatingOptions] = useState([]);
  const [enabledInviteBoardMemberPopup, setEnabledInviteBoardMemberPopup] =
    useState(false);
  const [inviteInfo, setInviteInfo] = useState({});
  const [resendSurveyOpen, setResendSurveyOpen] = useState({
    isOpen: false,
    data: null,
  });
  const [boardmemberList, setBoardmemberList] = useState([]);
  const [startDataCount, setStartDataCount] = useState(0);
  const [endDataCount, setEndDataCount] = useState(0);
  const [selectedMember, setSelectedMember] = useState([]);
  const [areAllSelected, setAreAllSelected] = useState(false);
  const [filteredMembers, setFilteredMembers] = useState([]);
  const [enabledPasswordManagePopup, setEnabledPasswordManagePopup] =
    useState(false);
  const [passwordInfo, setPasswordInfo] = useState({});
  const [perspectives,setPerspectives] = useState([]);

  useEffect(() => {
    if (role && role !== "customer-admin") {
      return navigate("/dashboard");
    }
  }, [role]);

  useEffect(() => {
    if (sortKey && sortValue) {
      setSortQuery({ [sortKey]: sortValue });
    } else {
      setSortQuery("");
    }
  }, [sortKey, sortValue]);

  useEffect(() => {
    setNoOfPage(Math.ceil(totalDataCount / limit));
  }, [limit, totalDataCount]);

  useEffect(() => {
    async function employeeRatingOptionList() {
      try {
        const result = await postData("employee-rating-option/list", {
          sortQuery: { created_at: 1 },
          status: 1,
          limit: 50,
          type: "boardmember",
        });
        if (result.data) {
          setReatingOptions(result.data);
        } else {
          console.log("Advance desire message", result.message);
        }
      } catch (error) {
        console.log("Error advance desire catch", error.message);
      }
    }
    employeeRatingOptionList();

    async function proposedBoardMemberReport() {
      try {
        const result = await postData("proposed-board-member/report", {
          sortQuery: { created_at: 1 },
          type: "boardmember",
        });
        if (result.data) {
          setPerspectives(result.data);
        } else {
          console.log("Advance desire message", result.message);
        }
      } catch (error) {
        console.log("Error advance desire catch", error.message);
      }
    }
    proposedBoardMemberReport();
  }, []);

  const loadList = useCallback(async () => {
    setLoading(true);
    try {
      const result = await postData("board-member/list", {
        // keyword: keyword,
        // limit: limit,
        offset: offset,
        sortQuery: { created_at: 1 },
        outside_candidate: 2,
      });
      if (result.data) {
        const verifiedBoardMember =
          result?.data &&
          result.data?.length > 0 &&
          result.data?.filter((item) => item?.parent_id);
        setBoardMambers(
          result.data?.sort((a, b) => a.last_name.localeCompare(b.last_name))
        );
        setBoardmemberList(
          verifiedBoardMember?.length > 0 &&
            verifiedBoardMember?.map((items) => {
              let firstName = "";
              let lastName = "";
              if (!items?.first_name) {
                let nameParts = items?.name?.split(/\s+/);
                firstName = nameParts?.[0] || "";
                lastName = nameParts?.slice(1).join(" ") || "";
              } else {
                firstName = items?.first_name;
                lastName = items?.last_name;
              }
              return {
                _id: items?._id,
                label: firstName + " " + lastName + " - " + items?.email,
                value: items?.email,
                position: items?.designation,
                first_name: firstName,
                last_name: lastName,
                isEnabled: true,
                parent_id: items?.parent_id,
              };
            })
        );
      }
      setTotalDataCount(result.count);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err.message);
    } finally {
      setLoading(false);
    }
  }, [offset, isUpdate, actionValue]);

  useEffect(() => {
    loadList();
  }, [loadList]);

  const getInitials = function (string) {
    var names = string.split(" "),
      initials = names[0].substring(0, 1).toUpperCase();
    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  };

  const getDownload = (e) => {
    e.preventDefault();
    if (e.target.tagName.toLowerCase() === "a") {
      window.open(e.target.href, "_blank");
    }
  };

  useEffect(() => {
    if (
      actionValue &&
      actionValue.button_type === "send-invitation-boardmember"
    ) {
      setEnabledInviteBoardMemberPopup(true);
      setInviteInfo({
        _id: actionValue.row_id,
      });
    } else if (
      actionValue &&
      actionValue.button_type === "resend-invitation-boardmember"
    ) {
      const loadResendInvitation = async () => {
        try {
          const othersData = await postData("board-member/invitation-resend", {
            id: actionValue?.row_id,
          });
          if (othersData.data) {
            setActionValue({});
            toastr.success(
              "Email resent.Please make sure to check your spam email."
            );
          } else {
            toastr.error(othersData.message);
          }
        } catch (error) {
          toastr.error(error.message);
        }
      };
      loadResendInvitation();
    } else if (actionValue && actionValue.button_type === "resendSurvey") {
      setResendSurveyOpen({ isOpen: true, data: actionValue?.row_id });
    } else if (actionValue && actionValue.button_type === "update-password") {
      setEnabledPasswordManagePopup(true);
      setPasswordInfo({
        _id: actionValue?.row_id?.parent_id,
        data: actionValue?.row_id?.first_name
          ? {
              company_name: actionValue?.row_id?.first_name
                ? actionValue?.row_id?.first_name +
                  " " +
                  actionValue?.row_id?.last_name
                : "N/A",
            }
          : "N/A",
      });
      setIsUpdate(false);
    }else if (actionValue && actionValue.button_type === "perspective-report") {
      const loadBoardMemberDetails = async () => {
        try {
          const result = await postData("board-member/details", {
            id: actionValue?.row_id?.parent_id,
            usedFor: "perspective-boardmember",
          });
          if (result.data) {
            let perspectiveData = {
              _id: result.data?._id,
              last_name: result.data?.last_name,
              first_name: result.data?.first_name,
              education: result.data?.education_details,
              study: result.data?.study_details,
              industry: result.data?.industry_details,
              industry_one: result.data?.industry_one_details,
              industry_two: result.data?.industry_two_details,
              comments : result.data?.comments,
              submitted_by: result.data?.submitted_by
            }
            const newTab = window.open("", "_blank");
            newTab.document.write(`
              <html lang="en">
                <head>
                  <script src="https://cdn.tailwindcss.com"></script>
                  <style>
                    body {
                      @apply bg-gray-100;
                      margin: 0;
                      height: 100vh;
                    }
                    .loader-container {
                      @apply flex flex-col justify-center items-center h-screen;
                    }
                    .loader {
                      width: 50px;
                      aspect-ratio: 1;
                      display: grid;
                      border-radius: 50%;
                      background:
                        linear-gradient(0deg, rgb(0 0 0 / 50%) 30%, #0000 0 70%, rgb(0 0 0 / 100%) 0) 50% / 8% 100%,
                        linear-gradient(90deg, rgb(0 0 0 / 25%) 30%, #0000 0 70%, rgb(0 0 0 / 75%) 0) 50% / 100% 8%;
                      background-repeat: no-repeat;
                      animation: l23 1s infinite steps(12);
                    }
                    .loader::before,
                    .loader::after {
                      content: "";
                      grid-area: 1 / 1;
                      border-radius: 50%;
                      background: inherit;
                      opacity: 0.915;
                      transform: rotate(30deg);
                    }
                    .loader::after {
                      opacity: 0.83;
                      transform: rotate(60deg);
                    }
                    @keyframes l23 {
                      100% {
                        transform: rotate(1turn);
                      }
                    }
                  </style>
                </head>
                <body>
                 <div class="flex justify-center items-center mt-20 loader-container">
                  <div class="flex flex-col items-center justify-center space-y-4">
                    <div class="loader"></div>
                    <p class="text-teal-700 text-lg font-semibold mt-4">Generating your PDF... Please wait.</p>
                  </div>
                 </div>
                </body>
              </html>
            `);
            try {
              // Generate the PDF blob asynchronously.
              const blob = await pdf(
                <Document>
                  <PerspectiveReport
                    title={t('prospective_board_member_report')}
                    headingTitle={t('prospective_board_members')}
                    user={user}
                    companyLogo={API_URL + user?.image}
                    type={"boardmember"}
                    perspectiveReport={perspectiveData}
                  />
                </Document>
              ).toBlob();
        
              // Create a URL from the blob.
              const blobURL = URL.createObjectURL(blob);
              // Redirect the new tab to the blob URL (load the PDF).
              newTab.location.href = blobURL;
            } catch (error) {
              // Display an error message in the new tab.
              newTab.document.body.innerHTML = `
                <div class="flex flex-col items-center justify-center h-screen bg-red-100">
                  <p class="text-red-700 text-lg font-semibold">Failed to load PDF. Please try again later.</p>
                  <p class="text-sm text-gray-600">${error.message}</p>
                </div>
              `;
              console.error("PDF generation error:", error);
            }
          }
        } catch (err) {
          setLoading(false);
          console.log(err.message);
        }
      };
      loadBoardMemberDetails();
    }
  }, [actionValue]);

  useEffect(() => {
    setNoOfPage(Math.ceil(totalDataCount / limit));
    const startItem = Number(totalDataCount > 0 ? 1 : 0);
    const endItem = Math.min(startItem + limit - 1, totalDataCount);
    setStartDataCount(startItem);
    setEndDataCount(endItem);
    if (keyword) {
      setOffset(0);
    }
  }, [limit, totalDataCount, keyword]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * limit) % totalDataCount;
    // console.log(
    //   `User requested page number ${event.selected}, which is offset ${newOffset}`
    // );
    setOffset(newOffset);
    const startItem = Number(newOffset + 1);
    const endItem = Math.min(startItem + limit - 1, totalDataCount);
    setStartDataCount(startItem);
    setEndDataCount(endItem);
    setSelectedMember([]);
  };

  const handleSelectedMember = (item) => {
    setSelectedMember((prevSelected) => {
      const isAlreadySelected = prevSelected.some(
        (member) => member?._id === item?._id
      );

      if (isAlreadySelected) {
        return prevSelected.filter((member) => member?._id !== item?._id);
      } else {
        return [...prevSelected, item];
      }
    });
  };

  const handleSelectAll = () => {
    const filteredMembers = boardMambers.filter((member) => member.parent_id);

    if (selectedMember.length === filteredMembers.length) {
      setSelectedMember([]);
    } else {
      setSelectedMember(filteredMembers);
    }
  };

  useEffect(() => {
    const filteredMembers = boardMambers?.filter((member) => member?.parent_id);
    const isAllSelected =
      selectedMember.length === filteredMembers.length &&
      filteredMembers.length !== 0;

    if (isAllSelected) {
      setAreAllSelected(true);
    } else {
      setAreAllSelected(false);
    }
  }, [selectedMember, boardMambers, areAllSelected]);

  

  return (
    <>
      <div className="relative flex justify-between items-center mb-4 flex-wrap sm:flex-nowrap gap-y-4">
        <div className="text-xl md:text-3xl text-slate-800 font-bold">
          {t("board_member") + " - " + user?.company_name}
        </div>
        <div className="sm:ml-auto flex justify-end items-center space-x-2">
          <div className="">
            <Button
              buttonType={"button"}
              buttonIcon={"fa-solid fa-paper-plane"}
              buttonIconPosition={"left"}
              buttonLabel={t("resend_survey")}
              buttonHasLink={false}
              //buttonLink={"/add-executive"}
              functions={() => {
                if (selectedMember.length > 0) {
                  setResendSurveyOpen({ isOpen: true, data: "all" });
                } else {
                  toastr.warning("Please Select a Board Member");
                }
              }}
            />
          </div>
          {role &&
            (role === "customer-admin" ||
              role === "customer-subadmin" ||
              role === "customer-boardmember") && (
              <div className="">
                <Button
                  buttonType={"button"}
                  buttonIcon={"fa-light fa-plus"}
                  buttonIconPosition={"left"}
                  buttonLabel={t("add_board_member")}
                  buttonHasLink={true}
                  buttonLink={"/boardroom"}
                />
              </div>
            )}
            {/* <div className="">
              <button
                type={"button"}
                className={
                  "flex justify-center items-center gap-2 bg-teal-500 text-white rounded text-base sm:text-lg h-8 sm:h-10 py-0 px-2 sm:px-3 transition-all duration-200 cursor-pointer"
                }
                onClick={(e) => getDownload(e)}
              >
                <i className={"fa-fw fa-light fa-download"}></i>
                <div className={"text-xs sm:text-sm font-medium whitespace-nowrap"}>
                  <PDFDownloadLink
                    document={
                      <Document>
                      <PerspectiveOverallReport
                        title={"Board Member Perspective Reports"}
                        headingTitle={"Board Member Perspective"}
                        user={user}
                        companyLogo={API_URL + user?.image}
                        type={"boardmember"}
                        perspectiveReport={perspectives}
                        />
                      </Document>
                    }
                    fileName="profile_report.pdf"
                  >
                    {({ blob, url, loading, error }) =>
                      loading ? t("loading_document") : t("download_pdf")
                    }
                  </PDFDownloadLink>
                </div>
              </button>
            </div> */}
          {/* <button
          type={'button'}
          className={"flex justify-center items-center gap-2 bg-teal-500 text-white rounded text-lg h-10 py-0 px-3 transition-all duration-200 cursor-pointer "}
          onClick={(e) => getDownload(e)}
        >
          <i className={"fa-fw fa-light fa-download"}></i>
          <div className={"text-sm font-medium whitespace-nowrap"}>
            <PDFDownloadLink document={
            <BoardMemberAllReport 
            boardMembers={boardMambers} 
            reatingOptions={reatingOptions}
            user={user} 
            />} fileName="boardmember_report.pdf">
              {({ blob, url, loading, error }) => (
                loading ? t('loading_document') : t('report'))}
            </PDFDownloadLink>
          </div>
        </button> */}
        </div>
      </div>
      <BoardMemeberAlert />

      <div className="relative flex gap-3 items-center mb-3 flex-wrap mt-2">
        {boardMambers?.filter((member) => member?.parent_id).length > 0 && (
          <div
            className=""
            data-tooltip-id={`selectAllButton${134}`}
            data-tooltip-place="bottom"
          >
            <Button
              buttonType={"button"}
              buttonClasses={
                "bg-transparent border border-transparent !text-dark-teal-500 ml-2"
              }
              buttonLabelClasses="text-dark-teal-500"
              buttonIcon={
                areAllSelected
                  ? "fa-solid fa-square-check"
                  : "fa-regular fa-square"
              }
              buttonIconPosition={"left"}
              buttonLabel={
                areAllSelected
                  ? "Unselect All For Reset Survey"
                  : "Select All For Reset Survey"
              }
              buttonHasLink={false}
              functions={() => handleSelectAll()}
            />
            <Tooltip
              id={`selectAllButton${134}`}
              className="!text-xs !bg-dark-teal-600 z-50 rounded py-3 px-6"
              render={() => (
                <div className="gap-x-1">
                  <div className="text-[13px] font-normal leading-none flex items-center">
                    <div className="font-semibold">
                      {areAllSelected
                        ? "Unselect All Invited Board Member"
                        : "Select All Invited Board Member"}
                    </div>
                  </div>
                </div>
              )}
            />
          </div>
        )}
        {/* <div className="relative w-40">
          <Select
            xPlacement={"left"}
            dropdownButtonClass={"!bg-white"}
            dropdownClass={"w-auto"}
            selectedValue={limit}
            dropdownData={[
              { _id: 1000000, label: t("all_items"), value: 1000000 },
              ...[
                { _id: 15, label: "15 " + t("items"), value: 15 },
                { _id: 20, label: "20 " + t("items"), value: 20 },
                { _id: 30, label: "30 " + t("items"), value: 30 },
                { _id: 50, label: "50 " + t("items"), value: 50 },
                { _id: 100, label: "100 " + t("items"), value: 100 },
              ],
            ]}
            getSelectedValue={(e) => {
              setLimit(e.value);
              setSelectedMember([]);
            }}
          />
        </div>
        <div className="relative w-60">
          <Search
            placeholder={t("search")}
            setInput={setKeyword}
            setSelectedMember={setSelectedMember}
          />
        </div> */}
      </div>
      <div className="relative flex justify-between items-center mb-4">
        &nbsp;
      </div>
      <div className="w-full overflow-auto scroll-smooth scrollbar min-h-[480px] mb-4">
        {totalDataCount > 0 ? (
          <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 2xl:grid-cols-5 gap-2 sm:gap-3">
            {boardMambers.length > 0 &&
              boardMambers.map((item, index) => {
                let namelatter = getInitials(
                  item.first_name + " " + item.last_name
                );
                let totalQuestion = reatingOptions?.length;
                let filterAnswer =
                  item?.ratingOptions?.length > 0 &&
                  item?.ratingOptions.filter(
                    (it) => it?.answerId !== null || (it?.ids?.length > 0 || it?.isNone)
                  );
                let totalAnswer = filterAnswer?.length;
                return (
                  <div
                    className="relative w-full bg-white border border-gray-200 rounded-lg shadow overflow-hidden"
                    key={index}
                  >
                    {totalQuestion === totalAnswer ? (
                      <div className="absolute top-0 left-0">
                        <div className="text-white bg-green-500 rounded-br-xl py-2 px-3 !leading-none text-xs font-medium">
                          {t("completed")}
                        </div>
                      </div>
                    ) : (
                      <div className="absolute top-0 left-0">
                        <div className="text-white bg-rose-500 rounded-br-xl py-2 px-3 !leading-none text-xs font-medium">
                          {t("in_completed")}
                        </div>
                      </div>
                    )}
                    <div className="flex justify-end px-2">
                      <CardAction
                        xPlacement={"right"}
                        dropdownData={[
                          // {
                          //   _id: 1,
                          //   label: t("edit"),
                          //   icon: "fa-pen-to-square",
                          //   link: "/board-member/edit/" + item?._id,
                          //   type: "link",
                          //   standout: false,
                          //   value: item?._id,
                          //   buttonType: "",
                          //   ordering: 1,
                          // },
                          // {
                          //   _id: 2,
                          //   label: t("details"),
                          //   icon: "fa-eye",
                          //   link: "/board-member/details/" + item?._id,
                          //   type: "link",
                          //   standout: false,
                          //   value: item?._id,
                          //   buttonType: "",
                          //   ordering: 2,
                          // },
                          !item?.parent_id
                            ? {
                                _id: 3,
                                label: t("send_invite"),
                                icon: "fa-envelope",
                                link: "/",
                                type: "button",
                                standout: false,
                                value: item?._id,
                                buttonType: "send-invitation-boardmember",
                                ordering: 3,
                              }
                            : "",
                          item?.parent_id && item?.email_verified !== 1
                            ? {
                                _id: 3,
                                label: t("resend_invitation"),
                                icon: "fa-envelope",
                                link: "/",
                                type: "button",
                                standout: false,
                                value: item?._id,
                                buttonType: "resend-invitation-boardmember",
                                ordering: 3,
                              }
                            : "",
                          item?.parent_id
                            ? {
                                _id: 4,
                                label: t("resend_survey"),
                                icon: "fa-solid fa-paper-plane",
                                link: "/",
                                type: "button",
                                standout: false,
                                value: item,
                                buttonType: "resendSurvey",
                                ordering: 4,
                              }
                            : "",
                          item?.email_verified === 1
                            ? {
                                _id: 3,
                                label: t("update_password"),
                                icon: "fa-key",
                                link: "/",
                                type: "button",
                                standout: false,
                                value: item,
                                buttonType: "update-password",
                                isVisabled: 1,
                                ordering: 3,
                              }
                            : "",
                          totalQuestion === totalAnswer ?
                            {
                              _id: 5,
                              label: t("perspective_report"),
                              icon: "fa-file-pdf",
                              link: "/",
                              type: "button",
                              standout: false,
                              value: item,
                              buttonType: "perspective-report",
                              isVisabled: 1,
                              ordering: 3,
                            }:""
                        ]}
                        getActionValue={(obj) => setActionValue(obj)}
                      />

                      {item?.parent_id && (
                        <div
                          className="flex items-center justify-between py-2"
                          key={index}
                        >
                          <input
                            type="checkbox"
                            id={`${item?._id}`}
                            checked={selectedMember.some(
                              (member) => member?._id === item?._id
                            )}
                            onChange={() => handleSelectedMember(item)}
                            className="border cursor-pointer w-5 h-5 border-slate-400 focus:ring-transparent checked:rounded-full rounded-full checked:text-dark-teal-700   checked:hover:bg-dark-teal-700 checked:bg-dark-teal-700 "
                          />
                        </div>
                      )}
                    </div>
                    <div className="flex flex-col items-center pb-10 space-y-2">
                      <div className="w-16 h-16 overflow-hidden bg-slate-100 rounded-full relative flex items-center justify-center border border-white font-bold">
                        {/* {namelatter} */}
                        <Indentification
                          initial={initialsValue(
                            item?.first_name + " " + item?.last_name
                          )}
                          alt={"shortName"}
                          fill={true}
                          className={""}
                          size={"xl"}
                          initialClass={"!text-xl"}
                        />
                      </div>
                      <div className="text-center">
                        <h5 className="text-base sm:text-lg font-bold text-gray-900 capitalize">
                          {item?.first_name + " " + item?.last_name}
                        </h5>
                        <h5 className="text-[12px] font-bold text-slate-500">
                          {item?.email || "N/A"}
                        </h5>
                        {/* <div className="text-xs sm:text-sm font-semibold sm:font-normal text-gray-600 capitalize">
                          {item?.position_details
                            ? item?.position_details?.name
                            : ""}
                        </div> */}
                        <div className="flex justify-center mt-2">
                          {item?.parent_id ? (
                            item?.email_verified &&
                            item?.email_verified === 1 ? (
                              <div className="text-sm flex items-center space-x-1 text-green-500">
                                <i className="fa-regular fa-fw fa-check-circle"></i>
                                <span className="text-xs font-semibold">
                                  {t("invite_accecpted")}
                                </span>
                              </div>
                            ) : (
                              <div className="text-sm flex items-center space-x-1 text-yellow-500">
                                <i className="fa-regular fa-fw fa-clock"></i>
                                <span className="text-xs font-semibold">
                                  {t("invited")}
                                </span>
                              </div>
                            )
                          ) : (
                            <div className="text-sm flex items-center space-x-1 text-red-500">
                              <i className="fa-regular fa-fw fa-circle-xmark"></i>
                              <span className="text-xs font-semibold">
                                {t("not_invited")}
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="text-sm flex items-center space-x-1">
                        <div className="text-slate-500">Invitation:</div>
                        <span className="text-xs font-semibold text-slate-800">
                          {item && item?.created_at
                            ? moment(new Date(item?.created_at)).format("ll")
                            : "N/A"}
                        </span>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        ) : (
          <div className="relative bg-white shadow rounded">
            <div className="py-8 px-4 w-full">
              {loading ? (
                <div className="p-4 sm:p-6">
                  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
                    {Array(12)
                      .fill(null)
                      .map((_, index) => (
                        <ProfileCardSkeleton key={index} />
                      ))}
                  </div>
                </div>
              ) : (
                <NoDataFound label={t("no_board_member_found")} />
              )}
            </div>
          </div>
        )}
      </div>
      {/* {noOfPage > 1 ? (
        <Pagination
          handlePageClick={handlePageClick}
          noOfPage={noOfPage}
          startDataCount={startDataCount}
          endDataCount={endDataCount}
          count={totalDataCount}
        />
      ) : (                     
        <div className="flex justify-center sm:justify-between my-4 text-sm text-dark-teal-500 text-center sm:text-left">
          {totalDataCount > 0 ? (
            <div>
              <p>
                {t("showing")}: {startDataCount} to {endDataCount} of{" "}
                {totalDataCount}
              </p>
            </div>
          ) : (
            ""
          )}
        </div>
      )} */}
      {enabledInviteBoardMemberPopup && (
        <ManageInviteBoardMemberPopup
          isOpen={enabledInviteBoardMemberPopup}
          setIsOpen={(val) => setEnabledInviteBoardMemberPopup(val)}
          data={inviteInfo}
          getActionValue={(obj) => {
            setActionValue(obj);
            setEnabledInviteBoardMemberPopup(false);
          }}
        />
      )}
      {resendSurveyOpen?.isOpen && (
        <BoardmemberResendSurveyPopup
          isOpen={resendSurveyOpen?.isOpen}
          setIsOpen={setResendSurveyOpen}
          data={resendSurveyOpen?.data}
          boardmemberList={boardmemberList}
          loadList={loadList}
          selectedMember={selectedMember}
          setSelectedMember={setSelectedMember}
        />
      )}
      {enabledPasswordManagePopup && (
        <ManagePasswordPopup
          isOpen={enabledPasswordManagePopup}
          setIsOpen={(val) => setEnabledPasswordManagePopup(val)}
          data={passwordInfo}
          getActionValue={(obj) => {
            setActionValue(obj);
            setEnabledPasswordManagePopup(false);
          }}
        />
      )}
    </>
  );
};

export default BoardMamberNew;
