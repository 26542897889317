import React, { useState, useEffect } from "react";
import toastr from "toastr";
import moment from "moment";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Papa from "papaparse";
import { setTitle } from "../../../helpers/MetaTag";
import { postData } from "../../../services/api";
import Button from "../../form/Button";
import Searching from "../../Searching";
import ReactPaginate from "react-paginate";
import ManageEmployeePopup from "../../popup/ManageEmployeePopup";
import DeletePopup from "../../DeletePopup";
import Select from "../../form/Select";
import NoDataFound from "../../NoDataFound";
import TableSkeleton from "../../loader/TableSkeleton";
import { useParams } from "react-router-dom";

const AllEmployees = () => {
  setTitle("Succession Planning Software for your Business with Succession Now | Company Employees");
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const { i18n, t } = useTranslation();
  const role = localStorage.getItem("role");
  const user = useSelector((state) => state.auth?.user);
  const time = new Date().getTime();
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [noOfPage, setNoOfPage] = useState(0);
  const [position, setPosition] = useState([]);
  const [sortKey, setSortKey] = useState("created_at");
  const [sortValue, setSortValue] = useState(-1);
  const [sortQuery, setSortQuery] = useState("");
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [startDataCount, setStartDataCount] = useState(0);
  const [endDataCount, setEndDataCount] = useState(0);
  const [csvData, setCsvData] = useState([]);
  const [isUpdateFile, setIsUpdateFile] = useState(false);
  const [actionValue, setActionValue] = useState({});
  const [enabledManageEmployeePopup, setEnabledManageEmployeePopup] = useState(false);
  const [currentId, setCurrentId] = useState("");
  const [enabledDeletePopup, setenabledDeletePopup] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);

  const sampleExcelData = [["firstname", "lastname", "email", "position"]];

  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    Papa.parse(file, {
      complete: async (res) => {
        // Update state with parsed CSV data
        //setCsvData(result.data);
        try {
          const result = await postData("employee/create", {
            result: res?.data,
          });
          if (result.data) {
            // setCsvData(
            //   result.data.map((value, key) => {
            //     return {
            //       _id: value?._id,
            //       rowData:[
            //         { _id:1, width:40, type:'text', data:value?.firstname },
            //         { _id:2, width:15, type:'text', data:value?.lastname },
            //         { _id:3, width:15, type:'text', data:value?.email },
            //         { _id:4, width:15, type:'text', data:value?.position },
            //         { _id:5, width:20, type:'text', data:(value && value?.created_at)?moment(new Date()).format('Do MMM YYYY'):'N/A'},
            //         {
            //           _id:6,
            //           width:10,
            //           type:'action',
            //           data:[

            //           ],
            //           statusLabel: (value?.status && value?.status === 1)?t('active'):t('deactivated'),
            //           statusType: (value?.status && value?.status === 1)?'success':'warning'
            //         }
            //       ]
            //     }
            //   })
            // )
            setIsUpdateFile(true);
          }
          setTotalDataCount(result.count);
          setLoading(false);
        } catch (err) {
          setLoading(false);
          console.log(err.message);
        }

        // You can customize the CSV file name here
        //setCsvFileName(file.name);
      },
      header: true, // Set to true if your CSV has a header row
    });
  };

  useEffect(() => {
    async function positionData() {
      try {
        const result = await postData("position/list", {
          sortQuery: { created_at: 1 },
          status: 1,
        });
        if (result.data) {
          setPosition(result.data?.sort((a, b) => a.name.localeCompare(b.name)));
        } else {
          console.log("Position list message", result.message);
        }
      } catch (error) {
        console.log("Error position list catch", error.message);
      }
    }
    positionData();
  }, []);

  useEffect(() => {
    const loadTempData = async () => {
      setLoading(true);
      try {
        const result = await postData("employee/list", {
          limit: limit,
          offset: offset,
          sortQuery: sortQuery,
          companyId: id,
        });
        if (result.data) {
          setCsvData(result.data);
        }
        setTotalDataCount(result.count);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err.message);
      }
    };
    loadTempData();
  }, [limit, offset, sortQuery, isUpdateFile, actionValue, id]);

  useEffect(() => {
    setNoOfPage(Math.ceil(totalDataCount / limit));
    const startItem = Number(totalDataCount > 0 ? 1 : 0);
    const endItem = Math.min(startItem + limit - 1, totalDataCount);
    setStartDataCount(startItem);
    setEndDataCount(endItem);
  }, [limit, totalDataCount]);

  const handelUpdate = async (id, val, field, index) => {
    try {
      setCsvData(
        csvData.map((it, idx) => {
          if (idx === index) {
            return { ...it, [field]: val };
          } else {
            return it;
          }
        })
      );
      const result = await postData("employee/update", {
        id: id,
        [field]: val !== "" ? val : null,
      });
      if (result.status && result.status === 200) {
        toastr.success(result.message);
      } else {
        toastr.error(result.message);
      }
    } catch (error) {
      toastr.error(error.message);
    }
  };

  const handlePageClick = (event) => {
    const newOffset = (event.selected * limit) % totalDataCount;
    console.log(`User requested page number ${event.selected}, which is offset ${newOffset}`);
    setOffset(newOffset);
    const startItem = Number(newOffset + 1);
    const endItem = Math.min(startItem + limit - 1, totalDataCount);
    setStartDataCount(startItem);
    setEndDataCount(endItem);
  };

  useEffect(() => {
    if (actionValue && actionValue.button_type === "delete") {
      const loadDeleteRecord = async () => {
        setIsUpdate(false);
        try {
          const statusData = await postData("employee/delete", {
            id: actionValue?.row_id,
          });
          if (statusData.data) {
            const index = csvData.findIndex((item) => item._id === actionValue?.row_id);
            if (index !== -1) {
              csvData.splice(index, 1);
              setCsvData(
                csvData.map((it) => {
                  return it;
                })
              );
            }
            setIsUpdate(true);
          } else {
            toastr.error(statusData.message);
          }
        } catch (error) {
          toastr.error(error.message);
        }
      };
      loadDeleteRecord();
    }
  }, [actionValue]);

  const downloadSampleFile = async () => {
    try {
      const res = await postData("download/sample-csv", {});
      if (res) {
        const blob = new Blob([res], { type: "text/csv" });
        const blobUrl = URL.createObjectURL(blob);
        const downloadLink = document.createElement("a");
        downloadLink.href = blobUrl;
        downloadLink.download = "succession-employee-" + time + ".csv";
        downloadLink.click();
        URL.revokeObjectURL(blobUrl);
      } else {
        toastr.error(res.message);
      }
    } catch (error) {
      toastr.error(error.message);
    }
  };

  return (
    <>
      <div className="relative flex justify-between items-center mb-4 flex-wrap sm:flex-nowrap gap-y-4">
        <div className="text-xl md:text-2xl text-slate-800 font-bold">{t("company_employees")}</div>
      </div>
      <div className="w-full overflow-auto scroll-smooth scrollbar min-h-[480px] mb-4">
        <table className="min-w-max w-full table-auto">
          <thead>
            <tr className="text-white font-bold text-sm leading-none border-b border-slate-200">
              <th className="py-4 px-2 text-left max-w-[240px] align-top sticky -top-6 z-10 bg-slate-900 rounded-tl-md">{t("first_name")}</th>
              <th className="py-4 px-2 text-left max-w-[240px] align-top sticky -top-6 z-10 bg-slate-900">{t("last_name")}</th>
              <th className="py-4 px-2 text-left max-w-[240px] align-top sticky -top-6 z-10 bg-slate-900">{t("email")}</th>
              <th className="py-4 px-2 text-left max-w-[240px] align-top sticky -top-6 z-10 bg-slate-900">{t("position")}</th>
              <th className="py-4 px-2 text-left max-w-[240px] align-top sticky -top-6 z-10 bg-slate-900">{t("date")}</th>
              {/* <th className="py-4 px-2 text-left max-w-[240px] align-top sticky -top-6 z-10 bg-slate-900">
                {t("action")}
              </th> */}
            </tr>
          </thead>
          {totalDataCount > 0 ? (
            <tbody className="text-slate-700 text-sm font-light bg-white">
              {csvData.map((items, index) => {
                let firstName = "";
                let lastName = "";
                if (!items?.first_name) {
                  firstName = items?.name.split(/\s+/)[0];
                  lastName = items?.name.split(/\s+/)[1];
                } else {
                  firstName = items?.first_name;
                  lastName = items?.last_name;
                }
                return (
                  <tr className="border-b border-slate-200 group" key={index}>
                    <td className="py-3 px-2 font-semibold text-sm text-left max-w-[240px] align-middle">{firstName}</td>
                    <td className="py-3 px-2 font-semibold text-sm text-left max-w-[240px] align-middle">{lastName}</td>
                    <td className="py-3 px-2 font-semibold text-sm text-left max-w-[240px] align-middle">{items?.email}</td>
                    <td className="py-3 px-2 text-left max-w-[240px]">{items?.position_details?.name}</td>
                    <td className="py-3 px-2 font-semibold text-sm text-left max-w-[240px] align-middle">
                      {items && items?.created_at ? moment(new Date(items.created_at)).format("Do MMM YYYY") : "N/A"}
                    </td>
                    {/* <td className="py-3 px-2 font-semibold text-sm text-left max-w-[240px] align-middle">
                      <Button
                        buttonClasses={
                          "!p-0 !flex !items-center !justify-center !text-xs !bg-transparent !w-4 !h-4 !text-rose-500"
                        }
                        buttonType={"button"}
                        buttonIcon={"fa-regular fa-trash-can"}
                        buttonIconPosition={"left"}
                        buttonHasLink={false}
                        functions={() => {
                          setCurrentId(items?._id);
                          setenabledDeletePopup(true);
                          setIsUpdate(false);
                        }}
                      />
                    </td> */}
                  </tr>
                );
              })}
            </tbody>
          ) : (
            <tbody className="text-slate-700 text-sm font-light bg-white">
              <tr className="border-b border-slate-200 group">
                <td className="py-3 px-2 font-semibold text-sm text-left max-w-[240px] align-middle" colspan={6}>
                  {loading ? <TableSkeleton /> : <NoDataFound label={t("no_data_found")} />}
                </td>
              </tr>
            </tbody>
          )}
        </table>
      </div>
      {noOfPage > 1 ? (
        <div className="flex justify-center sm:justify-between my-4">
          <div>
            <p>
              {t("showing")}: {startDataCount} to {endDataCount} of {totalDataCount}
            </p>
          </div>
          <ReactPaginate
            breakLabel="..."
            breakClassName="w-8 h-8 flex justify-center items-center rounded hover:bg-slate-200"
            nextLabel={<i className="fa-regular fa-fw fa-chevron-right"></i>}
            nextClassName="w-8 h-8 flex justify-center items-center rounded hover:bg-slate-200"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            pageCount={noOfPage}
            containerClassName="flex justify-center items-center text-slate-80 text-sm font-medium mt-8 lg:mt-0 space-x-2"
            activeClassName="hover:bg-teal-500 bg-teal-500 text-white"
            pageLinkClassName="flex justify-center items-center w-full h-full"
            previousLabel={<i className="fa-regular fa-fw fa-chevron-left"></i>}
            pageClassName="w-8 h-8 flex justify-center items-center rounded hover:bg-slate-200"
            previousClassName="w-8 h-8 flex justify-center items-center rounded hover:bg-slate-200"
            renderOnZeroPageCount={null}
          />
        </div>
      ) : (
        <div className="flex justify-center sm:justify-between my-4">
          {totalDataCount > 0 ? (
            <div>
              <p>
                {t("showing")}: {startDataCount} to {endDataCount} of {totalDataCount}
              </p>
            </div>
          ) : (
            ""
          )}
        </div>
      )}
      {enabledManageEmployeePopup && (
        <ManageEmployeePopup
          isOpen={enabledManageEmployeePopup}
          setIsOpen={(val) => setEnabledManageEmployeePopup(val)}
          getActionValue={(obj) => {
            setActionValue(obj);
          }}
        ></ManageEmployeePopup>
      )}
      {enabledDeletePopup && (
        <DeletePopup
          isOpen={enabledDeletePopup}
          setIsOpen={(val) => setenabledDeletePopup(val)}
          currentId={currentId}
          getActionValue={(obj) => {
            setActionValue(obj);
            setenabledDeletePopup(false);
            setCurrentId("");
          }}
        />
      )}
    </>
  );
};

export default AllEmployees;
