import React from "react";
import { Page, Text, View, Image, Font } from "@react-pdf/renderer";
import logo from "../../logo-succession-now.png";
import Shape from "../../shape.png";
import moment from 'moment/moment';

import RobotoLight from "../../assets/font/Roboto-Light.ttf";
import RobotoRegular from "../../assets/font/Roboto-Regular.ttf";
import RobotoMedium from "../../assets/font/Roboto-Medium.ttf";
import RobotoBold from "../../assets/font/Roboto-Bold.ttf";

Font.register({ family: "RobotoLight", src: RobotoLight });
Font.register({ family: "RobotoRegular", src: RobotoRegular });
Font.register({ family: "RobotoMedium", src: RobotoMedium });
Font.register({ family: "RobotoBold", src: RobotoBold });

const DashboardReport = ({ 
  results, 
  user,
  heading,
  header,
  type
}) => {
  if(type && type === 'expenses'){
    header.pop();
    Array.isArray(results) && results.forEach((element,i) => {
      results[i].rowData.pop();
    });
  }  

  return (
    <Page size="A4" orientation="landscape" style={{ padding: 16, fontFamily: "RobotoRegular", lineHeight: 1.2, fontSize: 9, color: "#334155", backgroundColor: "#f1f5f9", }}>
      <View style={{ position: "absolute", left: 0, top: 0, right: 0, bottom: 0, zIndex: 0, }} fixed>
        <View style={{ position: "absolute", left: 0, top: 0, right: 0, width: "100%", height: 160, zIndex: -1, opacity: 0.1, }}>
          <Image style={{ objectFit: "cover" }} src={Shape} />
        </View>
        <View style={{ position: "absolute", width: "100%", left: 0, bottom: -10, right: 0, height: 160, zIndex: -1, opacity: 0.075, }}>
          <Image style={{ objectFit: "contain" }} src={logo} />
        </View>
      </View>
      <View style={{ paddingBottom: 20, width: "100%", margin: "0 auto", display: "flex", flexDirection: "column", alignItems: "center" }}>
        <View style={{ textAlign: "center", display: "flex", flexDirection: "column", justifyContent: "center", fontSize: 16, }} >
          <Image style={{ width: 200, height: 40, objectFit: "contain", display: "flex", alignItems: "center", justifyContent: "center" }} src={logo} />
        </View>
        <View style={[{ display: "flex", width: "100%", flexDirection: "row", justifyContent: "center", marginTop: 15 }]}>
          <Text style={{ fontFamily: "RobotoBold", fontSize: 12, textTransform: "uppercase", color: "#14b8a6", letterSpacing: 3, textAlign: "center", }}>{heading} Report</Text>
        </View>
        <View style={[{ display: "flex", width: "100%", flexDirection: "row", marginTop: 5, textAlign: "center", justifyContent: "center" }]}>
          <View style={[{ display: "flex", flexDirection: "row", marginTop: 5, textAlign: "center", justifyContent: "center", marginRight: 12 }]}>
            <Text style={[{ fontFamily: "RobotoBold", fontSize: 10, textAlign: "center" }]}>Created by:</Text>
            <Text style={{ fontFamily: "RobotoRegular", fontSize: 10, marginLeft: 2 }}>{user?.name}</Text>
          </View>
          <View style={[{ display: "flex", flexDirection: "row", marginTop: 5 }]}>
            <Text style={[{ fontFamily: "RobotoBold", fontSize: 10 }]}>Date:</Text>
            <Text style={{ fontFamily: "RobotoRegular", fontSize: 10, marginLeft: 2 }}>{moment(new Date()).format("MM/DD/YYYY")}</Text>
          </View>
        </View>
      </View>

      <View style={[{ marginBottom: 20 }]}>  
        {results &&  results.length > 0 &&
          <View style={{ border: "1px solid #e2e8f0", borderRadius: 16, overflow: "hidden", marginBottom: 20 }}>
            <View style={{ display: "flex", flexDirection: "row", backgroundColor: "#0f172a", color: "#ffffff", overflow: "hidden", borderTopLeftRadius: 16, borderTopRightRadius: 16, }}>
            {header.length > 0 && header.map((item, index) => (
              <View style={{ display: "flex", flexDirection: "row", alignItems: 'center', flexWrap: "wrap", width: "100%", maxWidth: item.width, paddingHorizontal: 6, paddingVertical: 12, justifyContent: item.value === 'status' ? 'flex-end' : item.align === 'right' ? 'flex-end' : item.align === 'center' ? 'center' :'flex-start' }} key={index}>
                <Text style={[{ fontFamily: "RobotoBold", fontSize: 10, color: "#ffffff", textAlign: item.value && item.value === 'status' ?'right':item.align === 'right' ? 'right' : item.align === 'center' ? 'center' : 'left' }]}>{item.name}</Text>
              </View>
            ))}
            </View>
            <View style={{ width: "100%", borderBottomLeftRadius: 16, borderBottomRightRadius: 16, backgroundColor: "white", }}>
              {results.map((row, key) => {
                return (
                  <View key={key} style={{ display: "flex", flexDirection: "row", alignItems: 'center', borderTop: "1px solid #e2e8f0" }} wrap={false}>
                    {row.rowData.length > 0 && (
                      <View style={[{ display: "flex", flexDirection: "row", width:"100%" }]}>
                        {row.rowData.map((item, index) => {
                          const matchedHeader = header[index];
                          if (!matchedHeader) {
                            return null;
                          }
                          return(
                            <View style={{ display: "flex", flexDirection: "row", alignItems: 'center', justifyContent: item.type && item.type === 'status' ? "flex-end" : item.align === 'right' ? 'flex-end' : item.align === 'center' ? 'center' :'flex-start', flexWrap: "wrap", fontSize: 10, width: "100%", maxWidth: matchedHeader.width, paddingHorizontal: 6, paddingVertical: 12, }} key={key + "_" + index} >
                              {item.type && item.type === 'status' ? (
                                <Text key={key + "_" + index} style={{ fontSize: 9, textTransform: "capitalize", color: item?.statusType === 'danger' ? '#ef4444' : item?.statusType === 'success' ? '#22c55e' : item?.statusType === 'info' ? '#f59e0b':'' }}>{item?.statusLabel}</Text>
                              ) : (
                                <Text key={key + "_" + index} style={{ fontSize: 9, textTransform:"capitalize" }}>{item?.data}</Text>
                              )}
                            </View>
                            )
                        })}
                      </View>
                    )}
                </View>
                )
              })}   
            </View>  
          </View>
        }
      </View>
    </Page>
  );
};

export default DashboardReport;
