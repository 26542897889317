import React, { useState, useEffect } from "react";
import { setTitle } from "../helpers/MetaTag";
import DropdownCheckbox from "../components/form/DropdownCheckbox";
import Searching from "../components/Searching";
import NoDataFound from "../components/NoDataFound";
import { Document, PDFDownloadLink } from "@react-pdf/renderer";
import ProfileReport from "./pdf/ProfileReport";
import { useSelector } from "react-redux";
import { API_URL } from "../config/host";

import { postData } from "../services/api";
import { useTranslation } from "react-i18next";
import ReportTableSkeleton from "../components/loader/ReportTableSkeleton";

const IndividualProfile = () => {
  setTitle(
    "Succession Planning Software for your Business with Succession Now | Profile Report"
  );
  const { i18n, t } = useTranslation();
  const user = useSelector((state) => state.auth?.user);
  const [loading, setLoading] = useState(false);
  const [industry, setIndustry] = useState("credit_union");
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [successors, setSuccessors] = useState([]);
  const [positions, setPositions] = useState([]);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    async function positionData() {
      try {
        const result = await postData("position/list", {
          sortQuery: { ordering: 1 },
          type: 1,
          status: 1,
          isStep: 1,
          //isReport : 1,
        });
        if (result.data) {
          setPositions(
            result.data.map((value, key) => {
              return {
                _id: value?._id,
                label: value?.name + " (" + value?.short_name + ")",
                isEnabled: false,
              };
            })
          );
          //setPositions(result.data);
        } else {
          console.log("position list message", result.message);
        }
      } catch (error) {
        console.log("Error position list catch", error.message);
      }
    }
    positionData();
  }, []);

  //useEffect(() => {
  const loadUsers = async (SelectedPosition = []) => {
    try {
      const result = await postData("assign/list", {
        //position : SelectedPosition
      });
      if (result.data) {
        setUsers(
          result.data.map((value, key) => {
            return {
              _id: value?._id,
              label: value?.name,
              isEnabled: false,
            };
          })
        );
      }
    } catch (err) {
      console.log(err.message);
    }
  };
  // loadUsers()
  // }, []);

  const loadSuccessors = async (SelectedPosition = [], SelectedUser = []) => {
    setLoading(true);
    setTotalDataCount(0);
    try {
      const result = await postData("highrated-successor/list", {
        sortQuery: { created_at: 1 },
        position: SelectedPosition,
        created_by: SelectedUser,
        isReport: true,
        is_interim: 2,
      });
      if (result.data) {
        setSuccessors(
          result.data.map((item, key) => {
            if (item.successors_details && item.successors_details.length > 0) {
              let successors_details = item.successors_details.map(
                (value, index) => {
                  return {
                    ...value,
                  };
                }
              );
              return {
                name: item.name,
                position: item.position,
                retirement: item.retirement,
                pos_details: item.pos_details,
                created_retirement_details: item.created_retirement_details,
                successors_details: successors_details,
              };
            } else {
              return [];
            }
          })
        );
      }
      setTotalDataCount(result.data.length);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err.message);
    }
  };

  const positionSelect = async (id, checked) => {
    if (id && id === "all") {
      setPositions(
        positions.map((pos) => {
          if (checked) {
            return { ...pos, isEnabled: checked };
          } else {
            return { ...pos, isEnabled: false };
          }
        })
      );
    } else {
      setPositions(
        positions.map((pos) => {
          if (pos._id === id) {
            return { ...pos, isEnabled: checked };
          } else {
            return pos;
          }
        })
      );
    }
  };

  const userSelect = async (id, checked) => {
    setUsers(
      users.map((usr) => {
        if (usr._id === id) {
          return { ...usr, isEnabled: checked };
        } else {
          return usr;
        }
      })
    );
  };

  useEffect(() => {
    let SelectedPosition = [];
    let SelectedUser = [];
    // eslint-disable-next-line array-callback-return
    if (positions) {
      positions.map((usr) => {
        if (usr.isEnabled) {
          SelectedPosition.push(usr._id);
        }
      });
    }
    if (users) {
      users.map((usr) => {
        if (usr.isEnabled) {
          SelectedUser.push(usr._id);
        }
      });
    }
    if (SelectedPosition.length > 0 || SelectedUser.length > 0) {
      loadSuccessors(SelectedPosition, SelectedUser);
    } else {
      setSuccessors([]);
    }

    // if(positions && SelectedPosition.length > 0){
    //   loadUsers(SelectedPosition);
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [positions, users]);

  useEffect(() => {
    let SelectedPosition = [];
    if (positions) {
      positions.map((usr) => {
        if (usr.isEnabled) {
          SelectedPosition.push(usr._id);
        }
      });
    }
    if (SelectedPosition.length > 0) {
      loadUsers(SelectedPosition);
    }
  }, [positions]);

  const getDownload = (e) => {
    e.preventDefault();
    if (e.target.tagName.toLowerCase() === "a") {
      window.open(e.target.href, "_blank");
    }
  };

  console.log(successors, "successorssuccessorssuccessors");

  return (
    <>
      <div className="relative flex justify-between items-center mb-4 flex-wrap sm:flex-nowrap gap-y-4">
        <div>
          <div className="text-xl md:text-3xl text-slate-800 font-bold">
            {t("profile_report") + " - " + user?.company_name}
          </div>
          <div className="text-sm text-slate-800 font-bold">
            {t("please_make_a_selection_from_the_dropdown_to_generate_report")}
          </div>
        </div>
        <div className="sm:ml-auto flex justify-end items-center">
          {successors?.length > 0 && (
            <button
              type={"button"}
              className={
                "flex justify-center items-center gap-2 bg-teal-500 text-white rounded text-base sm:text-lg h-8 sm:h-10 py-0 px-2 sm:px-3 transition-all duration-200 cursor-pointer"
              }
              onClick={(e) => getDownload(e)}
            >
              <i className={"fa-fw fa-light fa-download"}></i>
              <div
                className={"text-xs sm:text-sm font-medium whitespace-nowrap"}
              >
                <PDFDownloadLink
                  document={
                    <Document>
                      <ProfileReport
                        successors={successors}
                        user={user}
                        companyLogo={API_URL + user?.image}
                      />
                    </Document>
                  }
                  fileName="profile_report.pdf"
                >
                  {({ blob, url, loading, error }) =>
                    loading ? t("loading_document") : t("download_pdf")
                  }
                </PDFDownloadLink>
              </div>
            </button>
          )}
        </div>
      </div>
      <div className="relative flex justify-start items-center mb-4 space-x-2">
        <div className="relative z-20">
          <DropdownCheckbox data={positions} functions={positionSelect} />
        </div>
        {/* <div className="relative z-20">
          <DropdownCheckbox data={users} functions={userSelect} />
        </div> */}
      </div>

      <div className="w-full overflow-auto scroll-smooth scrollbar min-h-[480px]">
        <table className="min-w-max w-full table-auto">
          {/* <thead>
            <tr className="text-white font-bold text-xs leading-none border-b border-slate-200">
              <th className="py-4 px-2 text-left max-w-[240px] sticky -top-6 z-10 bg-slate-900 rounded-tl-md">Potential Successors</th>
              <th className="py-4 px-2 text-left max-w-[240px] sticky -top-6 z-10 bg-slate-900">Senior Management Experience
                <span className="block text-[10px] font-normal mt-1">(Years of Executive Experience)</span>
              </th>
              <th className="py-4 px-2 text-left max-w-[240px] sticky -top-6 z-10 bg-slate-900">{(industry && industry === 'credit_union') ? 'Years with Company' : 'Years with company'}
                <span className="block text-[10px] font-normal mt-1">({(industry && industry === 'credit_union') ? 'Years with Company' : 'Years with company'})</span>
              </th>
              <th className="py-4 px-2 text-left max-w-[240px] sticky -top-6 z-10 bg-slate-900">Education
                <span className="block text-[10px] font-normal mt-1">({(industry && industry === 'credit_union') ? 'Highest level of Education' : 'Specialized schools or courses'})</span>
              </th>
              <th className="py-4 px-2 text-left max-w-[240px] sticky -top-6 z-10 bg-slate-900">Performance
                <span className="block text-[10px] font-normal mt-1">(Recent Performance Review)</span>
              </th>
              <th className="py-4 px-2 text-left max-w-[240px] sticky -top-6 z-10 bg-slate-900">Loyalty
                <span className="block text-[10px] font-normal mt-1">({(industry && industry === 'credit_union') ? 'Loyalty Toward Company' : 'Company'})</span>
              </th>
              <th className="py-4 px-2 text-left max-w-[240px] sticky -top-6 z-10 bg-slate-900">Desire to Advance</th>
              <th className="py-4 px-2 text-left max-w-[240px] sticky -top-6 z-10 bg-slate-900 rounded-tr-md">Retirement Window</th>
            </tr>
          </thead> */}
          <tbody className="text-slate-700 text-sm font-light bg-white divide-y divide-slate-200">
            {Array.isArray(successors) && successors?.length > 0 ? (
              successors.map((row) => {
                return (
                  <>
                    {row.successors_details && (
                      <>
                        <tr className="!border-none bg-slate-300">
                          <td
                            className="py-3 px-2 font-semibold text-sm text-left max-w-[240px] align-top rounded-tl-md"
                            colSpan={3}
                          >
                            {row.position} : {row.name}
                          </td>
                          <td
                            className="py-3 px-2 font-semibold text-sm text-left max-w-[240px] align-top rounded-tr-md"
                            colSpan={8}
                          >
                            {t("retirement_window")} : {row.retirement}
                          </td>
                        </tr>
                      </>
                    )}
                    {row.successors_details && (
                      <tr className="text-white font-bold text-xs leading-none bg-slate-900 !border-none">
                        <th className="py-4 px-2 text-left max-w-[240px] z-10">
                          {t("potential_successors")}
                        </th>
                        <th className="py-4 px-2 text-left max-w-[240px] z-10">
                          {t("senior_management_experience")}
                          <span className="block text-[10px] font-normal mt-1">
                            ({t("years_of_executive_experience")})
                          </span>
                        </th>
                        <th className="py-4 px-2 text-left max-w-[240px] z-10">
                          {industry && industry === "credit_union"
                            ? t("years_with_company")
                            : t("years_with_company")}
                          <span className="block text-[10px] font-normal mt-1">
                            (
                            {industry && industry === "credit_union"
                              ? t("years_with_company")
                              : t("years_with_company")}
                            )
                          </span>
                        </th>
                        <th className="py-4 px-2 text-left max-w-[240px] z-10">
                          {t("education")}
                          <span className="block text-[10px] font-normal mt-1">
                            (
                            {industry && industry === "credit_union"
                              ? t("high_level_of_education")
                              : t("specialized_schools_or_courses")}
                            )
                          </span>
                        </th>
                        <th className="py-4 px-2 text-left max-w-[240px] z-10">
                          {t("performance")}
                          <span className="block text-[10px] font-normal mt-1">
                            ({t("recent_performance_review")})
                          </span>
                        </th>
                        <th className="py-4 px-2 text-left max-w-[240px] z-10">
                          {t("loyalty")}
                          <span className="block text-[10px] font-normal mt-1">
                            (
                            {industry && industry === "credit_union"
                              ? t("loyalty_toward_company")
                              : t("company")}
                            )
                          </span>
                        </th>
                        <th className="py-4 px-2 text-left max-w-[240px] z-10">
                          {t("desire_to_advance")}
                        </th>
                        <th className="py-4 px-2 text-left max-w-[240px] z-10">
                          {t("retirement_window")}
                        </th>
                      </tr>
                    )}
                    {row.successors_details &&
                      row.successors_details.map((item, index) => (
                        <tr className="bg-white" key={index}>
                          <td className="py-3 px-2 font-semibold text-sm text-left max-w-[240px] align-top">
                            {item?.name}
                            <span className="block text-xs font-bold leading-tight text-teal-500">
                              {item?.position_details
                                ? item?.position_details[0]?.short_name
                                : "N/A"}
                            </span>
                            {/* <div className="block">
                                <div className="text-sm font-medium text-slate-600"></div>
                                <div className="text-xs text-slate-400">{(item?.position_details) ? item?.position_details[0]?.short_name : 'N/A'}</div>
                              </div> */}
                          </td>
                          <td className="py-3 px-2 text-left max-w-[240px]">
                            {item?.experience_details &&
                            item?.experience_details.length > 0
                              ? item?.experience_details[0]?.name
                              : "N/A"}
                          </td>
                          <td className="py-3 px-2 text-left max-w-[240px]">
                            {item?.cu_details && item?.cu_details.length > 0
                              ? item?.cu_details[0]?.name
                              : "N/A"}
                          </td>
                          <td className="py-3 px-2 text-left max-w-[240px]">
                            {item?.education_details &&
                            item?.education_details.length > 0
                              ? item?.education_details[0]?.name
                              : "N/A"}
                          </td>
                          <td className="py-3 px-2 text-left max-w-[240px]">
                            {item?.performance_details &&
                            item?.performance_details.length > 0
                              ? item?.performance_details[0]?.name +
                                " " +
                                (item?.performance_details[0].number
                                  ? "(" +
                                    item?.performance_details[0].number +
                                    ")"
                                  : "")
                              : "N/A"}
                          </td>
                          <td
                            className={
                              "py-3 px-2 text-left max-w-[240px] " +
                              (item?.loyalty_details.length > 0 &&
                              item?.loyalty_details[0].isPass
                                ? ""
                                : "text-red-600")
                            }
                          >
                            {item?.loyalty_details &&
                            item?.loyalty_details.length > 0
                              ? item?.loyalty_details[0]?.name
                              : "N/A"}
                          </td>
                          <td
                            className={
                              "py-3 px-2 text-left max-w-[240px] " +
                              (item?.desire_details.length > 0 &&
                              item?.desire_details[0].isPass
                                ? ""
                                : "text-red-600")
                            }
                          >
                            {item?.desire_details &&
                            item?.desire_details.length > 0
                              ? item?.desire_details[0]?.name
                              : "N/A"}
                          </td>
                          <td
                            className={
                              "py-3 px-2 text-left max-w-[240px] " +
                              (item?.retirement_details.length > 0 &&
                              item?.retirement_details[0].isPass
                                ? ""
                                : "text-red-600")
                            }
                          >
                            {item?.retirement_details &&
                            item?.retirement_details.length > 0
                              ? item?.retirement_details[0]?.name
                              : "N/A"}
                          </td>
                        </tr>
                      ))}
                    <tr className="bg-slate-100 !border-none">
                      <td className="py-3 px-2" colSpan={11}></td>
                    </tr>
                  </>
                );
              })
            ) : (
              <tr className="border-b border-slate-200">
                <td className="py-3 px-2 text-left" colSpan={20}>
                  {loading ? (
                    <div className="mt-2 space-y-5">
                      <ReportTableSkeleton />
                      <ReportTableSkeleton />
                    </div>
                  ) : (
                    <NoDataFound
                      label={t(
                        "please_make_a_selection_from_the_dropdown_to_generate_report"
                      )}
                    />
                  )}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default IndividualProfile;
