import React, { useState, useEffect } from "react";
import { setTitle } from "../helpers/MetaTag";
import DropdownCheckbox from "../components/form/DropdownCheckbox";
import Searching from "../components/Searching";
import NoDataFound from "../components/NoDataFound";
import { Document, PDFDownloadLink } from "@react-pdf/renderer";
import RetirementReport from "./pdf/RetirementReport";
import { useSelector } from "react-redux";
import { API_URL } from "../config/host";

import { postData } from "../services/api";
import { useTranslation } from "react-i18next";
import ReportTableSkeleton from "../components/loader/ReportTableSkeleton";
import { groupByNameAndCalculateAverage } from "../helpers";
import SymbolKey from "../components/elements/alert/SymbolKey";
import CompanyRetirementReport from "./pdf/CompanyRetirementReport";

const IndividualCompanyRetirementProfile = () => {
  setTitle("Succession Planning Software for your Business with Succession Now | Retirement Report");
  const { i18n, t } = useTranslation();
  const user = useSelector((state) => state.auth?.user);
  const [loading, setLoading] = useState(false);
  const [successors, setSuccessors] = useState([]);
  const [positions, setPositions] = useState([]);
  const [users, setUsers] = useState([]);
  const [totalDataCount, setTotalDataCount] = useState(0);

  useEffect(() => {
    async function positionData() {
      try {
        const result = await postData("position/list", {
          sortQuery: { ordering: 1 },
          type: 1,
          status: 1,
          isStep: 1,
          //isReport : 1,
        });
        if (result.data) {
          setPositions(
            result.data.map((value, key) => {
              return {
                _id: value?._id,
                label: value?.name + " (" + value?.short_name + ")",
                isEnabled: true,
              };
            })
          );
        } else {
          console.log("position list message", result.message);
        }
      } catch (error) {
        console.log("Error position list catch", error.message);
      }
    }
    positionData();
  }, []);

  const positionSelect = async (id, checked) => {
    if (id && id === "all") {
      setPositions(
        positions.map((pos) => {
          if (checked) {
            return { ...pos, isEnabled: checked };
          } else {
            return { ...pos, isEnabled: false };
          }
        })
      );
    } else {
      setPositions(
        positions.map((pos) => {
          if (pos._id === id) {
            return { ...pos, isEnabled: checked };
          } else {
            return pos;
          }
        })
      );
    }
  };

  useEffect(() => {
    let SelectedPosition = [];
    let SelectedUser = [];
    // eslint-disable-next-line array-callback-return
    if (positions) {
      positions.map((usr) => {
        if (usr.isEnabled) {
          SelectedPosition.push(usr._id);
        }
      });
    }
    if (users) {
      users.map((usr) => {
        if (usr.isEnabled) {
          SelectedUser.push(usr._id);
        }
      });
    }
    if (SelectedPosition.length > 0 || SelectedUser.length > 0) {
      loadSuccessors(SelectedPosition, SelectedUser);
    } else {
      setSuccessors([]);
    }
  }, [positions, users]);

  function groupByRetirementId(data) {
    // Step 1: Identify global duplicates based on the `name` field
    const nameCounts = data.reduce((counts, record) => {
      counts[record.name] = (counts[record.name] || 0) + 1;
      return counts;
    }, {});

    // Step 2: Reduce data by retirement_id
    const grouped = data.reduce((result, record) => {
      const { retirement_id, retirement_name, ordering, creator, name, position_details } = record;

      // Initialize group if it doesn't exist
      if (!result[retirement_id]) {
        result[retirement_id] = {
          retirement_id,
          retirement_name,
          ordering,
          members: [],
        };
      }

      // Check if the name is globally duplicated
      const isDuplicate = nameCounts[name] > 1;

      // Add the member with the isDuplicate flag
      result[retirement_id].members.push({
        creator,
        name,
        position_details,
        isDuplicate,
      });

      return result;
    }, {});

    // Convert grouped object into an array
    return Object.values(grouped);
  }

  const loadSuccessors = async (SelectedPosition = [], SelectedUser = []) => {
    setLoading(true);
    setTotalDataCount(0);
    try {
      const result = await postData("highrated-successor/list", {
        sortQuery: { created_at: 1 },
        position: SelectedPosition,
        created_by: SelectedUser,
        isReport: true,
        is_interim: 2,
      });
      if (result.data) {
        let totalData = [];
        result.data.length &&
          result.data.map((item, key) => {
            if (item.successors_details && item.successors_details.length > 0) {
              item.successors_details.map((value, index) => {
                totalData.push({
                  creator: item.name,
                  name: value.name,
                  position_details: value.position_details,
                  retirement_id: value.retirement_details && value.retirement_details.length > 0 ? value.retirement_details[0]?._id : null,
                  retirement_name: value.retirement_details && value.retirement_details.length > 0 ? value.retirement_details[0]?.name : null,
                  ordering: value.retirement_details && value.retirement_details.length > 0 ? value.retirement_details[0]?.ordering : 0,
                });
              });
            } else {
              return [];
            }
          });

        const commonRecords = groupByRetirementId(totalData);
        setSuccessors(commonRecords);
      }
      setTotalDataCount(result.data.length);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err.message);
    }
  };

  const getDownload = (e) => {
    e.preventDefault();
    if (e.target.tagName.toLowerCase() === "a") {
      window.open(e.target.href, "_blank");
    }
  };

  return (
    <>
      <div className="relative flex justify-between items-center mb-4 flex-wrap sm:flex-nowrap gap-y-4">
        <div>
          <div className="text-xl md:text-3xl text-slate-800 font-bold">{t("company_retirement_profile") + " - " + user?.company_name}</div>
          <div className="text-sm text-slate-800 font-bold">{t("please_make_a_selection_from_the_dropdown_to_generate_report")}</div>
        </div>
        <div className="sm:ml-auto flex justify-end items-center">
          <button
            type={"button"}
            className={"flex justify-center items-center gap-2 bg-teal-500 text-white rounded text-base sm:text-lg h-8 sm:h-10 py-0 px-2 sm:px-3 transition-all duration-200 cursor-pointer"}
            onClick={(e) => getDownload(e)}
          >
            <i className={"fa-fw fa-light fa-download"}></i>
            <div className={"text-xs sm:text-sm font-medium whitespace-nowrap"}>
              <PDFDownloadLink
                document={
                  <Document>
                    <CompanyRetirementReport successors={successors} user={user} companyLogo={API_URL + user?.image} />
                  </Document>
                }
                fileName="company_retirement_report.pdf"
              >
                {({ blob, url, loading, error }) => (loading ? t("loading_document") : t("download_pdf"))}
              </PDFDownloadLink>
            </div>
          </button>
        </div>
      </div>
      <div className="relative flex justify-start items-center mb-4 space-x-2">
        <div className="relative z-20">
          <DropdownCheckbox data={positions} functions={positionSelect} />
        </div>
        {/* <div className="relative z-20">
          <DropdownCheckbox data={users} functions={userSelect} />
        </div> */}
      </div>
      <div className="w-full overflow-auto scroll-smooth scrollbar mb-3">
        <table className="min-w-max w-full table-auto">
          <tbody className="text-slate-700 text-sm font-light bg-slate-300 divide-y divide-slate-200">
            {Array.isArray(successors) && successors?.length > 0 ? (
              successors
                .sort((a, b) => a.ordering - b.ordering)
                .map((row) => {
                  return (
                    <>
                      {row.members && (
                        <>
                          <tr className="bg-slate-100 !border-none">
                            <td className="py-3 px-2" colSpan={11}></td>
                          </tr>
                          <tr className="!border-none">
                            <td className="py-3 px-2 font-semibold text-sm text-left max-w-[240px] bg-slate-300 align-top rounded-t-md" colSpan={11}>
                              {t("retirement_window")}: {row.retirement_name}
                            </td>
                          </tr>
                        </>
                      )}
                      {row.members && (
                        <tr className="text-white font-bold text-sm leading-none bg-slate-900">
                          <th className="py-4 px-2 text-left max-w-[240px] z-10" colSpan={3}>
                            {t("potential_successors")}
                          </th>
                          <th className="py-4 px-2 text-left max-w-[240px] z-10" colSpan={8}>
                            {t("created")}
                          </th>
                        </tr>
                      )}
                      {row.members &&
                        row.members.map((item, index) => (
                          <tr className="bg-white" key={index}>
                            <td className="py-3 px-2 font-semibold text-sm text-left max-w-[240px] align-top" colSpan={3}>
                              {item?.name}
                              {item?.isDuplicate && (
                                <sup className="text-[8px] text-red-500 ml-0.5">
                                  <i className="fa-solid fa-asterisk"></i>
                                </sup>
                              )}
                              <span className="block text-xs font-bold leading-tight text-teal-500">{item?.position_details.length > 0 ? item?.position_details[0]?.short_name : "N/A"}</span>
                            </td>
                            <td className={"py-3 px-2 text-left max-w-[240px] "} colSpan={8}>
                              {item?.creator}
                            </td>
                          </tr>
                        ))}
                    </>
                  );
                })
            ) : (
              <tr className="">
                {loading ? (
                  <td className="p-0" colSpan={20}>
                    <div className="space-y-5">
                      <ReportTableSkeleton />
                      <ReportTableSkeleton />
                    </div>
                  </td>
                ) : (
                  <td className="p-0" colSpan={20}>
                    <NoDataFound label={t("please_make_a_selection_from_the_dropdown_to_generate_report")} />
                  </td>
                )}
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {!loading && successors?.length > 0 && (
        <SymbolKey sign={"star"} message={"This name appears multiple times throughout the company succession plans."} />
      )}
    </>
  );
};

export default IndividualCompanyRetirementProfile;
