import React from "react";
import { Document, Page, Text, View, StyleSheet, Image, Font } from "@react-pdf/renderer";
import logo from "../../logo-succession-now.png";
import Shape from "../../shape.png";
import moment from "moment/moment";

import { useTranslation } from "react-i18next";

import RobotoLight from "../../assets/font/Roboto-Light.ttf";
import RobotoRegular from "../../assets/font/Roboto-Regular.ttf";
import RobotoItalic from "../../assets/font/Roboto-Italic.ttf";
import RobotoMedium from "../../assets/font/Roboto-Medium.ttf";
import RobotoBold from "../../assets/font/Roboto-Bold.ttf";

Font.register({ family: "RobotoLight", src: RobotoLight });
Font.register({ family: "RobotoRegular", src: RobotoRegular });
Font.register({ family: "RobotoItalic", src: RobotoItalic });
Font.register({ family: "RobotoMedium", src: RobotoMedium });
Font.register({ family: "RobotoBold", src: RobotoBold });

const DevelopmentIndividualReport = ({ successors, user, companyLogo }) => {
  const { i18n, t } = useTranslation();
  const styles = StyleSheet.create({
    page: {
      padding: 20,
      fontFamily: "RobotoRegular",
      lineHeight: 1.2,
      fontSize: 9,
      color: "#334155",
    },
    tableHeading: {
      fontSize: 8,
      width: "33.33%",
      paddingHorizontal: 8,
      paddingVertical: 8,
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
    },
    subText: {
      fontSize: 8,
      marginTop: 2,
    },
    tableData: {
      fontSize: 9,
      width: "33.33%",
      padding: 4,
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
    },
    tableText: {
      fontSize: 7,
    },
    image: {
      width: 200,
      height: 40,
      objectFit: "contain",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    tableHeadingText: {
      width: "100%",
      fontFamily: "RobotoRegular",
      fontSize: 10,
      lineHeight: 1,
    },
    imageSignature: {
      width: 100,
      height: 60,
      objectFit: "contain",
    },
    tablePart: {
      display: "flex",
      flexDirection: "row",
      overflow: "hidden",
    },
    section: { textAlign: "center" },
  });
  return (
    <Page
      size="A4"
      orientation="landscape"
      style={{
        padding: 20,
        fontFamily: "RobotoRegular",
        lineHeight: 1.2,
        fontSize: 9,
        color: "#334155",
        backgroundColor: "#f1f5f9",
        position: "relative",
        zIndex: 0,
      }}
    >
      <View
        style={{
          position: "absolute",
          left: 0,
          top: 0,
          right: 0,
          bottom: 0,
          zIndex: 0,
        }}
        fixed
      >
        <View
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            right: 0,
            width: "100%",
            height: 160,
            zIndex: -1,
            opacity: 0.1,
          }}
        >
          <Image style={{ objectFit: "cover" }} src={Shape} />
        </View>
        <View
          style={{
            position: "absolute",
            width: "100%",
            left: 0,
            bottom: -10,
            right: 0,
            height: 160,
            zIndex: -1,
            opacity: 0.075,
          }}
        >
          <Image style={{ objectFit: "contain" }} src={logo} />
        </View>
      </View>

      <View style={{ position: "absolute", right: 30, top: 30 }}>
        <Image style={{ width: 120, height: 24, objectFit: "contain" }} src={logo} />
      </View>

      <View style={{ paddingBottom: 20, width: "100%", margin: "0 auto", display: "flex", flexDirection: "column", alignItems: "center" }}>
        <View
          style={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            fontSize: 16,
            alignItems: "center",
            position: "relative",
            width: "100%",
          }}
        >
          <Image
            style={{
              width: 200,
              height: 40,
              objectFit: "contain",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            src={companyLogo}
          />
        </View>
        <View style={[{ display: "flex", width: "100%", flexDirection: "row", justifyContent: "center", marginTop: 15 }]}>
          <Text style={[{ fontFamily: "RobotoBold", fontSize: 20, textTransform: "capitalize", textAlign: "center" }]}>Company Name: {user?.company_name}</Text>
        </View>

        <View style={[{ display: "flex", width: "100%", flexDirection: "row", justifyContent: "center", marginTop: 15 }]}>
          <Text
            style={{
              fontFamily: "RobotoBold",
              fontSize: 12,
              textTransform: "uppercase",
              color: "#14b8a6",
              letterSpacing: 3,
              textAlign: "center",
            }}
          >
            {t("development_plan_report")}
          </Text>
        </View>
        <View style={[{ display: "flex", width: "100%", flexDirection: "row", marginTop: 5, textAlign: "center", justifyContent: "center" }]}>
          <View style={[{ display: "flex", flexDirection: "row", marginTop: 5, textAlign: "center", justifyContent: "center", marginRight: 12 }]}>
            <Text style={[{ fontFamily: "RobotoBold", fontSize: 10, textAlign: "center" }]}>Created by:</Text>
            <Text style={{ fontFamily: "RobotoRegular", fontSize: 10, marginLeft: 2 }}>
              {user?.name}({user?.title})
            </Text>
          </View>
          <View style={[{ display: "flex", flexDirection: "row", marginTop: 5 }]}>
            <Text style={[{ fontFamily: "RobotoBold", fontSize: 10 }]}>Date:</Text>
            <Text style={{ fontFamily: "RobotoRegular", fontSize: 10, marginLeft: 2 }}>{moment(new Date()).format("MM/DD/YYYY")}</Text>
          </View>
        </View>
      </View>
      {successors &&
        successors.length > 0 &&
        successors.map((value, idx) => {
          return (
            <>
              <View style={[{ marginTop: 10, marginBottom: 10 }]} key={idx}>
                <View style={[{ display: "flex", flexDirection: "column", marginTop: 5, alignItems: "center" }]}>
                  <Text style={[{ display: "flex", flexDirection: "row", fontFamily: "RobotoBold", fontSize: 13, marginBottom: 10 }]}>{value?.name}</Text>
                </View>
                {value.successors_details &&
                  value.successors_details.length > 0 &&
                  value.successors_details.map((row, key) => (
                    <View style={{ width: "100%" }} wrap={false} key={key}>
                      <View style={[{ display: "flex", flexDirection: "row", columnGap: 20, marginTop: 5, marginBottom: 5 }]}>
                        <Text style={[{ fontFamily: "RobotoBold", fontSize: 10 }]}>
                          {row.name}
                          {row?.position_details[0]?.name !== undefined && (
                            <Text style={[{ fontFamily: "RobotoRegular", fontSize: 10 }]}>{row?.position_details ? " (" + row?.position_details[0]?.name + ")" : ""}</Text>
                          )}
                        </Text>
                      </View>
                      <View style={{ border: "1px solid #e2e8f0", borderRadius: 16, overflow: "hidden", marginBottom: 20 }}>
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            backgroundColor: "#0f172a",
                            color: "#ffffff",
                            overflow: "hidden",
                            borderTopLeftRadius: 16,
                            borderTopRightRadius: 16,
                          }}
                        >
                          <View
                            style={[
                              {
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: "wrap",
                                fontFamily: "RobotoBold",
                                fontSize: 12,
                                color: "#ffffff",
                                width: "33.33%",
                                maxWidth: "100%",
                                paddingHorizontal: 12,
                                paddingVertical: 12,
                              },
                            ]}
                          >
                            <Text style={[{ width: "100%", fontFamily: "RobotoBold", fontSize: 10 }]}>Needed Skills & Abilities</Text>
                          </View>
                          <View
                            style={[
                              {
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: "wrap",
                                fontFamily: "RobotoBold",
                                fontSize: 12,
                                color: "#ffffff",
                                width: "33.33%",
                                maxWidth: "100%",
                                paddingHorizontal: 12,
                                paddingVertical: 12,
                              },
                            ]}
                          >
                            <Text style={[{ width: "100%", fontFamily: "RobotoBold", fontSize: 10 }]}>Needed Experience or Seasoning</Text>
                          </View>
                          <View
                            style={[
                              {
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: "wrap",
                                fontFamily: "RobotoBold",
                                fontSize: 12,
                                color: "#ffffff",
                                width: "33.33%",
                                maxWidth: "100%",
                                paddingHorizontal: 12,
                                paddingVertical: 12,
                              },
                            ]}
                          >
                            <Text style={[{ width: "100%", fontFamily: "RobotoBold", fontSize: 10 }]}>Needed Education</Text>
                          </View>
                        </View>
                        <View
                          style={{
                            width: "100%",
                            borderBottomLeftRadius: 16,
                            borderBottomRightRadius: 16,
                            backgroundColor: "white",
                          }}
                        >
                          {((row.plan_skills && row.plan_skills.length > 0) ||
                            (row.plan_experiences && row.plan_experiences.length > 0) ||
                            (row.plan_educations && row.plan_educations.length > 0)) && (
                            <View style={{ display: "flex", flexDirection: "row", borderTop: "1px solid #e2e8f0" }}>
                              <>
                                <View
                                  style={[{ display: "flex", flexDirection: "row", flexWrap: "wrap", fontSize: 10, width: "33.33%", maxWidth: "100%", paddingHorizontal: 12, paddingVertical: 12 }]}
                                  key={key}
                                >
                                  {row.plan_skills && row.plan_skills.length > 0 && (
                                    <>
                                      {row.plan_skills.map((skill_item, skill_index) => (
                                        <View style={skill_index === 0 ? { fontSize: 9 } : { borderTop: "1px solid #e2e8f0", marginTop: "8px", paddingTop: "8px" }} key={key + "_" + skill_index}>
                                          <Text key={key + "_" + skill_index} style={{ fontSize: 9 }}>
                                            {skill_item}
                                          </Text>
                                        </View>
                                      ))}
                                    </>
                                  )}
                                </View>
                                <View
                                  style={[{ display: "flex", flexDirection: "row", flexWrap: "wrap", fontSize: 10, width: "33.33%", maxWidth: "100%", paddingHorizontal: 12, paddingVertical: 12 }]}
                                  key={key}
                                >
                                  {row.plan_experiences && row.plan_experiences.length > 0 && (
                                    <>
                                      {row.plan_experiences.map((experience_item, experience_index) => (
                                        <View
                                          style={experience_index === 0 ? { fontSize: 9 } : { borderTop: "1px solid #e2e8f0", marginTop: "8px", paddingTop: "8px" }}
                                          key={key + "_" + experience_index}
                                        >
                                          <Text key={key + "_" + experience_index} style={{ fontSize: 9 }}>
                                            {experience_item}
                                          </Text>
                                        </View>
                                      ))}
                                    </>
                                  )}
                                </View>
                                <View
                                  style={[{ display: "flex", flexDirection: "row", flexWrap: "wrap", fontSize: 10, width: "33.33%", maxWidth: "100%", paddingHorizontal: 12, paddingVertical: 12 }]}
                                  key={key}
                                >
                                  {row.plan_educations && row.plan_educations.length > 0 && (
                                    <>
                                      {row.plan_educations.map((education_item, education_index) => (
                                        <View
                                          style={education_index === 0 ? { display: "flex", fontSize: 9 } : { display: "flex", borderTop: "1px solid #e2e8f0", marginTop: "8px", paddingTop: "8px" }}
                                          key={key + "_" + education_index}
                                        >
                                          <Text key={key + "_" + education_index} style={{ fontSize: 9 }}>
                                            {education_item}
                                          </Text>
                                        </View>
                                      ))}
                                    </>
                                  )}
                                </View>
                              </>
                            </View>
                          )}
                          <View style={{ display: "flex", flexDirection: "row", borderTop: "1px solid #e2e8f0" }}>
                            <View style={[{ display: "flex", flexDirection: "row", flexWrap: "wrap", fontSize: 10, width: "33.33%", maxWidth: "100%", paddingHorizontal: 12, paddingVertical: 12 }]}>
                              <View style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                <Text style={{ fontSize: 9, fontFamily: "RobotoMedium" }}>Timeframe:</Text>
                                <Text style={{ fontSize: 9 }}>
                                  {row?.plan_skills_timeframe_details && row?.plan_skills_timeframe_details.length > 0 ? row?.plan_skills_timeframe_details[0]?.name : "N/A"}
                                </Text>
                              </View>
                            </View>
                            <View style={[{ display: "flex", flexDirection: "row", flexWrap: "wrap", fontSize: 10, width: "33.33%", maxWidth: "100%", paddingHorizontal: 12, paddingVertical: 12 }]}>
                              <View style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                <Text style={{ fontSize: 9, fontFamily: "RobotoMedium" }}>Timeframe:</Text>
                                <Text style={{ fontSize: 9 }}>
                                  {row?.plan_experiences_timeframe_details && row?.plan_experiences_timeframe_details.length > 0 ? row?.plan_experiences_timeframe_details[0]?.name : "N/A"}
                                </Text>
                              </View>
                            </View>
                            <View style={[{ display: "flex", flexDirection: "row", flexWrap: "wrap", fontSize: 10, width: "33.33%", maxWidth: "100%", paddingHorizontal: 12, paddingVertical: 12 }]}>
                              <View style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                <Text style={{ fontSize: 9, fontFamily: "RobotoMedium" }}>Timeframe:</Text>
                                <Text style={{ fontSize: 9 }}>
                                  {row?.plan_educations_timeframe_details && row?.plan_educations_timeframe_details.length > 0 ? row?.plan_educations_timeframe_details[0]?.name : "N/A"}
                                </Text>
                              </View>
                            </View>
                          </View>
                        </View>
                      </View>
                    </View>
                  ))}
              </View>
            </>
          );
        })}
    </Page>
  );
};

export default DevelopmentIndividualReport;
