import React, { useState, useEffect } from "react";
import { setTitle } from "../helpers/MetaTag";
import Button from "../components/form/Button";
import Input from "../components/form/Input";
import ReactPaginate from "react-paginate";
import toastr from "toastr";
import { sumTotal } from "../helpers";
import { postData } from "../services/api";
import AlertPopup from "../components/popup/AlertPopup";
import HeadingManagePopup from "../components/popup/HeadingManagePopup";
import Searching from "../components/Searching";
import NoDataFound from "../components/NoDataFound";
import { useSelector } from "react-redux";

import { useTranslation } from "react-i18next";
import TableSkeleton from "../components/loader/TableSkeleton";
import { Tooltip } from "react-tooltip";

const PredictorHeadingAlt = () => {
  setTitle("Succession Planning Software for your Business with Succession Now | Predictors Manage");
  const { i18n, t } = useTranslation();
  const role = localStorage.getItem("role");
  const user = useSelector((state) => state.auth?.user);
  const [loading, setLoading] = useState(true);
  const [keyword, setKeyword] = useState("");
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [sortKey, setSortKey] = useState("created_at");
  const [sortValue, setSortValue] = useState(1);
  const [sortQuery, setSortQuery] = useState("");
  const [noOfPage, setNoOfPage] = useState(0);
  const [predictorHeadings, setPredictorHeadings] = useState([]);
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [actionValue, setActionValue] = useState({});
  const [isUpdate, setIsUpdate] = useState(false);
  const [weightValue, setWeightValue] = useState([]);
  const [weightTotalValue, setWeightTotalValue] = useState(0);
  const [isSubmit, setIssubmit] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [enabledAlertPopup, setEnabledAlertPopup] = useState(false);
  const othersAction = {
    type: "button",
    link: "/",
    label: t("edit"),
    icon: "fa-pen-to-square",
  };
  const [enabledPopup, setEnabledPopup] = useState(false);
  const [editData, setEditData] = useState({});

  useEffect(() => {
    if (sortKey && sortValue) {
      setSortQuery({ [sortKey]: sortValue });
    } else {
      setSortQuery("");
    }
  }, [sortKey, sortValue]);

  useEffect(() => {
    const loadPosition = async () => {
      setLoading(true);
      try {
        const result = await postData("heading/list", {
          keyword: keyword,
          limit: limit,
          offset: offset,
          sortQuery: { created_at: 1 },
          status: 1,
          type: "predictors",
        });
        if (result.data) {
          setPredictorHeadings(result.data);
          setWeightTotalValue(sumTotal(result.data, "company_weight"));
          setWeightValue(
            result.data.map((value, key) => {
              return {
                id: value._id,
                value: value.company_weight ? value.company_weight : value.weight,
              };
            })
          );
        }
        setTotalDataCount(result.count);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err.message);
      }
    };
    loadPosition();
  }, [limit, offset, actionValue]);

  useEffect(() => {
    setNoOfPage(Math.ceil(totalDataCount / limit));
  }, [limit, totalDataCount]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * limit) % totalDataCount;
    console.log(`User requested page number ${event.selected}, which is offset ${newOffset}`);
    setOffset(newOffset);
  };

  // useEffect(() => {
  //   if (actionValue && actionValue.button_type === "others") {
  //     setenabledPopup(true);
  //     setHeading("Edit Weight");
  //     const index = predictorHeadings.findIndex(
  //       (item) => item._id === actionValue?.row_id
  //     );
  //     if (index !== -1) {
  //       //console.log(predictorHeadings)
  //       setEditData({
  //         ...actionValue,
  //         name: predictorHeadings[index].rowData[0].data,
  //         weight: predictorHeadings[index].rowData[1].data,
  //         _id: predictorHeadings[index]._id,
  //       });
  //     } else {
  //       setEditData(actionValue);
  //     }
  //   }
  // }, [actionValue]);

  const handelUpdate = async (id, index, value) => {
    setWeightValue(
      weightValue.map((it, idx) => {
        if (idx === index) {
          return {
            ...it,
            value: +value,
          };
        } else {
          return it;
        }
      })
    );
  };

  useEffect(() => {
    if (sumTotal(weightValue, "value") === 100) {
      setWeightTotalValue(sumTotal(weightValue, "value"));
      setIssubmit(true);
    } else {
      setWeightTotalValue(sumTotal(weightValue, "value"));
      setIssubmit(false);
    }
  }, [weightValue]);

  //useEffect(() => {
  const onSubmit = async (e) => {
    setIsAdd(true);
    try {
      const result = await postData("predictors/update", {
        weightValue: weightValue,
      });
      if (result.status && result.status === 200) {
        toastr.success("Changes made here affect all company succession plans");
        setIsAdd(false);
        setEnabledAlertPopup(false);
      } else {
        toastr.error(result.message);
        setIsAdd(false);
      }
    } catch (error) {
      toastr.error(error.message);
      setIsAdd(false);
    }
  };
  // if (actionValue && actionValue.button_type === "confirm") {
  //   onSubmit();
  // }
  // }, [actionValue]);

  return (
    <>
      <div className="relative flex justify-between items-center mb-4 flex-wrap sm:flex-nowrap gap-y-4">
        <div className="text-xl md:text-3xl text-slate-800 font-bold">{t("edit_predictors_weight") + " - " + user?.company_name}</div>
        <div className="sm:ml-auto flex justify-end items-center space-x-4">
          {isSubmit ? (
            <Button
              buttonClasses=""
              buttonLabelClasses=""
              buttonType={"button"}
              buttonIcon={isAdd ? "fa-light fa-spinner fa-spin" : "fa-light fa-check"}
              buttonIconPosition={"left"}
              buttonLabel={t("save")}
              functions={
                () => onSubmit()
                //setEnabledAlertPopup(true)
              }
              buttonHasLink={false}
              buttonDisabled={isAdd}
            />
          ) : (
            ""
          )}
          <div
            className={
              weightTotalValue === 100
                ? "text-xs sm:text-sm bg-teal-500 text-white flex justify-center items-center px-4 h-8 sm:h-10 rounded"
                : "text-xs sm:text-sm bg-rose-500 text-white flex justify-center items-center px-4 h-8 sm:h-10 rounded"
            }
          >
            {t("weight")}: {weightTotalValue}%
          </div>
        </div>
      </div>

      <div className="w-full overflow-auto scroll-smooth scrollbar bg-white rounded-md shadow min-h-[480px]">
        <table className="min-w-max w-full table-auto">
          <thead>
            <tr className="text-white font-bold text-sm leading-none border-b border-slate-200">
              <th className="py-4 px-2 text-left max-w-[240px] sticky -top-6 z-10 bg-slate-900 rounded-tl-md">{t("factor")}</th>
              <th className="py-4 px-2 text-left max-w-[240px] sticky -top-6 z-10 bg-slate-900">{t("description")}</th>
              <th className="py-4 px-2 text-left max-w-[240px] sticky -top-6 z-10 bg-slate-900">{t("weight")}</th>
              <th className="py-4 px-2 text-left max-w-[240px] sticky -top-6 z-10 bg-slate-900 rounded-tr-md">{t("action")}</th>
            </tr>
          </thead>
          <tbody className="text-slate-700 text-sm font-light bg-white">
            {totalDataCount > 0 ? (
              predictorHeadings.map((item, index) => (
                <tr className="border-b border-slate-200" key={index}>
                  <td className="py-3 px-2 text-left max-w-[240px]">
                    <div className="block">
                      <div className="text-sm font-semibold text-slate-600">{item?.heading_name ? item?.heading_name : item?.name}</div>
                    </div>
                  </td>
                  <td className="py-3 px-2 text-left max-w-[240px]">
                    <div className="block">
                      <div className="text-xs text-slate-400">{item?.heading_description ? item?.heading_description : item?.description}</div>
                    </div>
                  </td>
                  <td className="py-3 px-2 text-left max-w-[240px]">
                    <Input
                      labelClasses={"!text-xs"}
                      inputClasses={"!text-red-500 font-semibold"}
                      inputType={"number"}
                      inputPlaceholder={"Enter Weight"}
                      inputValue={weightValue[index].value || ""}
                      setInput={(val) => handelUpdate(item._id, index, val)}
                    />
                  </td>
                  <td className="py-3 px-2 text-left max-w-[240px]">
                    <div className="" data-tooltip-id={`editButton${item?._id}`} data-tooltip-place="right">
                      <Button
                        buttonClasses={"!p-0 !flex !items-center !justify-center !text-[14px] !bg-transparent !text-dark-teal-600 !w-8 !h-8 !font-bold"}
                        buttonType={"button"}
                        buttonIcon={"fa-regular fa-pencil"}
                        buttonIconPosition={"left"}
                        buttonHasLink={false}
                        functions={() => {
                          setEnabledPopup(true);
                          setEditData(item);
                        }}
                      />
                      <Tooltip
                        id={`editButton${item?._id}`}
                        className="!text-xs !bg-dark-teal-600 z-50 rounded py-3 px-6"
                        render={() => (
                          <div className="gap-x-1 gap-y-1">
                            <div className="text-[13px] font-normal leading-none flex items-center">
                              <div className="font-semibold">{`Click To Edit Predictors Weight (${item?.heading_name ? item?.heading_name : item?.name})`}</div>
                            </div>
                          </div>
                        )}
                      />
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr className="border-b border-slate-200">
                <td className="py-3 px-2 text-left" colSpan={4}>
                  {loading ? <TableSkeleton tableImage={false} /> : <NoDataFound label={t("no_data_found")} />}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {noOfPage > 1 && (
        <div className="flex justify-center sm:justify-end my-4">
          <ReactPaginate
            breakLabel="..."
            breakClassName="w-8 h-8 flex justify-center items-center rounded hover:bg-slate-200"
            nextLabel={<i className="fa-regular fa-fw fa-chevron-right"></i>}
            nextClassName="w-8 h-8 flex justify-center items-center rounded hover:bg-slate-200"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            pageCount={noOfPage}
            containerClassName="flex justify-center items-center text-slate-80 text-sm font-medium mt-8 lg:mt-0 space-x-2"
            activeClassName="hover:bg-teal-500 bg-teal-500 text-white"
            pageLinkClassName="flex justify-center items-center w-full h-full"
            previousLabel={<i className="fa-regular fa-fw fa-chevron-left"></i>}
            pageClassName="w-8 h-8 flex justify-center items-center rounded hover:bg-slate-200"
            previousClassName="w-8 h-8 flex justify-center items-center rounded hover:bg-slate-200"
            renderOnZeroPageCount={null}
          />
        </div>
      )}
      {enabledAlertPopup && (
        <AlertPopup
          isOpen={enabledAlertPopup}
          setIsOpen={(val) => setEnabledAlertPopup(val)}
          getActionValue={(obj) => {
            setActionValue(obj);
          }}
        ></AlertPopup>
      )}
      {enabledPopup && (
        <HeadingManagePopup
          isOpen={enabledPopup}
          setIsOpen={(val) => setEnabledPopup(val)}
          data={editData}
          getActionValue={(obj) => {
            setActionValue(obj);
          }}
          type={"predictors"}
        ></HeadingManagePopup>
      )}
    </>
  );
};

export default PredictorHeadingAlt;
