import React from "react";
import { Page, Text, View, Image, Font } from "@react-pdf/renderer";
import logo from "../../logo-succession-now.png";
import Shape from "../../shape.png";
import moment from "moment/moment";

import { useTranslation } from "react-i18next";

import RobotoLight from "../../assets/font/Roboto-Light.ttf";
import RobotoRegular from "../../assets/font/Roboto-Regular.ttf";
import RobotoMedium from "../../assets/font/Roboto-Medium.ttf";
import RobotoBold from "../../assets/font/Roboto-Bold.ttf";

Font.register({ family: "RobotoLight", src: RobotoLight });
Font.register({ family: "RobotoRegular", src: RobotoRegular });
Font.register({ family: "RobotoMedium", src: RobotoMedium });
Font.register({ family: "RobotoBold", src: RobotoBold });

const SalesTeamReport = ({ results,header, user }) => {
  const { i18n,t } = useTranslation();   

  return (
    <Page size="A4" orientation="landscape" style={{ padding: 16, fontFamily: "RobotoRegular", lineHeight: 1.2, fontSize: 9, color: "#334155", backgroundColor: "#f1f5f9", }}>
      <View style={{ position: "absolute", left: 0, top: 0, right: 0, bottom: 0, zIndex: 0, }} fixed>
        <View style={{ position: "absolute", left: 0, top: 0, right: 0, width: "100%", height: 160, zIndex: -1, opacity: 0.1, }}>
          <Image style={{ objectFit: "cover" }} src={Shape} />
        </View>
        <View style={{ position: "absolute", width: "100%", left: 0, bottom: -10, right: 0, height: 160, zIndex: -1, opacity: 0.075, }}>
          <Image style={{ objectFit: "contain" }} src={logo} />
        </View>
      </View>
      <View style={{ paddingBottom: 20, width: "100%", margin: "0 auto", display: "flex", flexDirection: "column", alignItems: "center" }}>
        <View style={{ textAlign: "center", display: "flex", flexDirection: "column", justifyContent: "center", fontSize: 16, }} >
          <Image style={{ width: 200, height: 40, objectFit: "contain", display: "flex", alignItems: "center", justifyContent: "center" }} src={logo} />
        </View>
        <View style={[{ display: "flex", width: "100%", flexDirection: "row", justifyContent: "center", marginTop: 15 }]}>
          <Text style={{ fontFamily: "RobotoBold", fontSize: 12, textTransform: "uppercase", color: "#14b8a6", letterSpacing: 3, textAlign: "center", }}>{t("subscription_report")}</Text>
        </View>
        <View style={[{ display: "flex", width: "100%", flexDirection: "row", marginTop: 5, textAlign: "center", justifyContent: "center" }]}>
          <View style={[{ display: "flex", flexDirection: "row", marginTop: 5, textAlign: "center", justifyContent: "center", marginRight: 12 }]}>
            <Text style={[{ fontFamily: "RobotoBold", fontSize: 10, textAlign: "center" }]}>Created by:</Text>
            <Text style={{ fontFamily: "RobotoRegular", fontSize: 10, marginLeft: 2 }}>{user?.name}</Text>
          </View>
          <View style={[{ display: "flex", flexDirection: "row", marginTop: 5 }]}>
            <Text style={[{ fontFamily: "RobotoBold", fontSize: 10 }]}>Date:</Text>
            <Text style={{ fontFamily: "RobotoRegular", fontSize: 10, marginLeft: 2 }}>{moment(new Date()).format("MM/DD/YYYY")}</Text>
          </View>
        </View>
      </View>
      
      {
        results && results.length > 0 &&   
        <View style={[{ marginBottom: 20 }]}>        
          <View style={{ border: "1px solid #e2e8f0", borderRadius: 16, overflow: "hidden", marginBottom: 20 }}>
            <View style={{ display: "flex", flexDirection: "row", alignItems: 'center', backgroundColor: "#0f172a", color: "#ffffff", overflow: "hidden", borderTopLeftRadius: 16, borderTopRightRadius: 16, }}>
              {header.length>0 && header.map((item,index) =>(
                <View style={{ display: "flex", flexDirection: "row", alignItems: 'center', flexWrap: "wrap", width: item.width, maxWidth: "100%", paddingHorizontal: 6, paddingVertical: 12, justifyContent: item.align === 'right' ? 'flex-end' : item.align === 'center' ? 'center' :'flex-start' }} key={index}>
                <Text style={[{ fontFamily: "RobotoBold", fontSize: 10, color: "#ffffff", wordBreak: "break-word", textAlign: item.align === 'right' ? 'flex-end' : item.align === 'center' ? 'center' : 'flex-start' }]}>{item.name}</Text>
                </View>
              ))}
            </View>
            <View style={{ width: "100%", borderBottomLeftRadius: 16, borderBottomRightRadius: 16, backgroundColor: "white", }}>
              {results.map((item,index) => {  
                return (
                  <View key={index} style={{ display: "flex", flexDirection: "row", alignItems: 'center', borderTop: "1px solid #e2e8f0" }} wrap={false}>
                    <View style={{ display: "flex", flexDirection: "row", alignItems:'left', justifyContent:"flex-start", flexWrap: "wrap", fontSize: 10, width: "10%", maxWidth: "100%", paddingHorizontal: 6, paddingVertical: 12, }}>
                      <Text style={{ fontSize: 9 }}>{(item?.created_at)? moment(new Date(item?.created_at)).format("MMM Do YYYY"):'N/A'}</Text>
                    </View> 
                    <View style={{ display: "flex", flexDirection: "row", alignItems:'left', justifyContent:"flex-start", flexWrap: "wrap", fontSize: 10, width: "15%", maxWidth: "100%", paddingHorizontal: 6, paddingVertical: 12, }}>
                      <Text style={{ fontSize: 9 }}>{item?.company_name}</Text>
                    </View> 
                    <View style={{ display: "flex", flexDirection: "row", alignItems:'left', justifyContent:"flex-start", flexWrap: "wrap", fontSize: 10, width: "15%", maxWidth: "100%", paddingHorizontal: 6, paddingVertical: 12, }}>
                      <Text style={{ fontSize: 9 }}>{(item?.salesperson)?item?.salesperson : ""}</Text>
                    </View> 
                    <View style={{ display: "flex", flexDirection: "row", alignItems:'center', justifyContent:"center", flexWrap: "wrap", fontSize: 10, width: "10%", maxWidth: "100%", paddingHorizontal: 6, paddingVertical: 12, }}>
                      <Text style={{ fontSize: 9 }}>{(item?.invoice_send && item?.invoice_send === 1)?t('yes') : t('no')}</Text>
                    </View> 
                    <View style={{ display: "flex", flexDirection: "row", alignItems:'center', justifyContent:"center", flexWrap: "wrap", fontSize: 10, width: "10%", maxWidth: "100%", paddingHorizontal: 6, paddingVertical: 12, }}>
                      <Text style={{ fontSize: 9 }}>{(item?.purchase_agreement_received && item?.purchase_agreement_received === 1)?t('yes') : t('no')}</Text>
                    </View> 
                    <View style={{ display: "flex", flexDirection: "row", alignItems:'center', justifyContent:"center", flexWrap: "wrap", fontSize: 10, width: "10%", maxWidth: "100%", paddingHorizontal: 6, paddingVertical: 12, }}>
                      <Text style={{ fontSize: 9 }}>{(item?.account_setup && item?.account_setup === 1)?t('yes') : t('no')}</Text>
                    </View> 
                    <View style={{ display: "flex", flexDirection: "row", alignItems:'center', justifyContent:"center", flexWrap: "wrap", fontSize: 10, width: "10%", maxWidth: "100%", paddingHorizontal: 6, paddingVertical: 12, }}>
                      <Text style={{ fontSize: 9 }}>{(item?.payment_received && item?.payment_received === 1)?t('yes') : t('no')}</Text>
                    </View> 
                    <View style={{ display: "flex", flexDirection: "row", alignItems:'center', justifyContent:"center", flexWrap: "wrap", fontSize: 10, width: "10%", maxWidth: "100%", paddingHorizontal: 6, paddingVertical: 12, }}>
                      <Text style={{ fontSize: 9 }}>{(item?.traning_schedule && item?.traning_schedule === 1)?t('yes') : t('no')}</Text>
                    </View> 
                    <View style={{ display: "flex", flexDirection: "row", alignItems:'center', justifyContent:"center", flexWrap: "wrap", fontSize: 10, width: "10%", maxWidth: "100%", paddingHorizontal: 6, paddingVertical: 12, }}>
                      <Text style={{ fontSize: 9 }}>{(item?.traning_complete && item?.traning_complete === 1)?t('yes') : t('no')}</Text>
                    </View>
                  </View>
                )
              })}   
            </View>              
          </View>
        </View>
      }
    </Page>
  )
};
export default SalesTeamReport;