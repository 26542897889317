import React, { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { usePopper } from "react-popper";
import { useRef, useState } from "react";
import { bottom, top, left, right } from "@popperjs/core";
import { classNames } from "../../../helper/classNames";
import Search from "../../form/Search";
import Checkbox from "../../form/Checkbox";
import Radio from "../../form/Radio";
import Button from "../../form/Button";
import useClickOutside from "../../../hooks/useClickOutside";

const TableFilter = ({
  xPlacement,
  dropdownContainer,
  isFilterSearch,
  filterData = [],
  onFilter = () => {},
  filterSearch = () => {},
  totalDataCount,
  ...props
}) => {
  const popperElRef = useRef(null);
  const [targetElement, setTargetElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [selected, setSelected] = useState([]);
  const [open, setOpen] = useState(false);

  const placements = {
    bottom: bottom,
    bottomLeft: "bottom-start",
    bottomRight: "bottom-end",
    top: top,
    topLeft: "top-start",
    topRight: "top-end",
    right: right,
    left: left,
    "": bottom,
  };

  const { styles, attributes } = usePopper(targetElement, popperElement, {
    placement: placements[xPlacement],
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 8],
        },
      },
    ],
  });

  useClickOutside(popperElRef, () => {
    setOpen(false);
  });

  const isAnySelected = selected.length > 0;

  return (
    <>
      <Menu as="div">
        {({}) => (
          <>
            <Menu.Button
              ref={setTargetElement}
              className="flex items-center "
              onClick={() => setOpen(!open)}
            >
              <div
                className={classNames(
                  "relative flex items-center justify-center text-sm w-5 h-5 focus:ring-0 transition-all duration-200",
                  isAnySelected ? "text-sky-400 gap-1" : "text-white"
                )}
              >
                <i className="fa-solid fa-fw fa-filter-list"></i>
                {selected.length > 0 && (
                  <div className="text-[12px] font-bold text-dark-teal-300 flex items-center">
                    <span className="relative">
                      {totalDataCount || 0}
                      <sup
                        className="text-xs absolute text-rose-600 -top-2 -right-2"
                        onClick={() => {
                          setSelected([]);
                          onFilter([]);
                        }}
                      >
                        x
                      </sup>
                    </span>
                  </div>
                )}
              </div>
            </Menu.Button>
            <Transition
              show={open}
              leave="transition duration-75"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              className="z-50"
              beforeEnter={() => setPopperElement(popperElRef.current)}
              afterLeave={() => setPopperElement(null)}
              ref={popperElRef}
              style={styles.popper}
              {...attributes.popper}
            >
              <Menu.Items className="w-40 origin-top-right bg-white divide-y divide-slate-100 rounded-md shadow ring-0 border-0">
                {isFilterSearch && (
                  <>
                    <div className="py-2 px-3">
                      <Search
                        placeholder={"Search"}
                        iconClasses={""}
                        classes={"font-normal"}
                        onSearch={(e) => filterSearch(e)}
                      />
                    </div>
                  </>
                )}

                <div className="py-3 max-h-60 divide-y divide-slate-100 overflow-auto scroll-smooth scrollbar">
                  <div
                    onClick={() => {
                      const isAllSelected =
                        selected?.length === filterData?.length;
                      const updatedSelection = isAllSelected ? [] : filterData;
                      setSelected(updatedSelection);
                      onFilter(updatedSelection);
                    }}
                    className={classNames(
                      "cursor-pointer font-normal",
                      selected?.length === filterData?.length
                        ? "bg-slate-100 hover:bg-slate-200"
                        : "hover:bg-slate-100"
                    )}
                  >
                    <div className="relative py-2 px-3 hover:bg-slate-100 transition-all duration-200">
                      <Checkbox
                        checkboxName={"filter_checkbox"}
                        checkboxInputclassName={""}
                        isChecked={selected?.length === filterData?.length}
                        checkboxLableClass={"!font-normal !text-xs"}
                        checkboxLabel={"All"}
                      />
                    </div>
                  </div>
                  {filterData.map((item, index) => (
                    <div
                      key={index?.toString()}
                      onClick={() => {
                        const isSelected = selected
                          ?.map((selectedItem) => selectedItem?.value)
                          ?.includes(item?.value);
                        const updatedSelection = isSelected
                          ? selected.filter(
                              (s_item) => s_item?.value !== item?.value
                            )
                          : [...selected, item];
                        setSelected(updatedSelection);
                        onFilter(updatedSelection);
                      }}
                      className={classNames(
                        "cursor-pointer font-normal",
                        selected?.some((data) => data?.value === item?.value)
                          ? "bg-slate-100 hover:bg-slate-200"
                          : "hover:bg-slate-100"
                      )}
                    >
                      {item.type === "checkbox" && (
                        <div className="relative py-2 px-3 hover:bg-slate-100 transition-all duration-200">
                          <Checkbox
                            checkboxName={"filter_checkbox"}
                            checkboxInputclassName={""}
                            isChecked={selected?.some(
                              (data) => data?.value === item?.value
                            )}
                            checkboxLabel={item.name}
                            checkboxLableClass={"font-normal text-xs"}
                          />
                        </div>
                      )}
                      {/* {item.type === "radio" && (
                        <div className="relative py-2 px-3 hover:bg-slate-100 transition-all duration-200">
                          <Radio
                            radioName={"filter_radio"}
                            radioInputclassName={""}
                            isChecked={item.isChecked}
                            radioLabel={item.name}
                            radioLableclassName={"font-normal text-xs"}
                          />
                        </div>
                      )} */}
                    </div>
                  ))}
                </div>
                {/* <div className="flex justify-end gap-2 pb-3 pr-3">
                  <Button
                    buttonLabel={"Reset"}
                    buttonType="button"
                    functions={() => {
                      setSelected([]);
                      onFilter([]);
                    }}
                    buttonClasses={
                      "!w-14 !h-8 !text-sm !font-normal !bg-rose-500 !text-white hover:!bg-rose-500/80"
                    }
                  />

                  <Button
                    buttonLabel={"Ok"}
                    buttonType="button"
                    functions={() => {
                      if (selected?.length > 0) {
                        onFilter(selected);
                        setOpen(false);
                      } else {
                        onFilter(null);
                        setOpen(false);
                      }
                    }}
                    buttonClasses={
                      "!w-14 !h-8 !text-sm !font-normal  hover:!bg-dark-teal-500/80"
                    }
                  />
                </div> */}
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
    </>
  );
};

export default TableFilter;
