import React from "react";
import { Link } from "react-router-dom";
import Image from "../elements/Image";
import { classNames } from "../../helper/classNames";

const Button = ({
  buttonTarget,
  buttonClasses,
  buttonType,
  buttonIcon,
  buttonIconPosition,
  buttonLabel,
  buttonLabelClasses,
  buttonHasLink,
  buttonLink,
  functions,
  buttonDisabled,
  tooltipContent = "",
  isImage = false,
  imageData,
  isCustomLabel = false,
  customData,
  ...props
}) => {
  return (
    <>
      {buttonHasLink ? (
        <Link
          to={buttonLink}
          className={
            "flex justify-center items-center gap-2 bg-teal-500 text-white rounded text-base sm:text-lg h-8 sm:h-10 py-0 px-2 sm:px-3 transition-all duration-200 " +
            buttonClasses
          }
          data-tooltip-id="button-tooltip"
          data-tooltip-content={tooltipContent}
          target={buttonTarget}
        >
          {buttonIconPosition === "left" && (
            <i className={"fa-fw " + buttonIcon}></i>
          )}
          {buttonLabel && (
            <div
              className={
                "text-xs sm:text-sm font-medium whitespace-nowrap " +
                buttonLabelClasses
              }
            >
              {buttonLabel}
            </div>
          )}
          {buttonIconPosition === "right" && (
            <i className={"fa-fw " + buttonIcon}></i>
          )}
        </Link>
      ) : (
        <button
          type={buttonType}
          className={
            "flex justify-center items-center gap-2 bg-teal-500 text-white rounded text-base sm:text-lg h-8 sm:h-10 py-0 px-2 sm:px-3 transition-all duration-200 cursor-pointer " +
            buttonClasses
          }
          onClick={functions}
          disabled={buttonDisabled}
          data-tooltip-id="button-tooltip"
          data-tooltip-content={tooltipContent}
        >
          {buttonIconPosition === "left" && (
            <i className={"fa-fw " + buttonIcon}></i>
          )}
          {!isCustomLabel && buttonLabel && isImage && (
            <div className="flex justify-center items-center gap-2">
              <div className="w-6 h-6">
                <Image
                  src={imageData}
                  alt={"image"}
                  className={classNames("w-10 h-10 ")}
                />
              </div>
              <div
                className={
                  "text-xs sm:text-sm font-medium whitespace-nowrap " +
                  buttonLabelClasses
                }
              >
                {buttonLabel}
              </div>
            </div>
          )}
          {!isCustomLabel && !isImage && buttonLabel && (
            <div
              className={
                "text-xs sm:text-sm font-medium whitespace-nowrap " +
                buttonLabelClasses
              }
            >
              {buttonLabel}
            </div>
          )}
          {isCustomLabel && customData}
          {buttonIconPosition === "right" && (
            <i className={"fa-fw " + buttonIcon}></i>
          )}
        </button>
      )}
    </>
  );
};

export default Button;
