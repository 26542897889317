import React from "react";
import Searching from "../Searching";
import NoDataFound from "../NoDataFound";
import { useTranslation } from "react-i18next";
import { classNames } from "../../helper/classNames";

const CardTable = ({ data, loading, title, newDesign = false }) => {
  const { i18n, t } = useTranslation();
  return (
    <div className={classNames(newDesign ? "flex w-full" : "mb-4")}>
      <div className={classNames("w-full min-h-[200px] bg-white scroll-smooth scrollbar overflow-auto", newDesign ? "rounded-lg py-4 px-4 shadow-md" : "rounded shadow")}>
        <div className="w-full scroll-smooth scrollbar overflow-auto md:overflow-visible">
          <table className="w-full table-auto">
            <thead>
              <tr className={classNames("font-bold leading-none border-b border-slate-200", newDesign ? "bg-white text-slate-800 uppercase" : "bg-slate-900 text-white text-sm")}>
                <th className={classNames(" px-2 text-left max-w-[240px] z-10", newDesign ? "rounded-tl-lg py-3" : "py-4 rounded-tl-md")}>{title}</th>
                <th className={classNames("px-2 text-left max-w-[240px] z-10", newDesign ? "rounded-tr-lg py-3" : "py-4 rounded-tr-md")}>&nbsp;</th>
              </tr>
            </thead>
            <tbody className="text-slate-700 text-sm font-light bg-white">
              {data && data.length > 0 ? (
                data?.map((item, index) => (
                  <tr className={"border-b border-slate-200"}>
                    <td className={"py-3 px-2 text-left max-w-[240px]"}>
                      <div className="block">
                        <div className="text-sm font-medium text-slate-600">{item?.name}</div>
                      </div>
                    </td>
                    <td className={"py-3 px-2 text-right max-w-[240px]"}>
                      <div className="block">
                        <div className="text-sm font-medium text-slate-600">{item?.point}</div>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr className="border-b border-slate-200">
                  <td className="py-3 px-2 text-left" colSpan={20}>
                    {loading ? <Searching label={"Searching"} /> : <NoDataFound label={"No Data Found"} />}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default CardTable;
