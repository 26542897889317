import React, { useState, useRef, useEffect, Fragment } from "react";
import toastr from "toastr";
import { useNavigate, useParams } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import { postData } from "../../services/api";
import { allowedImageExtensions } from "../../helpers";
import ButtonFile from "../form/ButtonFile";
import Image from "../elements/Image";
import Input from "../form/Input";
import Checkbox from "../form/Checkbox";
import Button from "../form/Button";
import { setTitle } from "../../helpers/MetaTag";
import NoDataFound from "../NoDataFound";
import Searching from "../Searching";
import ChangeEmailPopup from "./ChangeEmailPopup";

const AddEditStaffPopup = ({ isOpen, setIsOpen = () => {}, data, loadList = () => {}, id }) => {
  if (id) {
    setTitle("Succession Planning Software for your Business with Succession Now | Edit Staff");
  } else {
    setTitle("Succession Planning Software for your Business with Succession Now | Add Staff");
  }
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const editorRef = useRef(null);

  const [details, setDetails] = useState({});
  const [image, setImage] = useState("");
  const [isManage, setIsManage] = useState(false);
  const [showLogo, setShowLogo] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [commission, setCommission] = useState("");
  const [salesCommission, setSalesCommission] = useState();
  const [consultantCommission, setConsultantCommission] = useState();
  const [appointmentSetterCommission, setAppointmentSetterCommission] = useState();
  const [salesAdminCommission, setSalesAdminCommission] = useState();
  const [position, setPosition] = useState("");
  const [roles, setRoles] = useState([]);
  const [enabledEmailPopup, setEnabledEmailPopup] = useState(false);
  const [currentId, setCurrentId] = useState("");
  const [actionValue, setActionValue] = useState({});

  const staffCommissionArray = [
    {
      code: "sales-staff",
      commission: salesCommission,
    },
    {
      code: "consultant",
      commission: consultantCommission,
    },
    {
      code: "appointment-setter",
      commission: appointmentSetterCommission,
    },
    {
      code: "sales-admin",
      commission: salesAdminCommission,
    },
  ];

  useEffect(() => {
    // Plan details
    const loadDetails = async () => {
      try {
        const result = await postData("usertype/details", {
          id: id,
        });
        if (result.data) {
          setDetails(result.data);
        }
      } catch (err) {
        console.log(err.message);
      }
    };
    loadDetails();
  }, [id]);

  useEffect(() => {
    const loadRoles = async () => {
      try {
        const result = await postData("role/list", {
          sortQuery: { ordering: 1 },
          codes: ["master-admin", "consultant", "sales-admin", "sales-staff", "appointment-setter"],
        });
        if (result.data) {
          setRoles(result.data);
        }
      } catch (err) {
        console.log(err.message);
      }
    };
    loadRoles();
  }, []);

  const onSubmit = async () => {
    setIsManage(true);
    try {
      let path = "";
      let payload = "";
      if (id) {
        path = "usertype/modify";
        payload = {
          id: id,
          first_name: firstName,
          last_name: lastName,
          email: email,
          phone: phone,
          //image: image,
          commission: staffCommissionArray,
          password: password,
          confirm_password: confirmPassword,
          role: position,
        };
      } else {
        path = "usertype/store";
        payload = {
          first_name: firstName,
          last_name: lastName,
          email: email,
          phone: phone,
          //image: image,
          commission: staffCommissionArray,
          password: password,
          confirm_password: confirmPassword,
          role: position,
        };
      }
      const res = await postData(path, payload);
      if (res.status && res.status === 200) {
        toastr.success(res.message);
        // navigate("/salesstaff");
        loadList();
        setIsOpen({ open: false, _id: null });
      } else {
        toastr.error(res.message);
      }
    } catch (err) {
      console.log(err.messege);
    }
    setIsManage(false);
  };

  useEffect(() => {
    if (actionValue && actionValue.button_type === "edit") {
      setEmail(actionValue.email);
    }
  }, [actionValue]);

  useEffect(() => {
    setFirstName(details?.first_name);
    setLastName(details?.last_name);
    setEmail(details?.email);
    //setCommission(details?.commission);
    setPosition(details?.role);
    setPhone(details?.phone);
    if (details.commission && details.commission.length > 0) {
      let sales = details?.commission.filter((item) => item.code === "sales-staff");
      let consultant = details?.commission.filter((item) => item.code === "consultant");
      let appointmentSetter = details?.commission.filter((item) => item.code === "appointment-setter");
      let salesAdmin = details?.commission.filter((item) => item.code === "sales-admin");
      if (sales && sales.length > 0) {
        setSalesCommission(sales[0].commission ? sales[0].commission : 0);
      }
      if (consultant && consultant.length > 0) {
        setConsultantCommission(consultant[0].commission ? consultant[0].commission : 0);
      }
      if (appointmentSetter && appointmentSetter.length > 0) {
        setAppointmentSetterCommission(appointmentSetter[0].commission ? appointmentSetter[0].commission : 0);
      }
      if (salesAdmin && salesAdmin.length > 0) {
        setSalesAdminCommission(salesAdmin[0].commission ? salesAdmin[0].commission : 0);
      }
    }
    setCurrentId(id);
  }, [details]);
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={() => {}}>
        <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-3xl transform rounded-2xl bg-white p-4 text-left align-middle shadow-xl transition-all">
                <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900 text-center">
                  {id ? t("edit_staff") : t("add_staff")}
                </Dialog.Title>
                <div className="absolute top-2 right-2 z-10">
                  <Button
                    buttonLabelClasses=""
                    buttonType={"button"}
                    buttonIcon={"fa-light fa-times"}
                    buttonIconPosition={"left"}
                    buttonLabel={""}
                    buttonClasses={"!bg-transparent !text-slate-700 !w-10 !h-10 !p-0"}
                    functions={() => {
                      setIsOpen({ _id: null, open: false });
                      setDetails({});
                      setFirstName("");
                      setLastName("");
                      setEmail("");
                      setPosition("");
                      setPhone("");
                      setSalesCommission("");
                      setConsultantCommission("");
                      setAppointmentSetterCommission("");
                      setSalesAdminCommission("");
                    }}
                    buttonHasLink={false}
                  />
                </div>
                <div className="relative mt-4 bg-white">
                  <div className="grid grid-cols-12 gap-4">
                    <div className="col-span-12 flex flex-col">
                      <div className="space-y-4">
                        <div className="grid sm:grid-cols-2 gap-4">
                          <div className="relative w-full">
                            <Input
                              label={t("staff_first_name")}
                              labelClasses={"!text-xs"}
                              inputType={"input"}
                              inputPlaceholder={t("enter_first_name")}
                              inputValue={firstName}
                              setInput={setFirstName}
                              requiredDisplay={true}
                            />
                          </div>
                          <div className="relative w-full">
                            <Input
                              label={t("staff_last_name")}
                              labelClasses={"!text-xs"}
                              inputType={"input"}
                              inputPlaceholder={t("enter_last_name")}
                              inputValue={lastName}
                              setInput={setLastName}
                              requiredDisplay={true}
                            />
                          </div>
                        </div>
                        <div className="grid sm:grid-cols-2 gap-4">
                          <div className="relative w-full">
                            <div className="flex items-center justify-between">
                              <div className={"text-xs font-medium text-slate-500 mb-1"}>
                                {t("staff_email")}
                                <span className="text-[8px] text-red-500 ml-0.5">
                                  <i className="fa-solid fa-asterisk"></i>
                                </span>
                              </div>
                              {id && (
                                <Button
                                  buttonClasses={"!p-0 !flex !items-center !justify-center !text-[10px] !bg-transparent !text-slate-400 !w-4 !h-4"}
                                  buttonType={"button"}
                                  buttonIcon={"fa-regular fa-pencil"}
                                  buttonIconPosition={"left"}
                                  buttonHasLink={false}
                                  functions={() => setEnabledEmailPopup(true)}
                                />
                              )}
                            </div>
                            <Input
                              label={""}
                              labelClasses={"!text-xs"}
                              inputType={"email"}
                              inputPlaceholder={t("staff_email_address")}
                              inputValue={email}
                              setInput={setEmail}
                              isDisabled={id ? true : false}
                              requiredDisplay={true}
                            />
                          </div>
                          <div className="relative w-full">
                            <Input
                              label={t("staff_phone_number")}
                              labelClasses={"!text-xs"}
                              inputType={"text"}
                              inputPlaceholder={t("staff_phone_number")}
                              inputValue={phone}
                              setInput={setPhone}
                              requiredDisplay={true}
                            />
                          </div>
                        </div>
                        <div className="grid sm:grid-cols-2 gap-4">
                          <div className="relative w-full">
                            <Input
                              label={t("sales_commission") + " (%)"}
                              labelClasses={"!text-xs"}
                              inputType={"number"}
                              inputPlaceholder={t("enter_sales_commission")}
                              inputValue={salesCommission}
                              setInput={setSalesCommission}
                            />
                          </div>
                          <div className="relative w-full">
                            <Input
                              label={t("consultant_commission") + " (%)"}
                              labelClasses={"!text-xs"}
                              inputType={"number"}
                              inputPlaceholder={t("enter_consultant_commission")}
                              inputValue={consultantCommission}
                              setInput={setConsultantCommission}
                            />
                          </div>
                          <div className="relative w-full">
                            <Input
                              label={t("appointment_setter_commission") + " (%)"}
                              labelClasses={"!text-xs"}
                              inputType={"number"}
                              inputPlaceholder={t("enter_appointment_setter_commission")}
                              inputValue={appointmentSetterCommission}
                              setInput={setAppointmentSetterCommission}
                            />
                          </div>
                          <div className="relative w-full">
                            <Input
                              label={t("sales_admin_commission") + " (%)"}
                              labelClasses={"!text-xs"}
                              inputType={"number"}
                              inputPlaceholder={t("enter_sales_admin_commission")}
                              inputValue={salesAdminCommission}
                              setInput={setSalesAdminCommission}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="flex justify-end gap-4 mt-10">
                        <Button
                          buttonClasses={"bg-white border border-slate-300 !text-slate-400 ml-2"}
                          buttonLabelClasses=""
                          buttonType={"button"}
                          buttonIcon={"fa-regular fa-xmark"}
                          buttonIconPosition={"left"}
                          buttonLabel={t("cancel")}
                          functions={() => {
                            setIsOpen({ _id: null, open: false });
                            setDetails({});
                            setFirstName("");
                            setLastName("");
                            setEmail("");
                            setPosition("");
                            setPhone("");
                            setSalesCommission("");
                            setConsultantCommission("");
                            setAppointmentSetterCommission("");
                            setSalesAdminCommission("");
                          }}
                          buttonHasLink={false}
                        />
                        <Button
                          buttonClasses=""
                          buttonLabelClasses=""
                          buttonType={"button"}
                          buttonIcon={isManage ? "fa-light fa-spinner fa-spin" : "fa-regular fa-floppy-disk"}
                          buttonIconPosition={"left"}
                          buttonLabel={t("save")}
                          functions={onSubmit}
                          buttonHasLink={false}
                          buttonDisabled={isManage}
                        />
                      </div>
                    </div>
                  </div>
                  {enabledEmailPopup && (
                    <ChangeEmailPopup
                      isOpen={enabledEmailPopup}
                      setIsOpen={(val) => setEnabledEmailPopup(val)}
                      data={currentId}
                      getActionValue={(obj) => {
                        setActionValue(obj);
                        setEnabledEmailPopup(false);
                        setCurrentId("");
                      }}
                      message={""}
                    />
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default AddEditStaffPopup;
