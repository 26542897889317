import React, { useState, useEffect } from "react";
import { setTitle } from "../../helpers/MetaTag";
import Button from "../../components/form/Button";
import Select from "../../components/form/Select";
import Search from "../../components/form/Search";
import Table from "../../components/elements/table/Table";
import moment from "moment";
import ReactPaginate from "react-paginate";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { reorder, currencyFormat } from "../../helpers";
import toastr from "toastr";
import { useSelector } from "react-redux";

import { postData } from "../../services/api";
import { useTranslation } from "react-i18next";
import Pagination from "../../components/Common/Pagination";
import { Tooltip } from "react-tooltip";
import { getStatusFilter } from "../../constant/Constant";

const Pricing = ({}) => {
  setTitle("Succession Planning Software for your Business with Succession Now | Plans & Pricing");
  const { i18n, t } = useTranslation();
  const status_filter = getStatusFilter(t);
  const role = localStorage.getItem("role");
  const user = useSelector((state) => state.auth?.user);
  const [loading, setLoading] = useState(true);
  const [keyword, setKeyword] = useState("");
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [sortKey, setSortKey] = useState("created_at");
  const [sortValue, setSortValue] = useState(-1);
  const [sortQuery, setSortQuery] = useState("");
  const [status, setStatus] = useState("");
  const [noOfPage, setNoOfPage] = useState(0);
  const [plans, setPlans] = useState([]);
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [startDataCount, setStartDataCount] = useState(0);
  const [endDataCount, setEndDataCount] = useState(0);
  const [actionValue, setActionValue] = useState({});
  const [isUpdate, setIsUpdate] = useState(false);
  const [inputAttr, setInputAttr] = useState({});
  const [isView, setIsView] = useState(1);

  useEffect(() => {
    const loadPlans = async () => {
      setLoading(true);
      try {
        const result = await postData("plans/list", {
          keyword: keyword,
          limit: limit,
          offset: offset,
          sortQuery: sortQuery,
          status: status,
          isView: isView,
        });
        if (result.data) {
          setPlans(
            result.data.map((value, key) => {
              return {
                _id: value?._id,
                rowData: [
                  { _id: 1, width: 40, type: "text", data: value?.name },
                  {
                    _id: 2,
                    width: 15,
                    type: "text",
                    data: currencyFormat(value?.price),
                  },
                  {
                    _id: 3,
                    width: 15,
                    type: "text",
                    data: value?.interval && value?.interval === "year" ? t("annual") : value?.interval,
                  },
                  {
                    _id: 4,
                    width: 20,
                    type: "text",
                    data: value && value?.created_at ? moment(new Date(value?.created_at)).format("ll") : "N/A",
                  },
                  {
                    _id: 5,
                    width: 15,
                    type: "status",
                    statusLabel: value?.status && value?.status === 1 ? t("active") : t("deactivated"),
                    statusType: value?.status && value?.status === 1 ? "success" : "warning",
                  },
                  {
                    _id: 6,
                    width: 10,
                    type: "action",
                    statusLabel: value?.status && value?.status === 1 ? t("active") : t("deactivated"),
                    statusType: value?.status && value?.status === 1 ? "success" : "warning",
                  },
                ],
              };
            })
          );
        }
        setTotalDataCount(result.count);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err.message);
      }
    };
    loadPlans();
  }, [keyword, limit, offset, sortQuery, status, isView, isUpdate]);

  useEffect(() => {
    setNoOfPage(Math.ceil(totalDataCount / limit));
    const startItem = Number(totalDataCount > 0 ? 1 : 0);
    const endItem = Math.min(startItem + limit - 1, totalDataCount);
    setStartDataCount(startItem);
    setEndDataCount(endItem);
  }, [limit, totalDataCount]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * limit) % totalDataCount;
    console.log(`User requested page number ${event.selected}, which is offset ${newOffset}`);
    setOffset(newOffset);
    const startItem = Number(newOffset + 1);
    const endItem = Math.min(startItem + limit - 1, totalDataCount);
    setStartDataCount(startItem);
    setEndDataCount(endItem);
  };

  const tableHeadData = [
    {
      _id: 1,
      width: 40,
      name: t("name"),
      value: "name",
      align: "left",
      isSort: false,
      isFilter: false,
    },
    {
      _id: 2,
      width: 15,
      name: t("price"),
      value: "price",
      align: "left",
      isSort: false,
      isFilter: false,
    },
    {
      _id: 3,
      width: 15,
      name: t("interval"),
      value: "interval",
      align: "left",
      isSort: false,
      isFilter: false,
    },
    {
      _id: 4,
      width: 20,
      name: t("created"),
      value: "created_at",
      align: "left",
      isSort: false,
      isFilter: false,
    },
    {
      _id: 5,
      width: 15,
      name: t("status"),
      value: "status",
      align: "left",
      isSort: false,
      isFilter: true,
      filterData: status_filter || [],
      onFilter: (data) => {
        if (data && Array.isArray(data) && data.length === 1) {
          setStatus(data[0]?.value);
        } else {
          setStatus("");
        }
        setOffset(0);
      },
    },
    {
      _id: 6,
      width: 10,
      name: t("action"),
      value: "",
      align: "right",
      isSort: false,
      isFilter: false,
    },
  ];

  const handleOnDragEnd = async (result) => {
    const items = reorder(plans, result.source.index, result.destination.index);
    setPlans(items);

    const res = await postData("position/ordering", {
      items: items,
    });
    if (res.status && res.status === 200) {
      toastr.success(res.message);
    } else {
      toastr.error(res.message);
    }
  };

  useEffect(() => {
    if (actionValue && actionValue.button_type === "status") {
      const loadStatusChange = async () => {
        setIsUpdate(false);
        try {
          const statusData = await postData("plan/status-change", {
            id: actionValue?.row_id,
            status: actionValue?.current_status,
          });
          if (statusData.data) {
            setPlans(
              plans.map((it) => {
                if (it?._id === statusData.data?._id) {
                  return {
                    ...it,
                    status: statusData.data?.status,
                  };
                } else {
                  return it;
                }
              })
            );
            setIsUpdate(true);
          } else {
            toastr.error(statusData.message);
          }
        } catch (error) {
          toastr.error(error.message);
        }
      };
      loadStatusChange();
    } else if (actionValue && actionValue.button_type === "delete") {
      const loadDeleteRecord = async () => {
        setIsUpdate(false);
        try {
          const statusData = await postData("plan/delete", {
            id: actionValue?.row_id,
          });
          if (statusData.data) {
            const index = plans.findIndex((item) => item._id === actionValue?.row_id);
            if (index !== -1) {
              plans.splice(index, 1);
              setPlans(
                plans.map((it) => {
                  return it;
                })
              );
            }
            setIsUpdate(true);
          } else {
            toastr.error(statusData.message);
          }
        } catch (error) {
          toastr.error(error.message);
        }
      };
      loadDeleteRecord();
    }
    //console.log('actionValue',actionValue)
  }, [actionValue]);

  // useEffect(() => {
  //   if(
  //     user.permission &&
  //     user.permission.filter((item) => item === 'pricing_edit').length > 0
  //   ){
  //     setInputAttr({...inputAttr, editUrl : '/edit-plan'})
  //   }
  // }, [user])

  return (
    <div className="border bg-white border-gray-200 p-4 shadow-md rounded-xl">
      <div className="relative flex justify-between items-center mb-3">
        <div className="text-2xl font-bold">{t("plans_and_pricing")}</div>
        {role && (role === "master-admin" || (user.permission && user.permission.filter((item) => item === "pricing_create").length > 0)) && (
          <div className="sm:ml-auto flex justify-end items-center" data-tooltip-id={`button${1}`} data-tooltip-place="right">
            <Button buttonType={"button"} buttonIcon={"fa-light fa-plus"} buttonIconPosition={"left"} buttonLabel={t("add")} buttonHasLink={true} buttonLink={"/add-plan"} />
            <Tooltip
              id={`button${1}`}
              className="!text-xs !bg-teal-500 z-50 rounded py-2 px-2"
              render={() => (
                <div className="gap-x-1 gap-y-1">
                  <div className="text-[13px] font-normal leading-none flex items-center">
                    <div className="font-semibold">Add New Plan and Pricing</div>
                  </div>
                </div>
              )}
            />
          </div>
        )}
      </div>
      <div className="relative flex gap-3 items-center mb-4 flex-wrap sm:flex-nowrap gap-y-2">
        <div className="relative">
          <Select
            xPlacement={"left"}
            dropdownButtonClass={"!bg-white"}
            selectedValue={limit}
            dropdownData={[
              { _id: 1000000, label: t("all_items"), value: 1000000 },
              ...[
                { _id: 10, label: "10 " + t("items"), value: 10 },
                { _id: 20, label: "20 " + t("items"), value: 20 },
                { _id: 30, label: "30 " + t("items"), value: 30 },
                { _id: 50, label: "50 " + t("items"), value: 50 },
                { _id: 100, label: "100 " + t("items"), value: 100 },
              ],
            ]}
            getSelectedValue={(e) => setLimit(e.value)}
          />
        </div>
        <div className="relative w-48">
          <Select
            xPlacement={"right"}
            dropdownButtonClass={"!bg-white"}
            dropdownClass="w-48"
            transitionClass={"!w-auto"}
            selectedValue={isView}
            dropdownData={[
              { _id: "", label: "Select" },
              { _id: 1, label: t("purchase_plan"), value: 1 },
              { _id: 2, label: t("no_of_employee"), value: 2 },
            ]}
            getSelectedValue={(e) => setIsView(e.value)}
          />
        </div>
      </div>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <div className="w-full bg-white rounded-md shadow overflow-hidden">
          <Table
            tableData={plans}
            tableHeadData={tableHeadData}
            totalRecord={totalDataCount}
            loading={loading}
            getSortValue={setSortValue}
            getSortKey={setSortKey}
            {...(role && (role === "master-admin" || (user.permission && user.permission.filter((item) => item === "pricing_edit").length > 0)) ? (isView === 1 ? { editUrl: "/edit-plan" } : "") : "")}
            isStatusUpdate={true}
            isDeleteEnabled={false}
            isDraggable={false}
            getActionValue={(obj) => setActionValue(obj)}
            tableImage={false}
            tableClasses={"min-h-[400px]"}
            // {...inputAttr}
          />
        </div>
      </DragDropContext>
      {noOfPage > 1 ? (
        <Pagination handlePageClick={handlePageClick} noOfPage={noOfPage} startDataCount={startDataCount} endDataCount={endDataCount} count={totalDataCount} />
      ) : (
        <div className="flex justify-center sm:justify-between my-4 text-sm text-black text-center sm:text-left">
          {totalDataCount > 0 ? (
            <div>
              <p>
                {t("showing")}: {startDataCount} to {endDataCount} of {totalDataCount}
              </p>
            </div>
          ) : (
            ""
          )}
        </div>
      )}
    </div>
  );
};
export default Pricing;
