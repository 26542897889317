import React from "react";
import { Page, Text, View, Image, Font } from "@react-pdf/renderer";
import logo from "../../logo-succession-now.png";
import Shape from "../../shape.png";
import moment from "moment/moment";

import RobotoLight from "../../assets/font/Roboto-Light.ttf";
import RobotoRegular from "../../assets/font/Roboto-Regular.ttf";
import RobotoItalic from "../../assets/font/Roboto-Italic.ttf";
import RobotoMedium from "../../assets/font/Roboto-Medium.ttf";
import RobotoBold from "../../assets/font/Roboto-Bold.ttf";

Font.register({ family: "RobotoLight", src: RobotoLight });
Font.register({ family: "RobotoRegular", src: RobotoRegular });
Font.register({ family: "RobotoItalic", src: RobotoItalic });
Font.register({ family: "RobotoMedium", src: RobotoMedium });
Font.register({ family: "RobotoBold", src: RobotoBold });

const CompetenciesReport = ({ data, user, companyLogo }) => {
  return (
    <Page
      size="A4"
      orientation="landscape"
      style={{
        padding: 20,
        fontFamily: "RobotoRegular",
        lineHeight: 1.2,
        fontSize: 9,
        color: "#334155",
        backgroundColor: "#f1f5f9",
      }}
    >
      <View
        style={{
          position: "absolute",
          left: 0,
          top: 0,
          right: 0,
          bottom: 0,
          zIndex: 0,
        }}
        fixed
      >
        <View
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            right: 0,
            width: "100%",
            height: 160,
            zIndex: -1,
            opacity: 0.1,
          }}
        >
          <Image style={{ objectFit: "cover" }} src={Shape} />
        </View>
        <View
          style={{
            position: "absolute",
            width: "100%",
            left: 0,
            bottom: -10,
            right: 0,
            height: 160,
            zIndex: -1,
            opacity: 0.075,
          }}
        >
          <Image style={{ objectFit: "contain" }} src={logo} />
        </View>
      </View>
      <View style={{ position: "absolute", right: 30, top: 30 }}>
        <Image style={{ width: 120, height: 24, objectFit: "contain" }} src={logo} />
      </View>
      <View style={{ paddingBottom: 20, width: "100%", margin: "0 auto", display: "flex", flexDirection: "column", alignItems: "center" }}>
        <View
          style={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            fontSize: 16,
            alignItems: "center",
            position: "relative",
            width: "100%",
          }}
        >
          <Image
            style={{
              width: 200,
              height: 40,
              objectFit: "contain",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            src={companyLogo}
          />
        </View>
        <View style={{ display: "flex", flexDirection: "row", marginTop: 15 }}>
          <Text style={{ fontFamily: "RobotoBold", fontSize: 20, textTransform: "capitalize" }}>Company Name: {user?.company_name}</Text>
        </View>
        <View style={[{ display: "flex", flexDirection: "row", marginTop: 15 }]}>
          <Text
            style={{
              fontFamily: "RobotoBold",
              fontSize: 12,
              textTransform: "uppercase",
              color: "#14b8a6",
              letterSpacing: 3,
              textAlign: "center",
            }}
          >
            Position Competencies
          </Text>
        </View>
        <View style={[{ display: "flex", flexDirection: "row", marginTop: 5, textAlign: "center", justifyContent: "center" }]}>
          <View style={[{ display: "flex", flexDirection: "row", marginTop: 5, textAlign: "center", justifyContent: "center", marginRight: 12 }]}>
            <Text style={[{ fontFamily: "RobotoMedium", fontSize: 10, textAlign: "center" }]}>Created by:</Text>
            <Text style={{ fontFamily: "RobotoRegular", fontSize: 10, marginLeft: 2 }}>
              {user?.name}({user?.title})
            </Text>
          </View>
          <View style={[{ display: "flex", flexDirection: "row", marginTop: 5 }]}>
            <Text style={[{ fontFamily: "RobotoMedium", fontSize: 10 }]}>Date:</Text>
            <Text style={{ fontFamily: "RobotoRegular", fontSize: 10, marginLeft: 2 }}>{moment(new Date()).format("MM/DD/YYYY")}</Text>
          </View>
        </View>
      </View>

      <View
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        {data.length > 0 &&
          data.map((item) => (
            <>
              <Text
                style={{
                  fontSize: 11,
                  fontFamily: "RobotoBold",
                  textDecoration: "underline",
                  marginBottom: 10,
                }}
              >
                {item.name}
              </Text>
              <Text
                style={{
                  fontSize: 10,
                  fontFamily: "RobotoRegular",
                  lineHeight: 1.3,
                  marginBottom: 10,
                }}
              >
                {item?.position_competencies}
              </Text>
            </>
          ))}
      </View>
    </Page>
  );
};

export default CompetenciesReport;
