import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { postData } from "../services/api";
import SyncDataPopup from "../components/popup/SyncDataPopup";

const SyncDataModalContext = createContext();

export const SyncDataModalProvider = ({ children }) => {
  const [syncDataModalOpen, setSyncDataModalOpen] = useState(false);
  const [userList, setUserList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [startDataCount, setStartDataCount] = useState(0);
  const [endDataCount, setEndDataCount] = useState(0);
  const [noOfPage, setNoOfPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [syncingLoader, setSyncingLoader] = useState(false);
  const [syncingStep, setSyncingStep] = useState({ step: "1", message: null });
  const [selectedRole, setSelectedRole] = useState("");

  const loadUserList = useCallback(async () => {
    setLoading(true);
    try {
      const result = await postData("all-employee-list", {
        code: [
          "customer-user",
          "customer-subadmin",
          "customer-boardmember",
          "customer-employee",
        ],
        role: selectedRole,
        is_invited: 1,
        keyword: keyword,
        limit: limit,
        offset: offset,
        keyword: keyword,
      });
      if (result.data) {
        setUserList(result.data);
        setTotalDataCount(result?.count);
      }
      setLoading(false);
    } catch (err) {
      console.error(err.message);
    } finally {
      setLoading(false);
    }
  }, [keyword, limit, offset, selectedRole]);

  useEffect(() => {
    loadUserList();
  }, [loadUserList]);

  return (
    <SyncDataModalContext.Provider
      value={{
        syncDataModalOpen,
        setSyncDataModalOpen,
        userList,
        setUserList,
        loadUserList,
        loading,
        setLoading,
        syncingLoader,
        setSyncingLoader,
        syncingStep,
        setSyncingStep,
        keyword,
        setKeyword,
        limit,
        setLimit,
        offset,
        setOffset,
        totalDataCount,
        setTotalDataCount,
        startDataCount,
        setStartDataCount,
        endDataCount,
        setEndDataCount,
        noOfPage,
        setNoOfPage,
        currentPage,
        setCurrentPage,
        selectedRole,
        setSelectedRole,
      }}
    >
      {children}
      <SyncDataPopup isOpen={syncDataModalOpen} />
    </SyncDataModalContext.Provider>
  );
};

export const useDataSyncModal = () => {
  return useContext(SyncDataModalContext);
};
