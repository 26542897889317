import React, { useState, useEffect, Suspense, lazy, useCallback } from "react";
import { setTitle } from "../helpers/MetaTag";
import Button from "../components/form/Button";
import Input from "../components/form/Input";
import Select from "../components/form/Select";
import { Link } from "react-router-dom";
import { dayRemaining, groupBy } from "./../helpers";
import toastr from "toastr";
import moment from "moment/moment";
import { postData } from "../services/api";
import { useParams, useNavigate } from "react-router-dom";
import Searching from "../components/Searching";
import NoDataFound from "../components/NoDataFound";
import { useSelector } from "react-redux";
import { PDFDownloadLink, Document, pdf } from "@react-pdf/renderer";
import ScorecardIndividualReport from "./pdf/ScorecardIndividualReport";
import InputGroup from "../components/form/InputGroup";
import SuccessionPlanReport from "./pdf/SuccessionPlanReport";
import SuccessionPlanAllReport from "./pdf/SuccessionPlanAllReport.js-not-used";
import { emailDomain } from "./../helpers";
import { API_URL } from "../config/host";
import OtherPositionPopup from "../components/popup/OtherPositionPopup";
import ApprovalPopup from "../components/popup/ApprovalPopup";
import AlertPopup from "../components/popup/AlertPopup";
import SuccessionPlanAllReportMain from "./pdf/SuccessionPlanAllReportMain";
import Checkbox from "../components/form/Checkbox";
import AdditionalPlanPopup from "../components/popup/AdditionalPlanPopup";
import { Tooltip } from "react-tooltip";
import DeletePopup from "../components/DeletePopup";
import ConfirmationPopup from "../components/popup/ConfirmationPopup";
import { useDispatch } from "react-redux";
import { authLogin, authLogout } from "../store/authSlice";
import EmergencyReplacementPlanReport from "./pdf/EmergencyReplacementPlanReport";
import { useTranslation } from "react-i18next";
import TrialAlertPopup from "../components/popup/TrialAlertPopup";
import SendReminderPopup from "../components/popup/SendReminderPopup";
import DashboardPlanTrackingSkeleton from "../components/loader/DashboardPlanTrackingSkeleton";
import TableSkeleton from "../components/loader/TableSkeleton";
import { SuccessonPlanLimitMessage } from "../helpers";
import DashBoardReportCenter from "../components/section/Dashboard/DashBoardReportCenter";
import AvgOfQuestionTier from "../components/section/Dashboard/AvgOfQuestionTier";
import CardTable from "../components/elements/CardTable";

// const PDFDownloadLink = lazy(() => import('@react-pdf/renderer').then(module => ({ default: module.PDFDownloadLink})));
// const Document = lazy(() => import('@react-pdf/renderer').then(module => ({ default: module.Document })));

const Dashboard = () => {
  setTitle("Succession Planning Software for your Business with Succession Now | Dashboard");
  const { i18n, t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const role = localStorage.getItem("role");
  const isTrial = localStorage.getItem("isTrial");
  const auth = useSelector((state) => state.auth);
  const user = useSelector((state) => state.auth?.user);
  const [position, setPosition] = useState([]);
  const [retirementWindow, setRetirementWindow] = useState([]);

  const [loading, setLoading] = useState(true);
  const [details, setDetails] = useState({});
  const [isAdd, setIsAdd] = useState(false);
  const [name, setName] = useState("");
  const [assignTitle, setAssignTitle] = useState("");
  const [email, setEmail] = useState("");
  const [domain, setDomain] = useState("");
  const [designation, setDesignation] = useState("");
  const [selectRetirementWindow, setSelectRetirementWindow] = useState("");
  const [shortName, setShortName] = useState("");
  const [showRerirement, setShowRetirement] = useState("");

  const [ownSuccessor, setOwnSuccessors] = useState([]);
  const [successors, setSuccessors] = useState([]);
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [isUpdate, setIsUpdate] = useState(false);

  const [predictorWeight, setPredictorWeight] = useState(0);
  const [experienceWeight, setExperienceWeight] = useState(0);
  const [concernsWeight, setConcernWeight] = useState(0);
  const [performanceWeight, setPerformanceWeight] = useState(0);
  const [educationWeight, setEducationWeight] = useState(0);
  const [yearWithCompanyWeight, setYearWithCompany] = useState(0);
  const [totalWeight, setTotalWeight] = useState(0);

  const windowUrl = window.location.search;
  const [isSuccessorCount, setIsSuccessorCount] = useState(false);
  const [successorCount, setSuccessorCount] = useState(0);
  const [highScore, setHighScore] = useState([]);
  const [positionDetails, setPositionDetails] = useState({});
  const [concernHeadings, setConcernHeadings] = useState([]);
  const [predictorsHeading, setPredictorsHeading] = useState([]);
  const [conclusionDetails, setConclusionDetails] = useState({});
  const [signature, setSignature] = useState("");
  const [conclusion, setConclusion] = useState([]);
  const [conclusionArray, setConclusionArray] = useState([]);

  const [enabledOtherPositionPopup, setEnabledOtherPositionPopup] = useState(false);
  const [enabledAdditionalPlanPopup, setEnabledAdditionalPlanPopup] = useState(false);
  const [enabledApprovalPopup, setEnabledApprovalPopup] = useState(false);
  const [enabledAlertPopup, setEnabledAlertPopup] = useState(false);
  const [enabledDeletePopup, setenabledDeletePopup] = useState(false);
  const [enabledConfirmationPopup, setEnabledConfirmationPopup] = useState(false);
  const [enabledSendReminderPopup, setEnabledSendReminderPopup] = useState(false);
  const [actionValue, setActionValue] = useState({});
  const [isApproved, setIsApproved] = useState(2);
  const [currentId, setCurrentId] = useState("");

  const [isChecking, setIsChecking] = useState(false);
  const [planData, setPalnData] = useState({});
  const [isAdditionalAdministrator, setIsAdditionalAdministrator] = useState(2);
  const [isInvitedBoardMember, setIsInvitedBoardMember] = useState(2);
  const [isAdditionalAdminDisabled, setIsAdditionalAdminDisabled] = useState(false);
  const [isInviteMemberDisabled, setIsInviteMemberDisabled] = useState(false);
  const [filed, setField] = useState("");
  const [enabledTrialPopup, setEnabledTrialPopup] = useState(false);

  const [employees, setEmployees] = useState([]);
  const path = window.location.pathname.replace(/[/]/g, "").replace(/\s/g, "");

  const [showSkeleton, setShowSkeleton] = useState(true);
  const [availableSuccessionPlan, setAvailableSuccessionPlan] = useState(0);
  const [enabledPrivatePopup, setEnabledPrivatePopup] = useState(false);

  const [readinessData, setReadinessData] = useState({
    data: [],
    loading: false,
  });
  const [retirementSummaryData, setRetirementSummaryData] = useState({
    data: [],
    loading: false,
  });

  useEffect(() => {
    if (user?.features && user?.features?.length > 0) {
      let successionPlanCount = user?.features?.find((it) => it?.code === "succession-plans-per-year")?.inputValue;
      setAvailableSuccessionPlan(successionPlanCount);
    }
  }, [user]);

  useEffect(() => {
    if (!loading && successors?.length <= 0) {
      const timer = setTimeout(() => {
        setShowSkeleton(false);
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [loading, successors]);

  const heading = [
    {
      name: t("potential_predictor_score"),
      option: t("weight") + ": " + predictorWeight + "%",
    },
    {
      name: t("senior_management_experience"),
      option: t("weight") + ": " + experienceWeight + "%",
    },
    {
      name: t("area_of_concern_score"),
      option: t("weight") + ": " + concernsWeight + "%",
    },
    {
      name: t("performance_rating"),
      option: t("weight") + ": " + performanceWeight + "%",
    },
    {
      name: t("education"),
      option: t("weight") + ": " + educationWeight + "%",
    },
    {
      name: t("years_with_the_company"),
      option: t("weight") + ": " + yearWithCompanyWeight + "%",
      overall_score: yearWithCompanyWeight,
    },
    {
      name: t("desire_to_advance"),
      option: t("pass") + "/" + t("fail"),
    },
    {
      name: t("loyalty"),
      option: t("pass") + "/" + t("fail"),
    },
    {
      name: t("retirement_window"),
      option: t("pass") + "/" + t("fail"),
    },
    {
      name: t("final_succession_score"),
      option: t("weight") + ": " + totalWeight + "%",
    },
    // {
    //   name: t("advancement_readiness_projection"),
    //   option: "",
    // },
    {
      name: t("additional_comments"),
      option: "",
    },
  ];

  const readinessHeading = [
    {
      name: t("readiness_timeframe"),
      option: "",
    },
    {
      name: t("competency_fit"),
      option: "",
    },
    {
      name: t("cultural_fit"),
      option: "",
    },
    {
      name: t("additional_comments"),
      option: "",
    },
    {
      name: t("eliminated_candidate_due_to"),
      option: "",
    },
  ];

  useEffect(() => {
    if (role && role === "customer-boardmember" && path !== "change-password") {
      return navigate("/boardroom");
    }
  }, [role]);

  useEffect(() => {
    setSignature(API_URL + conclusionDetails?.signature);
    setConclusion(conclusionDetails?.conclusion);
  }, [conclusionDetails]);

  useEffect(() => {
    if (user && Object.keys(user).length > 0) {
      setIsAdditionalAdministrator(user?.confirm_additional_admin);
      if (user?.confirm_additional_admin === 1) {
        setIsAdditionalAdminDisabled(false);
        setIsInviteMemberDisabled(true);
      }
    }
  }, [user]);

  useEffect(() => {
    // Profiles List
    const loadSuccessors = async () => {
      try {
        const result = await postData("successor/list", {
          sortQuery: { created_at: 1 },
          is_interim: 2,
        });
        if (result.data) {
          setOwnSuccessors(
            result.data.map((value, key) => {
              let predictorCalculation = (predictorWeight * value?.overall_weighted_score) / 100;
              let experienceCalculation = (experienceWeight * (value.experience_details ? value?.experience_details[0]?.point : 0)) / 100;
              let concernsCalculation = (concernsWeight * value?.overall_score) / 100;
              let performanceCalculation = (performanceWeight * (value.performance_details ? value?.performance_details[0]?.point : 0)) / 100;
              let educationCalculation = (educationWeight * (value.education_details ? value?.education_details[0]?.point : 0)) / 100;
              let yearWithCompanyCalculation = (yearWithCompanyWeight * (value.cu_details ? value?.cu_details[0]?.point : 0)) / 100;
              let desireResult = value?.desire_details && value?.desire_details.length > 0 ? (value?.desire_details[0]?.isPass ? 1 : 2) : null;
              let loyaltyResult = value?.loyalty_details && value?.loyalty_details.length > 0 ? (value?.loyalty_details[0]?.isPass ? 1 : 2) : null;
              let RetirementResult = value?.retirement_details && value?.retirement_details.length > 0 ? (value?.retirement_details[0]?.isPass ? 1 : 2) : null;
              return {
                ...value,
                overallWeightScore:
                  desireResult === 2 || loyaltyResult === 2 || RetirementResult === 2
                    ? 0.0
                    : (
                        parseFloat(predictorCalculation) +
                        parseFloat(experienceCalculation) +
                        parseFloat(concernsCalculation) +
                        parseFloat(performanceCalculation) +
                        parseFloat(educationCalculation) +
                        parseFloat(yearWithCompanyCalculation)
                      ).toFixed(2),
              };
            })
          );
        }
        setSuccessorCount(result.count);
        setIsSuccessorCount(true);
      } catch (err) {
        console.log(err.message);
      }
    };
    loadSuccessors();

    async function positionDetails() {
      try {
        const result = await postData("competency/details", {
          id: user.designation,
        });
        if (result.data) {
          setPositionDetails(result.data);
        } else {
          console.log("Competency details message", result.message);
        }
      } catch (error) {
        console.log("Error competency details catch", error.message);
      }
    }
    positionDetails();

    const detailsFun = async () => {
      setLoading(true);
      try {
        const result = await postData("emergency-replacement/details", {});
        if (result.data) {
          setDetails(result.data);
          setLoading(false);
        } else {
          setDetails({});
          setLoading(false);
        }
      } catch (err) {
        console.log(err.message);
      }
    };
    detailsFun();
  }, [isUpdate || ""]);

  useEffect(() => {
    async function employeeData() {
      try {
        const result = await postData("employee/list", {
          sortQuery: { created_at: -1 },
          status: 1,
        });
        if (result.data) {
          //setEmployees(result.data);
          setEmployees(
            result.data.map((items) => {
              let firstName = "";
              let lastName = "";
              if (!items?.first_name) {
                firstName = items?.name.split(/\s+/)[0];
                lastName = items?.name.split(/\s+/)[1];
              } else {
                firstName = items?.first_name;
                lastName = items?.last_name;
              }
              return {
                ...items,
                first_name: firstName,
                last_name: lastName,
              };
            })
          );
        } else {
          console.log("Employee list message", result.message);
        }
      } catch (error) {
        console.log("Error employee list catch", error.message);
      }
    }
    employeeData();

    async function retirementWindowData() {
      try {
        const result = await postData("experience/list", {
          sortQuery: { created_at: 1 },
          type: 3,
          status: 1,
          is_visabled: 1,
        });
        if (result.data) {
          setRetirementWindow(result.data);
        } else {
          console.log("Retirement window list message", result.message);
        }
      } catch (error) {
        console.log("Error retirement window list catch", error.message);
      }
    }
    retirementWindowData();

    const loaWeights = async () => {
      setIsUpdate(false);
      try {
        const result = await postData("scorecard/list", {
          status: 1,
        });
        if (result.data) {
          let total = 0;
          let pWeight = 0;
          let exWeight = 0;
          let con = 0;
          let pref = 0;
          let edu = 0;
          let ywc = 0;
          result.data.map((value, key) => {
            if (value.alias === "potential_predictor_score") {
              pWeight = value?.company_weight ? value?.company_weight : value?.weight;
              total = total + Number(value?.company_weight ? value?.company_weight : value?.weight);
            } else if (value.alias === "executive_experience") {
              exWeight = value?.company_weight ? value?.company_weight : value?.weight;
              total = total + Number(value?.company_weight ? value?.company_weight : value?.weight);
            } else if (value.alias === "concerns") {
              con = value?.company_weight ? value?.company_weight : value?.weight;
              total = total + Number(value?.company_weight ? value?.company_weight : value?.weight);
            } else if (value.alias === "recent_performance_review") {
              pref = value?.company_weight ? value?.company_weight : value?.weight;
              total = total + Number(value?.company_weight ? value?.company_weight : value?.weight);
            } else if (value.alias === "highest_level_education") {
              edu = value?.company_weight ? value?.company_weight : value?.weight;
              total = total + Number(value?.company_weight ? value?.company_weight : value?.weight);
            } else if (value.alias === "years_with_company") {
              ywc = value?.company_weight ? value?.company_weight : value?.weight;
              total = total + Number(value?.company_weight ? value?.company_weight : value?.weight);
            }
            setPredictorWeight(pWeight);
            setExperienceWeight(exWeight);
            setConcernWeight(con);
            setPerformanceWeight(pref);
            setEducationWeight(edu);
            setYearWithCompany(ywc);
            setTotalWeight(total);
            setIsUpdate(true);
            // if(pWeight>0 && exWeight>0 && con>0 && pref>0 && edu>0){
            //   console.log('reload')
            //setIsUpdate(true);
            // }else{
            //   console.log('update')
            //   setIsUpdate(false);
            // }
          });
        }
        setTotalDataCount(result.count);
      } catch (err) {
        console.log(err.message);
        setIsUpdate(false);
      }
    };
    loaWeights();

    async function concernHeadingData() {
      try {
        const result = await postData("heading/list", {
          sortQuery: { created_at: 1 },
          status: 1,
          type: "concern",
        });
        if (result.data) {
          setConcernHeadings(result.data);
        } else {
          console.log("Concern heading list message", result.message);
        }
      } catch (error) {
        console.log("Error concern heading list catch", error.message);
      }
    }
    concernHeadingData();

    async function predictorHeadingData() {
      try {
        const result = await postData("heading/list", {
          sortQuery: { created_at: 1 },
          status: 1,
          type: "predictors",
        });
        if (result.data) {
          setPredictorsHeading(result.data);
        } else {
          console.log("Predictors heading list message", result.message);
        }
      } catch (error) {
        console.log("Error predictors heading list catch", error.message);
      }
    }
    predictorHeadingData();

    const loadConclusionDetails = async () => {
      setLoading(true);
      try {
        const result = await postData("conclusion/details", {});
        if (result.data) {
          setConclusionDetails(result.data);
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err.message);
      }
    };
    loadConclusionDetails();
  }, []);

  useEffect(() => {
    async function positionData() {
      setDesignation("");
      try {
        const result = await postData("position/list", {
          sortQuery: { created_at: 1 },
          status: 1,
          isBoardMember: isInvitedBoardMember === 1 ? 1 : 2,
        });
        if (result.data) {
          setPosition(result.data.sort((a, b) => a.name.localeCompare(b.name)));
        } else {
          console.log("Position list message", result.message);
        }
      } catch (error) {
        console.log("Error position list catch", error.message);
      }
    }
    positionData();
  }, [isInvitedBoardMember || ""]);

  useEffect(() => {
    if (actionValue && (actionValue.button_type === "confirm" || actionValue.button_type === "close")) {
      let fieldValue = 0;
      if (actionValue.button_type === "confirm") {
        fieldValue = 1;
      } else if (actionValue.button_type === "close") {
        fieldValue = 2;
      }
      const confirmAdditionalAdministrator = async () => {
        setLoading(true);
        try {
          const result = await postData("permission/manage", {
            [filed]: fieldValue,
          });
          if (result.data) {
            dispatch(
              authLogin({
                ...user,
                confirm_additional_admin: fieldValue,
              })
            );
            toastr.success(result.message);
            if (actionValue.button_type === "confirm") {
              setIsAdditionalAdministrator(1);
              setIsInvitedBoardMember(2);
              if (fieldValue === 1) {
                setIsAdditionalAdminDisabled(false);
                setIsInviteMemberDisabled(true);
              } else {
                setIsAdditionalAdminDisabled(false);
                setIsInviteMemberDisabled(false);
              }
            } else if (actionValue.button_type === "close") {
              setIsAdditionalAdministrator(2);
              setIsInvitedBoardMember(2);
              setIsAdditionalAdminDisabled(false);
              setIsInviteMemberDisabled(false);
            }
            setEnabledConfirmationPopup(false);
          }
          setLoading(false);
        } catch (err) {
          setLoading(false);
          console.log(err.message);
        }
      };
      confirmAdditionalAdministrator();
    } else if (actionValue && actionValue.button_type === "delete") {
      const loadDeleteRecord = async () => {
        setIsUpdate(false);
        try {
          const statusData = await postData("succession-plan/delete", {
            id: actionValue?.row_id,
          });
          if (statusData.data) {
            const index = successors.findIndex((item) => item._id === actionValue?.row_id);
            if (index !== -1) {
              successors.splice(index, 1);
              setSuccessors(
                successors.map((it) => {
                  return it;
                })
              );
            }
            const userData = await postData("verify-token", {}, localStorage.getItem("accessToken"));
            if (userData?.data) {
              if (userData?.status && userData?.status === 200) {
                dispatch(authLogin(userData?.data));
              } else {
                dispatch(authLogout());
                localStorage.removeItem("accessToken");
                navigate("/signin");
              }
            }
            setIsUpdate(true);
          } else {
            toastr.error(statusData.message);
          }
        } catch (error) {
          toastr.error(error.message);
        }
      };
      loadDeleteRecord();
    } else if (actionValue) {
      setPosition([...position, actionValue]);
      setDesignation(actionValue?._id);
    }
  }, [actionValue]);

  const updateHandler = async (id, event) => {
    setLoading(true);
    try {
      const result = await postData("download-succession-plan/approved", {
        id: id,
        is_approved: event ? 1 : 2,
      });
      if (result.data) {
        toastr.success(result.message);
        setCurrentId("");
        setEnabledAlertPopup(false);
        setSuccessors(
          successors.map((item, key) => {
            if (item?._id === id) {
              return {
                ...item,
                is_approved: event ? 1 : 2,
              };
            } else {
              return item;
            }
          })
        );
        //setIsApproved(result.data?.is_approved)
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setCurrentId("");
      console.log(err.message);
    }
  };

  // const makePrivateHandler = async (id, event) => {
  //   setLoading(true);
  //   try {
  //     const result = await postData("download-succession-plan/make-private", {
  //       id: id,
  //       make_private: event ? 1 : 2,
  //     });
  //     if (result.data) {
  //       toastr.success(result.message);
  //       setCurrentId("");
  //       //setEnabledAlertPopup(false)
  //       setSuccessors(
  //         successors.map((item, key) => {
  //           if (item?._id === id) {
  //             return {
  //               ...item,
  //               make_private: event ? 1 : 2,
  //             };
  //           } else {
  //             return item;
  //           }
  //         })
  //       );
  //     } else {
  //       toastr.error(result.message);
  //     }
  //     setLoading(false);
  //   } catch (err) {
  //     setLoading(false);
  //     setCurrentId("");
  //     console.log(err.message);
  //   }
  // };

  useEffect(() => {
    if (user && Object.keys(actionValue).length === 0) {
      setDesignation(user?.designation);
      setDomain(emailDomain(user?.email));
    }
    if (position) {
      const index = position.findIndex((item) => item._id === user?.designation);
      if (index !== -1) {
        setShortName(position[index].short_name);
      }
    }
    if (retirementWindow) {
      const index = retirementWindow.findIndex((item) => item._id === user?.retirement_window);
      if (index !== -1) {
        setShowRetirement(retirementWindow[index].name);
      }
    }
  }, [user, position, retirementWindow, actionValue]);

  useEffect(() => {
    const loadSuccessors = async () => {
      setLoading(true);
      try {
        const result = await postData("highrated-successor/list", {
          is_interim: 2,
          //is_visabled: 2,
          isAllAvailable: 1,
          //useFor : "succession-plan"
        });
        if (result.data) {
          setSuccessors(
            result.data.map((item, key) => {
              //console.log('item',item)
              if (item.successors_details && item.successors_details.length > 0) {
                let successors_details = item.successors_details.map((value, index) => {
                  let predictorCalculation = (predictorWeight * value?.overall_weighted_score) / 100;
                  let experienceCalculation = (experienceWeight * (value.experience_details ? value?.experience_details[0]?.point : 0)) / 100;
                  let concernsCalculation = (concernsWeight * value?.overall_score) / 100;
                  let performanceCalculation = (performanceWeight * (value.performance_details ? value?.performance_details[0]?.point : 0)) / 100;
                  let educationCalculation = (educationWeight * (value.education_details ? value?.education_details[0]?.point : 0)) / 100;
                  let yearWithCompanyCalculation = (yearWithCompanyWeight * (value.cu_details ? value?.cu_details[0]?.point : 0)) / 100;
                  let desireResult = value?.desire_details && value?.desire_details.length > 0 ? (value?.desire_details[0]?.isPass ? 1 : 2) : null;
                  let loyaltyResult = value?.loyalty_details && value?.loyalty_details.length > 0 ? (value?.loyalty_details[0]?.isPass ? 1 : 2) : null;
                  let RetirementResult = value?.retirement_details && value?.retirement_details.length > 0 ? (value?.retirement_details[0]?.isPass ? 1 : 2) : null;
                  return {
                    ...value,
                    overallWeightScore:
                      desireResult === 2 || loyaltyResult === 2 || RetirementResult === 2
                        ? 0.0
                        : (
                            parseFloat(predictorCalculation) +
                            parseFloat(experienceCalculation) +
                            parseFloat(concernsCalculation) +
                            parseFloat(performanceCalculation) +
                            parseFloat(educationCalculation) +
                            parseFloat(yearWithCompanyCalculation)
                          ).toFixed(2),
                    desireResult: desireResult,
                    loyaltyResult: loyaltyResult,
                    RetirementResult: RetirementResult,
                  };
                });
                return {
                  _id: item?._id,
                  created_at: item?.created_at,
                  is_approved: item?.is_approved,
                  approved_date: item?.approved_date,
                  report_date: item?.report_date,
                  customer_admin_id: item?.customer_admin_id,
                  make_private: item?.make_private,
                  name: item.name,
                  position: item.position,
                  retirement: item.retirement,
                  pos_details: item.pos_details,
                  created_retirement_details: item.created_retirement_details,
                  successors_details: successors_details,
                  conclusion: item.conclusion,
                  plan_approve: item.plan_approve,
                  date: item.date,
                  signature: item.signature,
                  conclusion_name: item.conclusion_name,
                  isInvitedEmployee: item.isInvitedEmployee,
                  designation: item?.designation,
                  retirement_window: item?.retirement_window,
                  emergency_replacement_details: item?.emergency_replacement_details,
                  conclusion_details: item?.conclusion_details,
                };
              } else {
                return {
                  _id: item?._id,
                  created_at: item?.created_at,
                  is_approved: item?.is_approved,
                  approved_date: item?.approved_date,
                  customer_admin_id: item?.customer_admin_id,
                  make_private: item?.make_private,
                  name: item.name,
                  position: item.position,
                  retirement: item.retirement,
                  pos_details: item.pos_details,
                  created_retirement_details: item.created_retirement_details,
                  conclusion: item.conclusion,
                  plan_approve: item.plan_approve,
                  date: item.date,
                  signature: item.signature,
                  conclusion_name: item.conclusion_name,
                  successors_details: [],
                  isInvitedEmployee: item.isInvitedEmployee,
                  designation: item?.designation,
                  retirement_window: item?.retirement_window,
                  emergency_replacement_details: item?.emergency_replacement_details,
                  conclusion_details: item?.conclusion_details,
                };
              }
            })
          );
        }
        //console.log('result.data.length', result.data.length)
        setTotalDataCount(result.data.length);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err.message);
      }
    };
    //console.log('isUpdate', isUpdate)
    if (isUpdate) {
      loadSuccessors();
    }
  }, [isUpdate]);

  const onSubmit = async (e) => {
    setIsAdd(true);
    let successionPlanCount = 0;
    let totalSuccessionPlan = user?.number_of_succession_plan ? user?.number_of_succession_plan : 0;
    if (user?.features && user?.features?.length > 0) {
      successionPlanCount = user?.features?.find((it) => it?.code === "succession-plans-per-year")?.inputValue;
    }
    if (successionPlanCount && successionPlanCount > totalSuccessionPlan) {
      try {
        let payload;
        let path;
        if (id) {
          path = "assign/update";
          payload = {
            id: id,
            name: name,
            title: assignTitle,
            email: email + "@" + domain,
            company_name: user?.company_name,
          };
        } else {
          path = "assign/create";
          payload = {
            name: name,
            title: assignTitle,
            email: email,
            //email: email+'@'+domain,
            designation: designation,
            retirement_window: selectRetirementWindow,
            company_name: user?.company_name,
            isInvitedBoardMember: isInvitedBoardMember,
          };
        }
        const result = await postData(path, payload);
        if (result.status && result.status === 200) {
          toastr.success(result.message);
          setIsAdd(false);
          setName("");
          setAssignTitle("");
          setEmail("");
          setDesignation("");
          setSelectRetirementWindow("");
          const userData = await postData("verify-token", {}, localStorage.getItem("accessToken"));
          if (userData?.data) {
            if (userData?.status && userData?.status === 200) {
              dispatch(authLogin(userData?.data));
            } else {
              dispatch(authLogout());
              localStorage.removeItem("accessToken");
              navigate("/signin");
            }
          }
          // if(isInvitedBoardMember && isInvitedBoardMember ===1){
          //   return navigate("/board-member");
          // }else{
          return navigate("/dashboard");
          //}
        } else {
          toastr.error(result.message);
          setIsAdd(false);
        }
      } catch (error) {
        toastr.error(error.message);
        setIsAdd(false);
      }
    } else {
      toastr.error(SuccessonPlanLimitMessage(successionPlanCount));
      setIsAdd(false);
    }
  };

  const getDownload = async (e) => {
    e.preventDefault();
    if (e.target.parentNode.tagName.toLowerCase() === "a") {
      window.open(e.target.parentNode.href, "_blank");
    }
  };

  const handleDownloadClick = async (e) => {
    e.preventDefault();

    // Open a blank tab immediately to avoid pop-up blockers.
    const newTab = window.open("", "_blank");

    // Display a styled loading message with Tailwind CSS.
    newTab.document.write(`
      <html lang="en">
        <head>
          <script src="https://cdn.tailwindcss.com"></script>
          <style>
            body {
              @apply bg-gray-100;
              margin: 0;
              height: 100vh;
            }
            .loader-container {
              @apply flex flex-col justify-center items-center h-screen;
            }
            .loader {
              width: 50px;
              aspect-ratio: 1;
              display: grid;
              border-radius: 50%;
              background:
                linear-gradient(0deg, rgb(0 0 0 / 50%) 30%, #0000 0 70%, rgb(0 0 0 / 100%) 0) 50% / 8% 100%,
                linear-gradient(90deg, rgb(0 0 0 / 25%) 30%, #0000 0 70%, rgb(0 0 0 / 75%) 0) 50% / 100% 8%;
              background-repeat: no-repeat;
              animation: l23 1s infinite steps(12);
            }
            .loader::before,
            .loader::after {
              content: "";
              grid-area: 1 / 1;
              border-radius: 50%;
              background: inherit;
              opacity: 0.915;
              transform: rotate(30deg);
            }
            .loader::after {
              opacity: 0.83;
              transform: rotate(60deg);
            }
            @keyframes l23 {
              100% {
                transform: rotate(1turn);
              }
            }
          </style>
        </head>
        <body>
          <div class="flex justify-center items-center mt-20 loader-container">
          <div class="flex flex-col items-center justify-center space-y-4">
            <div class="loader"></div>
            <p class="text-teal-700 text-lg font-semibold mt-4">Generating your PDF... Please wait.</p>
          </div>
         </div>
        </body>
      </html>
      `);
    try {
      // Generate the PDF blob asynchronously.
      const blob = await pdf(
        <SuccessionPlanAllReportMain
          successors={successors}
          heading={heading}
          readinessHeading={readinessHeading}
          concernHeadings={concernHeadings}
          predictorsHeading={predictorsHeading}
          user={user}
          details={details}
          companyLogo={API_URL + user?.image}
        />
      ).toBlob();

      // Create a URL from the blob.
      const blobURL = URL.createObjectURL(blob);
      // Redirect the new tab to the blob URL (load the PDF).
      newTab.location.href = blobURL;
    } catch (error) {
      // Display an error message in the new tab.
      newTab.document.body.innerHTML = `
        <div class="flex flex-col items-center justify-center h-screen bg-red-100">
          <p class="text-red-700 text-lg font-semibold">Failed to load PDF. Please try again later.</p>
          <p class="text-sm text-gray-600">${error.message}</p>
        </div>
      `;
      console.error("PDF generation error:", error);
    }
  };

  const handleSingleDownloadClick = async (e, item, index) => {
    e.preventDefault();

    // Open a blank tab immediately to avoid pop-up blockers.
    const newTab = window.open("", "_blank");

    // Display a styled loading message with Tailwind CSS.
    newTab.document.write(`
      <html lang="en">
        <head>
          <script src="https://cdn.tailwindcss.com"></script>
          <style>
            body {
              @apply bg-gray-100;
              margin: 0;
              height: 100vh;
            }
            .loader-container {
              @apply flex flex-col justify-center items-center h-screen;
            }
            .loader {
              width: 50px;
              aspect-ratio: 1;
              display: grid;
              border-radius: 50%;
              background:
                linear-gradient(0deg, rgb(0 0 0 / 50%) 30%, #0000 0 70%, rgb(0 0 0 / 100%) 0) 50% / 8% 100%,
                linear-gradient(90deg, rgb(0 0 0 / 25%) 30%, #0000 0 70%, rgb(0 0 0 / 75%) 0) 50% / 100% 8%;
              background-repeat: no-repeat;
              animation: l23 1s infinite steps(12);
            }
            .loader::before,
            .loader::after {
              content: "";
              grid-area: 1 / 1;
              border-radius: 50%;
              background: inherit;
              opacity: 0.915;
              transform: rotate(30deg);
            }
            .loader::after {
              opacity: 0.83;
              transform: rotate(60deg);
            }
            @keyframes l23 {
              100% {
                transform: rotate(1turn);
              }
            }
          </style>
        </head>
        <body>
         <div class="flex justify-center items-center mt-20 loader-container">
          <div class="flex flex-col items-center justify-center space-y-4">
            <div class="loader"></div>
            <p class="text-teal-700 text-lg font-semibold mt-4">Generating your PDF... Please wait.</p>
          </div>
         </div>
        </body>
      </html>
      `);

    try {
      // Generate the PDF blob asynchronously.
      const blob = await pdf(
        <Document>
          <SuccessionPlanReport
            key={index}
            successors={[item]}
            heading={heading}
            readinessHeading={readinessHeading}
            concernHeadings={concernHeadings}
            predictorsHeading={predictorsHeading}
            conclusion={item.conclusion ? item?.conclusion : []}
            planApprove={item?.plan_approve}
            name={item?.conclusion_name}
            date={item?.date}
            trimmedDataURL={item?.signature}
            is_approved={item?.is_approved}
            user={user}
            companyLogo={API_URL + user?.image}
            creatorPosition={item?.pos_details && item?.pos_details?.length > 0 ? item?.pos_details[0]?.name : ""}
            creator={item?.name}
          />
          {item?.pos_details && item?.pos_details[0]?.short_name === "CEO" && (
            <EmergencyReplacementPlanReport data={details} details={item?.emergency_replacement_details} companyLogo={API_URL + user?.image} />
          )}
        </Document>
      ).toBlob();

      // Create a URL from the blob.
      const blobURL = URL.createObjectURL(blob);
      // Redirect the new tab to the blob URL (load the PDF).
      newTab.location.href = blobURL;
    } catch (error) {
      // Display an error message in the new tab.
      newTab.document.body.innerHTML = `
        <div class="flex flex-col items-center justify-center h-screen bg-red-100">
          <p class="text-red-700 text-lg font-semibold">Failed to load PDF. Please try again later.</p>
          <p class="text-sm text-gray-600">${error.message}</p>
        </div>
      `;
      console.error("PDF generation error:", error);
    }
  };

  const getDownload2 = async (e) => {
    e.preventDefault();
    if (e.target.tagName.toLowerCase() === "a") {
      window.open(e.target.href, "_blank");
    }
  };

  useEffect(() => {
    let result2 = [];
    if (successors.length > 0) {
      successors.map((row) => {
        if (row.successors_details && row.successors_details.length > 0) {
          row.successors_details.map((item) => {
            result2.push({
              name: item?.name,
              successor_position: row.position,
              overall_score: item?.overallWeightScore,
              current_position: item?.position_details,
            });
          });
        }
      });
    }
    let mainResult = result2; //[...result,...result2];
    const group = groupBy(mainResult, "successor_position");
    setHighScore(group);
  }, [ownSuccessor, successors]);

  const LoginAs = async (login_id) => {
    setIsChecking(false);
    return navigate("/login-as?user=" + login_id);
  };

  const loadReadinessList = useCallback(async () => {
    setReadinessData({
      data: [],
      loading: true,
    });
    try {
      const result = await postData("avarage-item", {
        type: "timeframe",
      });
      if (result.status === 200) {
        setReadinessData({
          data: result?.data,
          loading: false,
        });
      }
    } catch (err) {
      setReadinessData({
        data: [],
        loading: false,
      });
      console.log(err.message);
    }
  }, []);

  useEffect(() => {
    loadReadinessList();
  }, [loadReadinessList]);

  const loadRetirementSummaryList = useCallback(async () => {
    setRetirementSummaryData({
      data: [],
      loading: true,
    });
    try {
      const result = await postData("avarage-others", {
        type: 3,
      });
      if (result.status === 200) {
        setRetirementSummaryData({
          data: result?.data,
          loading: false,
        });
      }
    } catch (err) {
      setRetirementSummaryData({
        data: [],
        loading: false,
      });
      console.log(err.message);
    }
  }, []);

  useEffect(() => {
    loadRetirementSummaryList();
  }, [loadRetirementSummaryList]);

  return (
    <>
      <div className="relative">
        <div className="w-full mx-auto">
          <div className="relative flex justify-between items-center mb-4 flex-wrap sm:flex-nowrap gap-y-4">
            <div className="text-xl md:text-3xl text-slate-800 font-bold">{t("dashboard")}</div>
            <div className="sm:ml-auto flex justify-end items-center space-x-2 py-3">
              {isSuccessorCount && successorCount === 0 && (
                <Button
                  buttonType={"button"}
                  buttonIcon={"fa-light fa-plus"}
                  buttonIconPosition={"left"}
                  buttonLabel={t("create_succession_plan")}
                  buttonHasLink={true}
                  buttonLink={"/add-successor"}
                />
              )}
              {isSuccessorCount && successorCount > 0 && (
                <Button
                  buttonType={"button"}
                  buttonIcon={"fa-light fa-circle-user"}
                  buttonIconPosition={"left"}
                  buttonLabel={t("your_succession_plan")}
                  buttonHasLink={true}
                  buttonLink={"/profiles"}
                />
              )}
              {isTrial !== 1 ? (
                <>
                  <Button
                    buttonType={"button"}
                    buttonIcon={"fa-light fa-person-circle-plus"}
                    buttonIconPosition={"left"}
                    buttonLabel={t("create_additional_plan")}
                    buttonHasLink={false}
                    functions={() => {
                      setPalnData({
                        type: 1,
                      });
                      let successionPlanCount = 0;
                      let totalSuccessionPlan = user?.number_of_succession_plan ? user?.number_of_succession_plan : 0;
                      if (user?.features && user?.features?.length > 0) {
                        successionPlanCount = user?.features?.find((it) => it?.code === "succession-plans-per-year")?.inputValue;
                      }
                      if (successionPlanCount && successionPlanCount > totalSuccessionPlan) {
                        setEnabledAdditionalPlanPopup(true);
                      } else {
                        toastr.error(SuccessonPlanLimitMessage(successionPlanCount));
                        setLoading(false);
                      }
                      setIsUpdate(false);
                    }}
                  />
                  {role && role === "customer-admin" && (
                    <Button
                      buttonType={"button"}
                      buttonIcon={"fa-light fa-clock"}
                      buttonIconPosition={"left"}
                      buttonLabel={t("send_reminder")}
                      buttonHasLink={false}
                      functions={() => {
                        setEnabledSendReminderPopup(true);
                      }}
                    />
                  )}
                </>
              ) : (
                <Button
                  buttonType={"button"}
                  buttonClasses={"!bg-slate-200 !text-slate-600 border border-slate-300"}
                  buttonIcon={"fa-light fa-person-circle-plus"}
                  buttonIconPosition={"left"}
                  buttonLabel={t("create_additional_plan")}
                  buttonHasLink={false}
                  functions={() => {
                    setEnabledTrialPopup(true);
                  }}
                />
              )}
            </div>
          </div>

          <div className="relative rounded divide-y divide-slate-200 mb-4 grid grid-cols-12 gap-5">
            <div className="col-span-8">
              <div className="py-4 px-4 flex flex-col bg-white rounded-lg shadow-md">
                <div className="flex justify-between items-center flex-wrap sm:flex-nowrap pb-1 mb-1">
                  <div className="text-base text-slate-800 font-bold leading-none">{t("company_plan_tracking")}</div>
                  <div className="sm:ml-auto flex justify-end items-center space-x-1">
                    {isTrial !== 1 ? (
                      <>
                        {/* <Button
                          buttonClasses={
                            "!py-0 !px-2 !border !border-teal-600 !flex !items-center !text-[10px] !bg-transparent !text-teal-600 !transition-all !duration-200"
                          }
                          buttonLabel={"Private My Succession Plan"}
                          buttonType={"button"}
                          buttonIcon={"fa-solid fa-key"}
                          buttonIconPosition={"left"}
                          buttonHasLink={false}
                          functions={() => {
                            setEnabledPrivatePopup(true);
                          }}
                        /> */}
                        <button
                          type="button"
                          className="flex justify-center items-center gap-2 bg-transparent text-teal-500 rounded text-sm h-8 py-0 px-2 transition-all duration-200 cursor-pointer"
                          onClick={handleDownloadClick}
                        >
                          <i className="fa-fw fa-regular fa-download"></i>
                          <div className="text-xs font-semibold whitespace-nowrap">{t("download_all_completed_succession_plans")}</div>
                        </button>
                      </>
                    ) : (
                      <Button
                        buttonClasses={"!py-0 !px-2 !flex !items-center !text-[10px] !bg-transparent !text-slate-400 !transition-all !duration-200"}
                        buttonLabel={t("download_all_completed_succession_plans")}
                        buttonType={"button"}
                        buttonIcon={"fa-regular fa-download"}
                        buttonIconPosition={"left"}
                        buttonHasLink={false}
                        functions={() => {
                          setEnabledTrialPopup(true);
                        }}
                      />
                    )}
                  </div>
                </div>

                <div className={`py-3 border-y border-slate-200 space-y-2 ${!showSkeleton || (!loading && "overflow-auto scroll-smooth scrollbar min-h-[493px]")}`}>
                  {!loading && !showSkeleton && (
                    <div className="flex items-center w-full gap-4">
                      <div className="grow min-w-[200px] w-4/10">
                        <div className="text-[10px] font-bold uppercase text-slate-600 leading-none">{t("plan_position")}</div>
                      </div>
                      <div className="min-w-[100px] w-1/10">
                        <div className="text-[10px] font-bold uppercase text-slate-600 leading-none">{t("invitation_date")}</div>
                      </div>
                      <div className="min-w-[100px] w-1/10">
                        <div className="text-[10px] font-bold uppercase text-slate-600 leading-none">{t("completion_date")}</div>
                      </div>
                      <div className="min-w-[100px] w-1/10">
                        <div className="text-[10px] font-bold uppercase text-slate-600 leading-none">{t("plan_status")}</div>
                      </div>
                      <div className="min-w-[100px] w-1/10">
                        <div className="text-[10px] font-bold uppercase text-slate-600 leading-none">{t("make_plan_private")}</div>
                      </div>
                      <div className="min-w-[100px] w-1/10">
                        <div className="text-[10px] font-bold uppercase text-slate-600 leading-none">{t("approved_by_admin")}</div>
                      </div>
                      <div className="min-w-[100px] w-1/10">
                        <div className="text-[10px] font-bold uppercase text-slate-600 leading-none">{t("download_plans")}</div>
                      </div>
                      {/* <div className="min-w-[100px] w-1/10">
                    <div className="text-[10px] font-bold uppercase text-slate-600 leading-none">
                      {t('action')}
                    </div>
                  </div> */}
                    </div>
                  )}
                  {loading && <DashboardPlanTrackingSkeleton />}

                  {!loading &&
                    successors?.length > 0 &&
                    successors.map((item, index) => {
                      return (
                        <div
                          className={"flex items-center w-full gap-4 p-1 rounded-lg border " + (item?.isInvitedEmployee === 2 ? "bg-teal-100/50 border-teal-200" : "border-slate-200")}
                          key={item._id}
                        >
                          <div className="grow min-w-[200px] w-4/10 pl-2">
                            <div className="text-sm text-slate-800 font-bold capitalize">{item?.name}</div>
                            <div className="text-xs text-slate-400 font-medium capitalize">{item?.position}</div>
                          </div>
                          <div className="min-w-[90px] w-1/10 ">
                            <div className="flex justify-start py-2 text-xs text-slate-500 font-medium">{item && item?.created_at ? moment(new Date(item?.created_at)).format("ll") : "N/A"}</div>
                          </div>
                          <div className="min-w-[90px] w-1/10 ">
                            <div className="flex justify-start py-2 text-xs text-slate-500 font-medium">{item && item?.date ? moment(new Date(item?.date)).format("ll") : "N/A"}</div>
                          </div>
                          <div className="min-w-[100px] w-1/10">
                            {item?.conclusion_details?.length > 0 ? (
                              <button
                                type={"button"}
                                className={"flex justify-center items-center font-medium gap-0.5 bg-transparent text-green-500 rounded text-xs "}
                                //data-tooltip-id="not-completed-tooltip"
                                //data-tooltip-content={"Completed"}
                              >
                                <div className={"text-sm font-semibold whitespace-nowrap"}>
                                  <i className={"fa-fw fa-solid fa-circle-check"}></i>
                                </div>
                                {t("completed")}
                              </button>
                            ) : (
                              <>
                                <button
                                  type={"button"}
                                  className={"flex justify-center items-center font-medium gap-0.5 bg-transparent text-red-500 rounded text-xs "}
                                  //data-tooltip-id="not-completed-tooltip"
                                  //data-tooltip-content={"Not Completed"}
                                >
                                  <div className={"text-sm font-semibold whitespace-nowrap"}>
                                    <i className={"fa-fw fa-solid fa-circle-exclamation"}></i>
                                  </div>
                                  {t("in_completed")}
                                </button>
                                {/* <Tooltip id="not-completed-tooltip" place="left"></Tooltip> */}
                              </>
                            )}
                          </div>
                          {/* <div className="min-w-[50px] w-1/10">
                            <Checkbox
                              checkboxLabel={""}
                              checkboxLableClass={"!text-xs !m-0"}
                              isChecked={
                                item?.make_private && item?.make_private === 1
                                  ? true
                                  : false
                              }
                              checkboxValue={item?._id}
                              getCheckboxValue={(code, event) => {
                                makePrivateHandler(
                                  item?._id,
                                  event.target.checked
                                );
                              }}
                              tooltipContent={""}
                            />
                          </div> */}
                          <div className="min-w-[100px] w-2/10">
                            <div className="flex items-center justify-start space-x-1">
                              {item.successors_details.length > 0 ? (
                                <>
                                  {/* <Checkbox
                                    checkboxLabel={""}
                                    checkboxLableClass={"!text-xs !m-0"}
                                    isChecked={
                                      item?.is_approved &&
                                      item?.is_approved === 1
                                        ? true
                                        : false
                                    }
                                    checkboxValue={item?._id}
                                    getCheckboxValue={(code, event) => {
                                      if (event.target.checked === true) {
                                        updateHandler(
                                          item?._id,
                                          event.target.checked
                                        );
                                      } else {
                                        setEnabledAlertPopup(true);
                                        setCurrentId(item?._id);
                                      }
                                    }}
                                    tooltipContent={"Approved by the CEO"}
                                  />
                                  <Tooltip
                                    id="checkbox-tooltip"
                                    place="left"
                                  ></Tooltip> */}
                                  {item?.isInvitedEmployee === 1 ? (
                                    <div data-tooltip-id="button-tooltip-edit" data-tooltip-content={"Edit"}>
                                      <Button
                                        buttonClasses={"!p-0 !flex !items-center !justify-center !text-xs !bg-transparent !text-teal-500 !w-4 !h-4"}
                                        buttonType={"button"}
                                        buttonIcon={"fa-regular fa-pencil"}
                                        buttonIconPosition={"left"}
                                        buttonLink={"/edit-user/" + item?._id}
                                        buttonHasLink={true}
                                      />
                                      <Tooltip id="button-tooltip-edit" place="left"></Tooltip>
                                    </div>
                                  ) : item?.isInvitedEmployee === 2 ? (
                                    <>
                                      <div data-tooltip-id="button-tooltip-edit" data-tooltip-content={"Edit"}>
                                        <Button
                                          buttonClasses={"!p-0 !flex !items-center !justify-center !text-xs !bg-transparent !text-teal-500 !w-4 !h-4"}
                                          buttonType={"button"}
                                          buttonIcon={"fa-regular fa-pencil"}
                                          buttonIconPosition={"left"}
                                          buttonHasLink={false}
                                          functions={() => {
                                            setPalnData({
                                              type: 1,
                                              name: item?.name,
                                              designation: item?.designation,
                                              retirement_window: item?.retirement_window,
                                              _id: item?._id,
                                            });
                                            setEnabledAdditionalPlanPopup(true);
                                            setIsUpdate(false);
                                          }}
                                        />
                                        <Tooltip id="button-tooltip-edit" place="left"></Tooltip>
                                      </div>
                                      <Button
                                        buttonClasses={"!p-0 !flex !items-center !justify-center !text-xs !bg-transparent !text-teal-500 !w-4 !h-4"}
                                        buttonType={"button"}
                                        buttonIcon={"fa-regular fa-envelope"}
                                        buttonIconPosition={"left"}
                                        buttonHasLink={false}
                                        functions={() => {
                                          setPalnData({
                                            type: 2,
                                            _id: item?._id,
                                          });
                                          setEnabledAdditionalPlanPopup(true);
                                          setIsUpdate(false);
                                        }}
                                        tooltipContent={"Make Invited Employee"}
                                      />
                                      <Tooltip id="button-tooltip" place="left"></Tooltip>
                                      <div className={"!text-teal-500 cursor-pointer text-xs"} data-tooltip-id="non-invited-tooltip" data-tooltip-content={"Non Invited Employee"}>
                                        <i
                                          onClick={() => {
                                            setIsChecking(true);
                                            LoginAs(item?._id);
                                          }}
                                          className={isChecking ? "fa-fw fa-regular fa-spinner fa-spin" : "fa-fw fa-regular fa-arrow-right-to-arc"}
                                        ></i>
                                      </div>
                                      <Tooltip id="non-invited-tooltip" place="left"></Tooltip>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </>
                              ) : (
                                <>
                                  {item?.isInvitedEmployee === 1 ? (
                                    <div data-tooltip-id="button-tooltip-edit" data-tooltip-content={"Edit"}>
                                      <Button
                                        buttonClasses={"!p-0 !flex !items-center !justify-center !text-xs !bg-transparent !text-teal-500 !w-4 !h-4"}
                                        buttonType={"button"}
                                        buttonIcon={"fa-regular fa-pencil"}
                                        buttonIconPosition={"left"}
                                        buttonLink={"/edit-user/" + item?._id}
                                        buttonHasLink={true}
                                      />
                                      <Tooltip id="button-tooltip-edit" place="left"></Tooltip>
                                    </div>
                                  ) : item?.isInvitedEmployee === 2 ? (
                                    <>
                                      <div data-tooltip-id="button-tooltip-edit" data-tooltip-content={"Edit"}>
                                        <Button
                                          buttonClasses={"!p-0 !flex !items-center !justify-center !text-xs !bg-transparent !text-teal-500 !w-4 !h-4"}
                                          buttonType={"button"}
                                          buttonIcon={"fa-regular fa-pencil"}
                                          buttonIconPosition={"left"}
                                          buttonHasLink={false}
                                          functions={() => {
                                            setPalnData({
                                              type: 1,
                                              name: item?.name,
                                              designation: item?.designation,
                                              retirement_window: item?.retirement_window,
                                              _id: item?._id,
                                            });
                                            setEnabledAdditionalPlanPopup(true);
                                            setIsUpdate(false);
                                          }}
                                        />
                                        <Tooltip id="button-tooltip-edit" place="left"></Tooltip>
                                      </div>
                                      <Button
                                        buttonClasses={"!p-0 !flex !items-center !justify-center !text-xs !bg-transparent !text-teal-500 !w-4 !h-4"}
                                        buttonType={"button"}
                                        buttonIcon={"fa-regular fa-envelope"}
                                        buttonIconPosition={"left"}
                                        buttonHasLink={false}
                                        functions={() => {
                                          setPalnData({
                                            type: 2,
                                            _id: item?._id,
                                          });
                                          setEnabledAdditionalPlanPopup(true);
                                          setIsUpdate(false);
                                        }}
                                        tooltipContent={"Make Invited Employee"}
                                      />
                                      <Tooltip id="button-tooltip" place="left"></Tooltip>
                                      <div className={"!text-teal-500 cursor-pointer !text-xs"} data-tooltip-id="non-invited-tooltip" data-tooltip-content={"Non Invited Employee"}>
                                        <i
                                          onClick={() => {
                                            setIsChecking(true);
                                            LoginAs(item?._id);
                                          }}
                                          className={isChecking ? "fa-fw fa-regular fa-spinner fa-spin" : "fa-fw fa-regular fa-arrow-right-to-arc"}
                                        ></i>
                                      </div>
                                      <Tooltip id="non-invited-tooltip" place="left"></Tooltip>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </>
                              )}
                              {user && user?._id !== item?._id && role === "customer-admin" && (
                                <div data-tooltip-id="button-tooltip-delete" data-tooltip-content={"Delete"}>
                                  <Button
                                    buttonClasses={"!p-0 !flex !items-center !justify-center !text-xs !bg-transparent !w-4 !h-4 !text-rose-500"}
                                    buttonType={"button"}
                                    buttonIcon={"fa-regular fa-trash-can"}
                                    buttonIconPosition={"left"}
                                    buttonHasLink={false}
                                    functions={() => {
                                      setCurrentId(item?._id);
                                      setenabledDeletePopup(true);
                                      setIsUpdate(false);
                                    }}
                                  />
                                  <Tooltip id="button-tooltip-delete" place="left"></Tooltip>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="min-w-[32px]">
                            {item.successors_details.length > 0 ? (
                              <div className="flex items-start justify-start">
                                {((user && user?._id === item?._id) || user?._id === item?.customer_admin_id || (item?.make_private && item?.make_private === 2)) && (
                                  <>
                                    {isTrial !== 1 ? (
                                      <>
                                        <div data-tooltip-id="button-tooltip-download" data-tooltip-content={"Download"}>
                                          <button
                                            type="button"
                                            className="flex justify-center items-center gap-2 bg-transparent text-teal-500 rounded text-sm h-8 py-0 px-2 transition-all duration-200 cursor-pointer"
                                            onClick={(e) => handleSingleDownloadClick(e, item, index)}
                                          >
                                            <i className="fa-fw fa-regular fa-download"></i>
                                          </button>
                                          <Tooltip id="button-tooltip-download" place="left"></Tooltip>
                                        </div>
                                      </>
                                    ) : (
                                      <div data-tooltip-id="button-tooltip-download" data-tooltip-content={"Download"}>
                                        <Button
                                          buttonClasses={"!p-0 !flex !items-center !justify-center !text-[10px] !bg-transparent !text-slate-400 !w-4 !h-4"}
                                          buttonType={"button"}
                                          buttonIcon={"fa-regular fa-download"}
                                          buttonIconPosition={"left"}
                                          buttonHasLink={false}
                                          functions={() => {
                                            setEnabledTrialPopup(true);
                                          }}
                                        />
                                        <Tooltip id="button-tooltip-download" place="left"></Tooltip>
                                      </div>
                                    )}
                                  </>
                                )}
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                          {/* <div className="min-w-[100px] w-1/10">
                          </div>*/}
                        </div>
                      );
                    })}

                  {!loading && successors?.length <= 0 && <>{showSkeleton ? <DashboardPlanTrackingSkeleton /> : <DashboardPlanTrackingSkeleton />}</>}
                </div>
              </div>
            </div>
            <div className="col-span-4">
              <div className="sticky top-0 bg-white rounded-lg divide-y w-full divide-slate-200 shadow-md min-h-[460px]">
                <div className="py-8 px-6 flex flex-col">
                  <div className="text-base text-slate-800 font-bold leading-none">{t("invite_individuals_in_your_company")}</div>
                  <div className="py-3 border-y border-slate-200 space-y-4 mt-4">
                    <div className="relative w-full">
                      <Select
                        label={t("company_employees")}
                        labelClasses={"!text-xs"}
                        xPlacement={"left"}
                        selectedValue={""}
                        dropdownClass={"!w-full"}
                        search={true}
                        dropdownData={[
                          { _id: "", label: t("select"), value: "" },
                          ...employees.map((item) => ({
                            _id: item?._id,
                            label: item?.first_name + " " + item?.last_name,
                            value: item?.email,
                            position: item?.designation,
                          })),
                        ].filter((item) => item)}
                        getSelectedValue={(e) => {
                          setName(e.label);
                          setEmail(e.value);
                          setDesignation(e.position);
                        }}
                      />
                    </div>
                  </div>

                  <div className="space-y-4 mt-4">
                    <div className="relative w-full">
                      <Input
                        label={t("full_name") + ":"}
                        requiredDisplay={true}
                        labelClasses={"!text-xs"}
                        inputType={"text"}
                        inputPlaceholder={t("enter_full_name")}
                        inputValue={name}
                        setInput={setName}
                      />
                    </div>
                    <div className="relative w-full">
                      <Input
                        label={t("work_email") + ":"}
                        requiredDisplay={true}
                        labelClasses={"!text-xs"}
                        inputType={"email"}
                        inputPlaceholder={t("work_email_address")}
                        inputValue={email}
                        setInput={setEmail}
                      />
                      {/* <InputGroup
                        label={"Work Email"}
                        labelClasses={"!text-xs"}
                        inputType={"email"}
                        inputPlaceholder={"Work Email Address"}
                        inputValue={email}
                        setInput={setEmail}
                        getSuffix={'@'+domain}
                        errorType={'info'}
                        errorText={'Only use email prefix'}
                      /> */}
                    </div>
                    {!id && (
                      <>
                        <div className="relative w-full">
                          <Select
                            label={t("position_the_succession_plan_is_being_created_by") + ":"}
                            requiredDisplay={true}
                            labelClasses={"!text-xs"}
                            xPlacement={"left"}
                            selectedValue={designation}
                            dropdownClass={"!w-full"}
                            search={true}
                            dropdownData={[
                              {
                                _id: "",
                                label: t("select_position"),
                                value: "",
                              },
                              ...position.map((item) => ({
                                _id: item?._id,
                                label: item?.name,
                                value: item?._id,
                              })),
                              {
                                _id: "others",
                                label: "Other",
                                value: "others",
                              },
                            ].filter((itm) => itm)}
                            getSelectedValue={(e) => {
                              if (e._id === "others") {
                                setEnabledOtherPositionPopup(true);
                              }
                              setDesignation(e._id);
                            }}
                          />
                        </div>
                      </>
                    )}
                    <div className="relative w-full">
                      <Checkbox
                        checkboxLabel={t("create_additional_administrator")}
                        checkboxLableClass={""}
                        isChecked={isAdditionalAdministrator && isAdditionalAdministrator === 1 ? true : false}
                        checkboxValue={"confirm_additional_admin"}
                        getCheckboxValue={(code, event) => {
                          if (event.target.checked === true) {
                            setEnabledConfirmationPopup(true);
                          } else {
                            setActionValue({
                              button_type: "close",
                              row_id: "",
                              current_status: "",
                            });
                            setIsAdditionalAdminDisabled(false);
                            setIsInviteMemberDisabled(false);
                          }
                          setField(code);
                        }}
                        isDisabled={isAdditionalAdminDisabled}
                      />
                    </div>
                    <div className="relative w-full">
                      <Checkbox
                        checkboxLabel={t("invite_board_member")}
                        checkboxLableClass={""}
                        isChecked={isInvitedBoardMember && isInvitedBoardMember === 1 ? true : false}
                        checkboxValue={"invite_additional_member"}
                        getCheckboxValue={(code, event) => {
                          if (event.target.checked === true) {
                            setIsInvitedBoardMember(1);
                            setIsAdditionalAdminDisabled(true);
                            setIsInviteMemberDisabled(false);
                            //setEnabledConfirmationPopup(true)
                          } else {
                            setIsInvitedBoardMember(2);
                            setIsAdditionalAdminDisabled(false);
                            setIsInviteMemberDisabled(false);
                          }
                          setField(code);
                        }}
                        isDisabled={isInviteMemberDisabled}
                      />
                    </div>
                  </div>
                </div>
                <div className="py-3 px-3 border-t border-slate-200">
                  <div className="flex items-center justify-end gap-4">
                    {isTrial !== 1 ? (
                      <Button
                        buttonClasses=""
                        buttonLabelClasses=""
                        buttonType={"button"}
                        buttonIcon={isAdd ? "fa-light fa-spinner fa-spin" : "fa-light fa-check"}
                        buttonIconPosition={"left"}
                        buttonLabel={t("invite")}
                        functions={onSubmit}
                        buttonHasLink={false}
                        buttonDisabled={isAdd}
                      />
                    ) : (
                      <Button
                        buttonClasses={"!bg-slate-200 !text-slate-600 border border-slate-300"}
                        buttonLabelClasses=""
                        buttonType={"button"}
                        buttonIcon={"fa-light fa-check"}
                        buttonIconPosition={"left"}
                        buttonLabel={t("invite")}
                        buttonHasLink={false}
                        functions={() => {
                          setEnabledTrialPopup(true);
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="relative gap-4 mt-2">
            <AvgOfQuestionTier />
          </div>

          <div className="relative grid grid-cols-12 gap-4 mt-4">
            {/* <div className="col-span-8 space-y-5">
              <div className="relative bg-white rounded-lg shadow-md divide-y divide-slate-200">
                <div className="text-base text-slate-800 font-bold leading-none px-3 py-3">
                  {t("highest_rated_successors")}
                </div>
                <div className="px-3 py-3 border-y border-slate-200 space-y-2 overflow-auto scroll-smooth scrollbar min-h-[500px]">
                  <div className="flex items-center w-full gap-4">
                    <div className="grow min-w-[200px] w-2/6">
                      <div className="text-[10px] font-bold uppercase text-slate-600 leading-none">
                        {t("name")}
                      </div>
                    </div>
                    <div className="min-w-[200px] sm:min-w-[100px] w-1/6">
                      <div className="text-[10px] font-bold uppercase text-slate-600 leading-none">
                        {t("overall_score")}
                      </div>
                    </div>
                    <div className="min-w-[200px] sm:min-w-[100px] w-1/6">
                      <div className="text-[10px] font-bold uppercase text-slate-600 leading-none">
                        {t("current_position")}
                      </div>
                    </div>
                    <div className="min-w-[200px] sm:min-w-[100px] w-2/6 pr-3 sm:pr-0">
                      <div className="text-[10px] font-bold uppercase text-slate-600 leading-none">
                        {t("successor_position")}
                      </div>
                    </div>
                  </div>
                  {!loading &&
                    totalDataCount > 0 &&
                    highScore &&
                    Object.keys(highScore).length > 0 &&
                    Object.values(highScore).map((row, idx) => {
                      return (
                        row.length > 0 && (
                          <>
                            {row &&
                              row
                                .sort(
                                  (a, b) => b.overall_score - a.overall_score
                                )
                                .map((item, idx2) => (
                                  <div
                                    className="flex items-center w-full gap-4"
                                    key={idx + "_" + idx2}
                                  >
                                    <div className="grow min-w-[200px] w-2/6">
                                      <Input
                                        inputType={"text"}
                                        inputValue={item.name}
                                        isDisabled={true}
                                      />
                                    </div>
                                    <div className="min-w-[200px] sm:min-w-[100px] w-1/6">
                                      <Input
                                        inputType={"text"}
                                        inputValue={item.overall_score}
                                        isDisabled={true}
                                      />
                                    </div>
                                    <div className="min-w-[200px] sm:min-w-[100px] w-1/6">
                                      <Input
                                        inputType={"text"}
                                        inputValue={
                                          item?.current_position
                                            ? item?.current_position[0]
                                                ?.short_name
                                            : "N/A"
                                        }
                                        isDisabled={true}
                                      />
                                    </div>
                                    <div className="min-w-[200px] sm:min-w-[100px] w-2/6 pr-3 sm:pr-0">
                                      <Input
                                        inputType={"text"}
                                        inputValue={item.successor_position}
                                        isDisabled={true}
                                      />
                                    </div>
                                  </div>
                                ))}
                          </>
                        )
                      );
                    })}
                  {loading && <TableSkeleton tableImage={false} />}
                </div>
              </div>
            </div> */}
            <div className="col-span-4 space-y-2 flex">
              <CardTable
                newDesign={true}
                data={readinessData?.data?.map((item) => ({
                  _id: item?._id,
                  name: item?.title,
                  point: item?.number,
                }))}
                loading={readinessData?.loading}
                title={"Company Readiness Summary"}
              />
            </div>
            <div className="col-span-4 space-y-2 flex">
              <CardTable
                newDesign={true}
                data={retirementSummaryData?.data?.map((item) => ({
                  _id: item?._id,
                  name: item?.title,
                  point: item?.number,
                }))}
                loading={retirementSummaryData?.loading}
                title={"Company Retirement Summary"}
              />
            </div>
            <div className="col-span-4 space-y-2">
              <DashBoardReportCenter />
            </div>
          </div>
        </div>
      </div>
      {enabledOtherPositionPopup && (
        <OtherPositionPopup
          isOpen={enabledOtherPositionPopup}
          setIsOpen={(val) => setEnabledOtherPositionPopup(val)}
          getActionValue={(obj) => {
            setActionValue(obj);
          }}
        ></OtherPositionPopup>
      )}
      {enabledApprovalPopup && (
        <ApprovalPopup
          isOpen={enabledApprovalPopup}
          setIsOpen={(val) => setEnabledApprovalPopup(val)}
          getActionValue={(obj) => {
            setActionValue(obj);
          }}
        ></ApprovalPopup>
      )}
      {enabledAlertPopup && (
        <AlertPopup
          isOpen={enabledAlertPopup}
          message={"Are you sure?"}
          setIsOpen={(val) => setEnabledAlertPopup(val)}
          getActionValue={(obj) => {
            updateHandler(currentId, false);
            //setActionValue(obj)
          }}
        ></AlertPopup>
      )}
      {enabledConfirmationPopup && (
        <ConfirmationPopup
          isOpen={enabledConfirmationPopup}
          title={"Confirmation"}
          message={"By checking this box you will allow the user administrator access to all plans and information within the company account."}
          confirmButtonLabel={"Confirm Additional Admin"}
          cancelButtonLabel={"Decline"}
          setIsOpen={(val) => setEnabledConfirmationPopup(val)}
          getActionValue={(obj) => {
            //updateHandler(currentId,false)
            setActionValue(obj);
          }}
        ></ConfirmationPopup>
      )}
      {enabledAdditionalPlanPopup && (
        <AdditionalPlanPopup
          isOpen={enabledAdditionalPlanPopup}
          setIsOpen={(val) => setEnabledAdditionalPlanPopup(val)}
          data={planData}
          getActionValue={(obj) => {
            setActionValue(obj);
            setIsUpdate(true);
          }}
        ></AdditionalPlanPopup>
      )}
      {enabledDeletePopup && (
        <DeletePopup
          isOpen={enabledDeletePopup}
          setIsOpen={(val) => setenabledDeletePopup(val)}
          currentId={currentId}
          getActionValue={(obj) => {
            setActionValue(obj);
            setenabledDeletePopup(false);
            setCurrentId("");
          }}
        />
      )}
      {enabledTrialPopup && (
        <TrialAlertPopup
          isOpen={enabledTrialPopup}
          setIsOpen={(val) => setEnabledTrialPopup(val)}
          getActionValue={(obj) => {
            setActionValue(obj);
          }}
        />
      )}
      {enabledSendReminderPopup && (
        <SendReminderPopup
          isOpen={enabledSendReminderPopup}
          setIsOpen={(val) => setEnabledSendReminderPopup(val)}
          data={planData}
          getActionValue={(obj) => {
            setActionValue(obj);
          }}
        ></SendReminderPopup>
      )}
      {enabledPrivatePopup && (
        <ConfirmationPopup
          isOpen={enabledPrivatePopup}
          title={"Confirmation"}
          message={"Are you sure you want to proceed with making your Succession Plan private? Please click 'Confirm' to finalize this action."}
          confirmButtonLabel={"Confirm"}
          cancelButtonLabel={"Cancel"}
          setIsOpen={(val) => setEnabledPrivatePopup(val)}
          getActionValue={(obj) => {
            //updateHandler(currentId,false)
            // setActionValue(obj);
          }}
        ></ConfirmationPopup>
      )}
    </>
  );
};

export default Dashboard;
