import React, { useEffect, useState } from "react";
import { setTitle } from "../helpers/MetaTag";
import { useSelector } from "react-redux";
import { postData } from "../services/api";
import { Document, PDFDownloadLink } from "@react-pdf/renderer";
import DevelopmentIndividualReport from "./pdf/DevelopmentIndividualReport";
import DropdownCheckbox from "../components/form/DropdownCheckbox";
import moment from "moment";
import Searching from "../components/Searching";
import NoDataFound from "../components/NoDataFound";
import { API_URL } from "../config/host";

import { useTranslation } from "react-i18next";
import ReportTableSkeleton from "../components/loader/ReportTableSkeleton";

const IndividualDevelopment = () => {
  setTitle("Succession Planning Software for your Business with Succession Now | Development Plan Report");
  const { i18n, t } = useTranslation();
  const user = useSelector((state) => state.auth?.user);
  const [loading, setLoading] = useState(false);
  const [successors, setSuccessors] = useState([]);
  const [predictorWeight, setPredictorWeight] = useState(0);
  const [experienceWeight, setExperienceWeight] = useState(0);
  const [concernsWeight, setConcernWeight] = useState(0);
  const [performanceWeight, setPerformanceWeight] = useState(0);
  const [educationWeight, setEducationWeight] = useState(0);
  const [totalWeight, setTotalWeight] = useState(0);
  const [positions, setPositions] = useState([]);
  const [users, setUsers] = useState([]);
  const [totalDataCount, setTotalDataCount] = useState(0);

  useEffect(() => {
    async function positionData() {
      try {
        const result = await postData("position/list", {
          sortQuery: { ordering: 1 },
          type: 1,
          status: 1,
          isStep: 1,
          //isReport : 1,
        });
        if (result.data) {
          setPositions(
            result.data.map((value, key) => {
              return {
                _id: value?._id,
                label: value?.name + " (" + value?.short_name + ")",
                isEnabled: false,
              };
            })
          );
        } else {
          console.log("position list message", result.message);
        }
      } catch (error) {
        console.log("Error position list catch", error.message);
      }
    }
    positionData();
  }, []);

  const loadUsers = async () => {
    try {
      const result = await postData("assign/list", {});
      if (result.data) {
        setUsers([
          {
            _id: user?._id,
            label: user?.name,
            isEnabled: false,
          },
          ...result.data.map((value, key) => {
            return {
              _id: value?._id,
              label: value?.name,
              isEnabled: false,
            };
          }),
        ]);
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  const loaWeights = async () => {
    try {
      const result = await postData("scorecard/list", {
        status: 1,
      });
      if (result.data) {
        let total = 0;
        // eslint-disable-next-line array-callback-return
        let pWeight = 0;
        let exWeight = 0;
        let con = 0;
        let pref = 0;
        let edu = 0;
        result.data.map((value, key) => {
          if (value.alias === "potential_predictor_score") {
            pWeight = value?.company_weight ? value?.company_weight : value?.weight;
            total = total + Number(value?.company_weight ? value?.company_weight : value?.weight);
          } else if (value.alias === "executive_experience") {
            exWeight = value?.company_weight ? value?.company_weight : value?.weight;
            total = total + Number(value?.company_weight ? value?.company_weight : value?.weight);
          } else if (value.alias === "concerns") {
            con = value?.company_weight ? value?.company_weight : value?.weight;
            total = total + Number(value?.company_weight ? value?.company_weight : value?.weight);
          } else if (value.alias === "recent_performance_review") {
            pref = value?.company_weight ? value?.company_weight : value?.weight;
            total = total + Number(value?.company_weight ? value?.company_weight : value?.weight);
          } else if (value.alias === "highest_level_education") {
            edu = value?.company_weight ? value?.company_weight : value?.weight;
            total = total + Number(value?.company_weight ? value?.company_weight : value?.weight);
          }
          setPredictorWeight(pWeight);
          setExperienceWeight(exWeight);
          setConcernWeight(con);
          setPerformanceWeight(pref);
          setEducationWeight(edu);
          setTotalWeight(total);
        });
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  const loadSuccessors = async (SelectedPosition = [], SelectedUser = []) => {
    setLoading(true);
    setTotalDataCount(0);
    try {
      const result = await postData("highrated-successor/list", {
        sortQuery: { created_at: 1 },
        position: SelectedPosition,
        //created_by: SelectedUser,
        is_interim: 2,
      });
      if (result.data) {
        setSuccessors(
          result.data.map((item, key) => {
            if (item.successors_details && item.successors_details.length > 0) {
              let successors_details = item.successors_details.map((value, index) => {
                let predictorCalculation = (predictorWeight * value?.overall_weighted_score) / 100;
                let experienceCalculation = (experienceWeight * (value.experience_details ? value?.experience_details[0]?.point : 0)) / 100;
                let concernsCalculation = (concernsWeight * value?.overall_score) / 100;
                let performanceCalculation = (performanceWeight * (value.performance_details ? value?.performance_details[0]?.point : 0)) / 100;
                let educationCalculation = (educationWeight * (value.education_details ? value?.education_details[0]?.point : 0)) / 100;
                let desireResult = value?.desire_details && value?.desire_details.length > 0 ? (value?.desire_details[0]?.isPass ? 1 : 2) : null;
                let loyaltyResult = value?.loyalty_details && value?.loyalty_details.length > 0 ? (value?.loyalty_details[0]?.isPass ? 1 : 2) : null;
                let RetirementResult = value?.retirement_details && value?.retirement_details.length > 0 ? (value?.retirement_details[0]?.isPass ? 1 : 2) : null;
                return {
                  ...value,
                  overallWeightScore:
                    desireResult === 2 || loyaltyResult === 2 || RetirementResult === 2
                      ? 0.0
                      : (
                          parseFloat(predictorCalculation) +
                          parseFloat(experienceCalculation) +
                          parseFloat(concernsCalculation) +
                          parseFloat(performanceCalculation) +
                          parseFloat(educationCalculation)
                        ).toFixed(2),
                };
              });
              return {
                name: item.name,
                position: item.position,
                retirement: item.retirement,
                pos_details: item.pos_details,
                created_retirement_details: item.created_retirement_details,
                successors_details: successors_details,
              };
            } else {
              return [];
            }
          })
        );
      }
      setTotalDataCount(result.data.length);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err.message);
    }
  };

  const positionSelect = async (id, checked) => {
    if (id && id === "all") {
      setPositions(
        positions.map((pos) => {
          if (checked) {
            return { ...pos, isEnabled: checked };
          } else {
            return { ...pos, isEnabled: false };
          }
        })
      );
    } else {
      setPositions(
        positions.map((pos) => {
          if (pos._id === id) {
            return { ...pos, isEnabled: checked };
          } else {
            return pos;
          }
        })
      );
    }
  };

  const userSelect = async (id, checked) => {
    if (id && id === "all") {
      setUsers(
        users.map((usr) => {
          if (checked) {
            return { ...usr, isEnabled: checked };
          } else {
            return { ...usr, isEnabled: false };
          }
        })
      );
    } else {
      setUsers(
        users.map((usr) => {
          if (usr._id === id) {
            return { ...usr, isEnabled: checked };
          } else {
            return usr;
          }
        })
      );
    }
  };

  useEffect(() => {
    loadUsers();
    loaWeights();
  }, []);

  useEffect(() => {
    let SelectedPosition = [];
    let SelectedUser = [];

    if (positions) {
      positions.map((usr) => {
        if (usr.isEnabled) {
          SelectedPosition.push(usr._id);
        }
      });
    }
    // if (users) {
    //   users.map((usr) => {
    //     if (usr.isEnabled) {
    //       SelectedUser.push(usr._id);
    //     }
    //   });
    // }
    if (SelectedPosition.length > 0) {
      loadSuccessors(SelectedPosition, SelectedUser);
    } else {
      setSuccessors([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [positions]);

  const getDownload = (e) => {
    e.preventDefault();
    if (e.target.tagName.toLowerCase() === "a") {
      window.open(e.target.href, "_blank");
    }
  };

  //console.log('user',user)

  return (
    <>
      <div className="relative flex justify-between items-center mb-4 flex-wrap sm:flex-nowrap gap-y-4">
        <div>
          <div className="text-xl md:text-3xl text-slate-800 font-bold">{t("development_plan_report") + " - " + user?.company_name}</div>
          <div className="text-sm text-slate-800 font-bold">{t("please_make_a_selection_from_the_dropdown_to_generate_report")}</div>
        </div>
        <div className="sm:ml-auto flex justify-end items-center">
          <button
            type={"button"}
            className={"flex justify-center items-center gap-2 bg-teal-500 text-white rounded text-base sm:text-lg h-8 sm:h-10 py-0 px-2 sm:px-3 transition-all duration-200 cursor-pointer"}
            onClick={(e) => getDownload(e)}
          >
            <i className={"fa-fw fa-light fa-download"}></i>
            <div className={"text-xs sm:text-sm font-medium whitespace-nowrap"}>
              <PDFDownloadLink
                document={
                  <Document>
                    <DevelopmentIndividualReport successors={successors} user={user} companyLogo={API_URL + user?.image} />
                  </Document>
                }
                fileName="scorecard.pdf"
              >
                {({ blob, url, loading, error }) => (loading ? t("loading_document") : t("download_pdf"))}
              </PDFDownloadLink>
            </div>
          </button>
        </div>
      </div>
      <div className="relative flex justify-start items-center mb-4 space-x-2">
        <div className="relative z-20">
          <DropdownCheckbox data={positions} functions={positionSelect} />
        </div>
        {/* <div className="relative z-20">
          <DropdownCheckbox data={users} functions={userSelect} />
        </div> */}
      </div>

      <div className="w-full overflow-auto scroll-smooth scrollbar min-h-[480px]">
        <table className="min-w-max w-full table-auto">
          {/* <thead>
            <tr className="text-white font-bold text-sm leading-none border-b border-slate-200">
              <th className="py-4 px-2 text-left max-w-[240px] sticky -top-6 z-10 bg-slate-900 rounded-tl-md"></th>
              <th className="py-4 px-2  max-w-[240px] sticky -top-6 z-10 bg-slate-900 last:rounded-tr-md">Needed Skills & Abilities</th>
              <th className="py-4 px-2  max-w-[240px] sticky -top-6 z-10 bg-slate-900 last:rounded-tr-md">Needed Experience or Seasoning</th>
              <th className="py-4 px-2  max-w-[240px] sticky -top-6 z-10 bg-slate-900 last:rounded-tr-md">Needed Education</th>
            </tr>
          </thead> */}
          <tbody className="text-slate-700 text-sm font-light bg-slate-300 divide-y divide-slate-200">
            {successors && successors.length > 0 && totalDataCount > 0 ? (
              successors.map((row) => {
                return (
                  <>
                    {row.successors_details && (
                      <>
                        <tr className="!border-none">
                          <td className="py-3 px-2 font-semibold text-sm text-left max-w-[240px] align-top rounded-tl-md" colSpan={3}>
                            {row.position} : {row.name}
                          </td>
                          <td className="py-3 px-2 font-semibold text-sm text-left max-w-[240px] align-top rounded-tr-md" colSpan={8}>
                            {t("retirement_window")} : {row.retirement}
                          </td>
                        </tr>
                      </>
                    )}
                    {row.successors_details && (
                      <tr className="text-white font-bold text-sm leading-none bg-slate-900">
                        <th className="py-4 px-2 text-left max-w-[240px] z-10"></th>
                        <th className="py-4 px-2  max-w-[240px] z-10 ">{t("needed_skills_&_abilities")}</th>
                        <th className="py-4 px-2  max-w-[240px] z-10 ">{t("needed_experience_or_seasoning")}</th>
                        <th className="py-4 px-2  max-w-[240px] z-10 ">{t("needed_education")}</th>
                      </tr>
                    )}
                    {row.successors_details &&
                      row.successors_details.map((items, index) => (
                        <tr className="bg-white" key={index}>
                          <td className="py-3 px-2 font-semibold text-sm text-left max-w-[240px] align-top">
                            {items.name}
                            <span className="block text-xs font-bold leading-tight text-teal-500">{items?.position_details ? items?.position_details[0]?.short_name : "N/A"}</span>
                          </td>
                          <td className="py-3 px-2 text-left max-w-[240px] align-top">
                            <>
                              <div className="border-b border-slate-100 pb-4">
                                {items.plan_skills &&
                                  items.plan_skills.length > 0 &&
                                  items.plan_skills.map((skill_item, skill_index) => (
                                    <div
                                      className="whitespace-pre-line relative flex justify-center text-xs font-medium text-slate-700 bg-white py-2 px-3 border border-slate-200 rounded mt-2 min-h-[100px]"
                                      key={skill_index}
                                    >
                                      {skill_item}
                                    </div>
                                  ))}
                              </div>
                              <div className="relative pt-2 flex items-center">
                                <div className="font-medium text-md pt-2">{t("timeframe")}</div>
                                <div className="font-light text-md pt-2 pl-2 whitespace-pre-line">
                                  {items?.plan_skills_timeframe_details && items?.plan_skills_timeframe_details.length > 0 ? items?.plan_skills_timeframe_details[0]?.name : ""}
                                </div>
                              </div>
                              {items && items?.plan_skills_timeframe_reminder ? (
                                <div className="relative flex items-center">
                                  <div className="font-medium text-md pt-2 text-teal-500">{t("reminder")}</div>
                                  <div className="font-light text-md pt-2 pl-2">{moment(new Date(items?.plan_skills_timeframe_reminder)).format("MM/DD/YYYY")}</div>
                                </div>
                              ) : (
                                ""
                              )}
                            </>
                          </td>
                          <td className="py-3 px-2 text-left max-w-[240px] align-top">
                            <>
                              <div className="border-b border-slate-100 pb-4">
                                {items.plan_experiences &&
                                  items.plan_experiences.length > 0 &&
                                  items.plan_experiences.map((experience_item, experience_index) => (
                                    <div
                                      className="whitespace-pre-line relative flex justify-center text-xs font-medium text-slate-700 bg-white py-2 px-3 border border-slate-200 rounded mt-2 min-h-[100px]"
                                      key={experience_index}
                                    >
                                      {experience_item}
                                    </div>
                                  ))}
                              </div>
                              <div className="relative pt-2  flex items-center">
                                <div className="font-medium text-md pt-2">{t("timeframe")}</div>
                                <div className="font-light text-md pt-2 pl-2 whitespace-pre-line">
                                  {items?.plan_experiences_timeframe_details && items?.plan_experiences_timeframe_details.length > 0 ? items?.plan_experiences_timeframe_details[0]?.name : ""}
                                </div>
                              </div>
                              {items && items?.plan_experiences_timeframe_reminder ? (
                                <div className="relative flex items-center">
                                  <div className="font-medium text-md pt-2 text-teal-500">{t("reminder")}</div>
                                  <div className="font-light text-md pt-2 pl-2">{moment(new Date(items?.plan_experiences_timeframe_reminder)).format("MM/DD/YYYY")}</div>
                                </div>
                              ) : (
                                ""
                              )}
                            </>
                          </td>
                          <td className="py-3 px-2 text-left max-w-[240px] align-top ">
                            <>
                              <div className="border-b border-slate-100 pb-4">
                                {items.plan_educations &&
                                  items.plan_educations.length > 0 &&
                                  items.plan_educations.map((education_item, education_index) => (
                                    <div
                                      className="whitespace-pre-line  relative flex justify-center text-xs font-medium text-slate-700 bg-white py-2 px-3 border border-slate-200 rounded mt-2 min-h-[100px]"
                                      key={education_index}
                                    >
                                      {education_item}
                                    </div>
                                  ))}
                              </div>

                              <div className="relative pt-2  flex items-center">
                                <div className="font-medium text-md pt-2">{t("timeframe")}</div>
                                <div className="font-light text-md pt-2 pl-2 whitespace-pre-line">
                                  {items?.plan_educations_timeframe_details && items?.plan_educations_timeframe_details.length > 0 ? items?.plan_educations_timeframe_details[0]?.name : ""}
                                </div>
                              </div>
                              {items && items?.plan_educations_timeframe_reminder ? (
                                <div className="relative flex items-center">
                                  <div className="font-medium text-md pt-2 text-teal-500">{t("reminder")}</div>
                                  <div className="font-light text-md pt-2 pl-2">{moment(new Date(items?.plan_educations_timeframe_reminder)).format("MM/DD/YYYY")}</div>
                                </div>
                              ) : (
                                ""
                              )}
                            </>
                          </td>
                        </tr>
                      ))}
                    <tr className="!border-none bg-slate-100">
                      <td className="py-3 px-2" colSpan={11}></td>
                    </tr>
                  </>
                );
              })
            ) : (
              <tr className="!border-none">
                {loading ? (
                  <td className="p-0" colSpan={11}>
                    <div className="space-y-5">
                      <ReportTableSkeleton />
                      <ReportTableSkeleton />
                    </div>
                  </td>
                ) : (
                  <td className="p-0" colSpan={11}>
                    <NoDataFound label={t("please_make_a_selection_from_the_dropdown_to_generate_report")} />
                  </td>
                )}

                {/* <td className="py-3 px-2 font-semibold text-sm text-center max-w-[240px] align-top" colSpan={11}>
                  {t('please_make_a_selection_from_the_dropdown_to_generate_report')}
                </td> */}
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default IndividualDevelopment;
